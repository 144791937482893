import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Badge from "@material-ui/core/Badge";
import FilterListIcon from "@material-ui/icons/FilterList";
import { Link } from "react-router-dom";
import Filters from "./Filters.jsx";
import Page2Map from "./Page2Map.jsx";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import Configure from "./Configure.jsx";
import Collapse from "@material-ui/core/Collapse";
import MapIcon from "@material-ui/icons/Map";
import CloseIcon from "@material-ui/icons/HighlightOff";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getAssets, showMessage } from "../actions/index";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { apiRequest, getOption, atLastPageScrollToTop, defaultImage, isPortalAdmin, getAccess, byName, formatDate } from "../utils/Utils";
import device_image from "../images/noimage.png";
import Button from "@material-ui/core/Button";
import { PFContext } from "./PrimaryFilterContext";
import Moment from 'react-moment';
import momentTimezone from "moment-timezone";
import Dialog from "@material-ui/core/Dialog";
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Open from "@material-ui/icons/OpenInNew";
import Close from "@material-ui/icons/CancelPresentation";
import SvgIcon from '@material-ui/core/SvgIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ViewColumnOutlined from "@material-ui/icons/ViewColumnOutlined";
import LocalOfferOutlined from "@material-ui/icons/LocalOfferOutlined";
import CollectionsBookmarkOutlined from "@material-ui/icons/CollectionsBookmarkOutlined";
import classNames from "classnames";
import AlertDialog from "./AlertDialog";
import PopUp from "./PopUp";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/SaveAlt";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Down from "@material-ui/icons/KeyboardArrowDown";
import Up from "@material-ui/icons/KeyboardArrowUp";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { CSVLink } from "react-csv";

let isCheckBoxColumnActive = false;

function getCookie(name) {
  var match = document.cookie.match(new RegExp('(^| )table=([^;]+)'));
  if (match) {
    let ob = JSON.parse(match[2]);
    if (ob) {
      //return ob.find(i => i.id == name).isActive;
      let column = ob.find(i => i.id == name);
      return column ? column.isActive : false;
    }
    //return (match[2]) == 1 ? true : false;
    return true;
  } else {
    return (name == "LocationName" || name == "DeviceImage" || name == "Timestamp" || name == "MeridianCreated" || name == "MeridianModified" || name == "FirstAssignment") ? false : true
  }
}

function desc(a, b, orderBy) {
  let cmpa = "";
  let cmpb = "";
  if (orderBy == "Labels" || orderBy == "CollectionNames") {
    cmpa = a[orderBy] ? a[orderBy].length : 0;
    cmpb = b[orderBy] ? b[orderBy].length : 0;
  } else {
    cmpa = a[orderBy] ? (!Number.isInteger(a[orderBy]) ? a[orderBy].toLowerCase() : a[orderBy]) : '';
    cmpb = b[orderBy] ? (!Number.isInteger(b[orderBy]) ? b[orderBy].toLowerCase() : b[orderBy]) : '';
  }
  if (cmpb < cmpa) {
    return -1;
  }
  if (cmpb > cmpa) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  //console.log("nah", stabilizedThis.map(el => el[0]));
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return orderBy
    ? order === "desc"
      ? (a, b) => desc(a, b, orderBy)
      : (a, b) => -desc(a, b, orderBy)
    : (a, b) => 0;
}

let defaultRows = [
  { id: "map", disablePadding: false, label: "MAP", isActive: true, isFixed: true, export: false },
  { id: "DeviceImage", disablePadding: true, label: "IMAGE", isActive: false, isFixed: false, export: false },
  { id: "Name", disablePadding: false, label: "DEVICE NAME", isActive: true, isFixed: true, export: true },
  { id: "EntityName", disablePadding: false, label: "ORGANIZATION", isActive: true, isFixed: false, export: true },
  { id: "LocationName", disablePadding: false, label: "ASSIGNED CAMPUS", isActive: false, isFixed: false, export: true },
  { id: "MeridianBuildingName", disablePadding: false, label: "CURRENT CAMPUS", isActive: true, isFixed: false, export: true },
  { id: "FloorNumber", disablePadding: false, label: "FLOOR", isActive: true, isFixed: false, export: true },
  { id: "ZoneName", disablePadding: false, label: "ZONE", isActive: true, isFixed: false, export: true },
  { id: "Manufacturer", disablePadding: false, label: "MANUFACTURER", isActive: true, isFixed: false, export: true },
  { id: "DeviceModel", disablePadding: false, label: "MODEL", isActive: true, isFixed: false, export: true },
  { id: "DeviceType", disablePadding: false, label: "TYPE", isActive: true, isFixed: false, export: true },
  { id: "CollectionNames", disablePadding: false, label: "COLLECTION", isActive: true, isFixed: false, export: true, format(o, v, p = "") { return o.CollectionName; } },
  { id: "DepartmentName", disablePadding: false, label: "DEPARTMENT", isActive: true, isFixed: false, export: true },
  { id: "Labels", disablePadding: false, label: "LABELS", isActive: true, isFixed: false, export: true, format(o, v, p = "") { return v.map(l => l.name).join(); } },
  { id: "DeviceFlag", disablePadding: false, label: "DEVICE FLAG", isActive: true, isFixed: false, export: true, format(o, v, p = "") { return v == "NOT_MAPPED" ? "NO DEPARTMENT" : v; } },
  { id: "BatteryLevel", disablePadding: false, label: "BATTERY LEVEL", isActive: true, isFixed: false, export: true, format(o, v, p = "") { return v > 100 ? "Unknown" : v + "%"; } },
  { id: "Timestamp", disablePadding: false, label: "LAST KNOWN", isActive: false, isFixed: false, export: true, format(o, v, p = "") { return v ? p + formatDate(new Date(v*1000)) : ""; } },
  { id: "MeridianCreated", disablePadding: false, label: "CREATED", isActive: false, isFixed: false, export: true, format(o, v, p = "") { return v ? p + formatDate(new Date(v + "Z")) : ""; } },
  { id: "MeridianModified", disablePadding: false, label: "MODIFIED", isActive: false, isFixed: false, export: true, format(o, v, p = "") { return v ? p + formatDate(new Date(v + "Z")) : ""; } },
  { id: "FirstAssignment", disablePadding: false, label: "ASSET ASSIGNMENT", isActive: false, isFixed: false, export: true, format(o, v, p = "") { return p + v; } },
  { id: "MacId", disablePadding: false, label: "MAC ADDRESS", isActive: true, isFixed: true, export: true },
  { id: "TagCode", disablePadding: false, label: "TAG CODE", isActive: false, isFixed: false, export: true, format(o, v, p = "") { return p + v; } },
];
let rows = defaultRows.map(v => ({ ...v }));
//let dict = {
//  "map": "MAP",
//  "DeviceImage": "IMAGE",
//  "Name": "DEVICE NAME",
//  "EntityName": "ORGANIZATION",
//  "LocationName": "ASSIGNED CAMPUS",
//  "MeridianBuildingName": "CURRENT CAMPUS",
//  "FloorNumber": "FLOOR",
//  "ZoneName": "ZONE",
//  "Manufacturer": "MANUFACTURER",
//  "DeviceModel": "MODEL",
//  "DeviceType": "TYPE",
//  "CollectionNames": "COLLECTION",
//  "DepartmentName": "DEPARTMENT",
//  "Labels": "LABELS",
//  "DeviceFlag": "DEVICE FLAG",
//  "BatteryLevel": "BATTERY LEVEL",
//  "Timestamp": "LAST KNOWN",
//  "MeridianCreated": "CREATED",
//  "MeridianModified": "MODIFIED",
//  "FirstAssignment": "ASSET ASSIGNMENT",
//  "MacId": "MAC ADDRESS",
//  "TagCode": "TAG CODE"
//};
let dict = defaultRows.reduce((a, v) => ({ ...a, [v.id]: v}), {});
var match = document.cookie.match(new RegExp('(^| )table=([^;]+)'));
if (match) {
  let arrayFromCookie = JSON.parse(match[2]);
  if (arrayFromCookie) {
    //rows = arrayFromCookie.map(itm => (
    //  {
    //    id: itm.id,
    //    disablePadding: itm.id == "DeviceImage" ? true : false,
    //    label: dict[itm.id],
    //    isActive: itm.isActive,
    //    isFixed: (itm.id == "map" || itm.id == "Name" || itm.id == "MacId") ? true : false
    //  }
    //));
    rows = arrayFromCookie.map(itm => ({ ...dict[itm.id], isActive: itm.isActive }));
  }
}

let before = "";
let pos = "";

const ColumnFilter = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [checked, setChecked] = React.useState(false);
  const handleCheck = id => (event) => {
    console.log("nyah", event, id);
    let r = rows.find(row => row.id == id);
    r.isActive = !r.isActive;
    saveInCookie();
    setChecked(!checked);
    props.onChange();
  };
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [isOrderChanged, setOrder] = React.useState(0);
  const setOrderMain = () => {
    setOrder(isOrderChanged + 1);
    saveInCookie();
    props.onChange();
  }
  const [search, setSearch] = React.useState("");
  let styles = [
    {
      cursor: "pointer",
      color: "#4b2e83",
      textAlign: "center",
      borderBottom: "2px solid #4b2e83",
    },
    {
      cursor: "pointer",
      color: "lightgrey",
      textAlign: "center",
      borderBottom: "2px solid lightgrey",
    }
  ];
  const [tab, setTab] = React.useState(0);
  let firstActive = rows.findIndex(e => e.isActive);
  let lastActive = rows.findLastIndex(e => e.isActive);
  const saveInCookie = () => {
    var today = new Date();
    var expiry = new Date(today.getTime() + 30 * 24 * 3600 * 1000); // plus 30 days
    let kv = JSON.stringify(rows.map(obj => ({ id: obj.id, isActive: obj.isActive })));
    document.cookie = "table=" + kv + "; path=/; expires=" + expiry.toGMTString();
  }
  return (
    <React.Fragment>
      <div sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Column filter settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <ViewColumnOutlined color="primary" />
          </IconButton>
        </Tooltip>
      </div>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        //onClick={handleClose}
        //style={{ transform: "translateY(50px)", maxHeight: "calc(100% - 170px)" }}
      >
        <Grid
          container
          spacing={2}
        >
          <Grid item xs={6} md={6} lg={6}>
            <div
              style={tab == 0 ? styles[0] : styles[1]}
              onClick={() => setTab(0)}
            >
              <h6 style={{ marginBottom: "11px", marginTop: "4px" }}>Select Columns</h6>
            </div>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <div
              onClick={() => setTab(1)}
              style={tab == 0 ? styles[1] : styles[0]}
            >
              <h6 style={{ marginBottom: "11px", marginTop: "4px" }}>Order Columns</h6>
            </div>
          </Grid>
        </Grid>
        <div id="container" style={{ maxHeight: "70vh", overflowY: "auto", width: "max-content" }}>
          {tab == 0 && <div>
            <div id="resetDiv" style={{ display: "flex", justifyContent: "center", paddingLeft: 8, paddingRight: 8, marginTop: 8 }}>
              <Button
                variant="outlined"
                onClick={evt => {
                  var today = new Date();
                  var expired = new Date(today.getTime() - 24 * 3600 * 1000); // less 24 hours
                  document.cookie = "table=null; path=/; expires=" + expired.toGMTString();
                  //rows = [
                  //  { id: "map", disablePadding: false, label: "MAP", isActive: true, isFixed: true },
                  //  { id: "DeviceImage", disablePadding: true, label: "IMAGE", isActive: false, isFixed: false },
                  //  { id: "Name", disablePadding: false, label: "DEVICE NAME", isActive: true, isFixed: true },
                  //  { id: "EntityName", disablePadding: false, label: "ORGANIZATION", isActive: true, isFixed: false },
                  //  { id: "LocationName", disablePadding: false, label: "ASSIGNED CAMPUS", isActive: false, isFixed: false },
                  //  { id: "MeridianBuildingName", disablePadding: false, label: "CURRENT CAMPUS", isActive: true, isFixed: false },
                  //  { id: "FloorNumber", disablePadding: false, label: "FLOOR", isActive: true, isFixed: false },
                  //  { id: "ZoneName", disablePadding: false, label: "ZONE", isActive: true, isFixed: false },
                  //  { id: "Manufacturer", disablePadding: false, label: "MANUFACTURER", isActive: true, isFixed: false },
                  //  { id: "DeviceModel", disablePadding: false, label: "MODEL", isActive: true, isFixed: false },
                  //  { id: "DeviceType", disablePadding: false, label: "TYPE", isActive: true, isFixed: false },
                  //  { id: "CollectionNames", disablePadding: false, label: "COLLECTION", isActive: true, isFixed: false },
                  //  { id: "DepartmentName", disablePadding: false, label: "DEPARTMENT", isActive: true, isFixed: false },
                  //  { id: "Labels", disablePadding: false, label: "LABELS", isActive: true, isFixed: false },
                  //  { id: "DeviceFlag", disablePadding: false, label: "DEVICE FLAG", isActive: true, isFixed: false },
                  //  { id: "BatteryLevel", disablePadding: false, label: "BATTERY LEVEL", isActive: true, isFixed: false },
                  //  { id: "Timestamp", disablePadding: false, label: "LAST KNOWN", isActive: false, isFixed: false },
                  //  { id: "MeridianCreated", disablePadding: false, label: "CREATED", isActive: false, isFixed: false },
                  //  { id: "MeridianModified", disablePadding: false, label: "MODIFIED", isActive: false, isFixed: false },
                  //  { id: "FirstAssignment", disablePadding: false, label: "ASSET ASSIGNMENT", isActive: false, isFixed: false },
                  //  { id: "MacId", disablePadding: false, label: "MAC ADDRESS", isActive: true, isFixed: true },
                  //  { id: "TagCode", disablePadding: false, label: "TAG CODE", isActive: false, isFixed: false },
                  //];
                  //defaultRows.map(v => ({ ...v }));
                  rows.splice(0, rows.length, ...defaultRows);
                  props.onChange();
                }}>
                Restore Default View
              </Button>
            </div>
            <TextField
              style={{
                width: "100%",
                paddingLeft: 8,
                paddingRight: 8
              }}
              value={search}
              margin="dense"
              placeholder="Search"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment>
                    <SearchIcon />
                  </InputAdornment>
                ),
                //className: classes.input,
              }}
              onChange={e => setSearch(e.target.value)}
            />
            {rows
              .filter(r => search != "" ? r.label.toLocaleLowerCase().includes(search.toLocaleLowerCase()) : true)
              .map((row, idx) =>
                <div key={row.id} style={{ paddingRight: "9px" }}>
                  <Checkbox
                    color="primary"
                    disabled={row.isFixed}
                    checked={row.isActive}
                    onChange={handleCheck(row.id)}
                  />
                  {row.label}
                </div>)
            }
          </div>}
          {tab == 1 && <div>
            <DragDropContext
              onDragEnd={result => {
                if (!result.destination) {
                  return;
                }
                const [removed] = rows.splice(result.source.index, 1);
                rows.splice(result.destination.index, 0, removed);
                setOrderMain();
              }}
            >
              <Droppable droppableId="droppable">
                {(provided) =>
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {rows
                      .map((row, idx) =>
                        <Draggable key={row.id} draggableId={row.id} index={idx} isDragDisabled={false}>
                          {(provided, snapshot) =>
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <div
                                style={{ paddingRight: "9px", display: row.isActive ? "flex" : "none", justifyContent: "space-between", alignItems: "center" }}
                              >
                                <span>
                                  <Checkbox
                                    color="primary"
                                    disabled={true || row.isFixed}
                                    checked={row.isActive}
                                  />
                                  {row.label}
                                </span>
                                <span>
                                  <IconButton disabled={idx == firstActive} color="primary"
                                    onClick={() => {
                                      let prevIndex = idx - 1;
                                      while (rows[prevIndex].isActive == false) {
                                        prevIndex--;
                                      }
                                      rows[prevIndex] = rows.splice(idx, 1, rows[prevIndex])[0];
                                      setOrderMain();
                                    }}
                                  >
                                    <Up />
                                  </IconButton>
                                  <IconButton disabled={idx == lastActive} color="primary"
                                    onClick={() => {
                                      let nextIndex = idx + 1;
                                      while (rows[nextIndex].isActive == false) {
                                        nextIndex++;
                                      }
                                      rows[idx] = rows.splice(nextIndex, 1, rows[idx])[0];
                                      setOrderMain();
                                    }}
                                  >
                                    <Down />
                                  </IconButton>
                                </span>
                              </div>
                            </div>
                          }
                        </Draggable>
                      )
                    }
                  </div>
                }
              </Droppable>
            </DragDropContext>
          </div>}
        </div>
      </Menu>
    </React.Fragment>
  );
}

//export class CSVExport extends React.PureComponent {
export class CSVExport extends React.Component {
  constructor(props) {
    super(props);
    this.oldProps = {};
    this.oldState = {};
    this.state = {
      update: false
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    console.log("csvu", this.props === nextProps, this.state === nextState);
    if (this.props !== nextProps) {
        const propNames = Object.getOwnPropertyNames(this.props);
        propNames.forEach(p => console.log("csvuProp", p, this.props[p] === nextProps[p]));
    }
    if (this.state !== nextState) {
        const propNames = Object.getOwnPropertyNames(this.state);
        propNames.forEach(p => console.log("csvuStat", p, this.state[p] === nextState[p]));
    }
    return !(nextState === this.state && nextProps.columns === this.props.columns && nextProps.data === this.props.data && nextProps.filename === this.props.filename && nextProps.disabled === this.props.disabled);
  }

  //componentDidMount() {
  componentDidUpdate(prevProps, prevState) {
    if (this.state !== prevState)
      document.getElementById("csvlink").click();
  }

  getCsvData = () => {
    this.setState({ update: true });
    return false;
  };

  render() {
    console.log("csvr", this.props === this.oldProps, this.state === this.oldState);
    if (this.props !== this.oldProps) {
        const propNames = Object.getOwnPropertyNames(this.props);
        propNames.forEach(p => console.log("csvrProp", p, this.props[p] === this.oldProps[p]));
    }
    if (this.state !== this.oldState) {
        const propNames = Object.getOwnPropertyNames(this.state);
        propNames.forEach(p => console.log("csvrStat", p, this.state[p] === this.oldState[p]));
    }

    let csvData = [];
    if (this.state !== this.oldState) {
      let csvColumns = this.props.columns.filter(r => r.isActive && r.export);
      csvData.push(csvColumns.map(r => r.label));
      this.props.data.forEach(n => csvData.push(csvColumns.map(r => r.format ? r.format(n, n[r.id], "\t") : n[r.id])));
    }
    this.oldProps = this.props;
    this.oldState = this.state;

    return (
      <CSVLink
        data={csvData}
        filename={this.props.filename}
        style={this.props.disabled ? { pointerEvents: "none", textDecoration: "none" } : { pointerEvents: "auto", textDecoration: "none" }}
        onClick={csvData.length == 0 ? this.getCsvData : undefined}
        id="csvlink"
      >
        {this.props.children}
      </CSVLink>
    );
  }
}

class AssignPopUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      manufacturerData: [],
      modelData: [],
      typeData: []
    };
  }

  componentDidMount() {
    this.fetchData("manufactures");
    this.fetchData("assettypes");
  }

  fetchData(dataType, id = "") {
    let url =
      dataType === "assetmodels"
        ? `/masters/${dataType}/${id}`
        : `/masters/${dataType}`;

    apiRequest(url, "get")
      .then(response => {
        const data = response.data.map(c => {
          return {
            id: c.ID,
            name: c.Value
          };
        });
        switch (dataType) {
          case "manufactures":
            this.setState({
              manufacturerData: data.sort(byName)
            });
            break;
          case "assetmodels":
            this.setState({
              modelData: data.sort(byName)
            });
            break;
          case "assettypes":
            this.setState({
              typeData: data.sort(byName)
            });
            break;
        }
      })
      .catch(error => console.log(error));
  }

  handleAssign = () => {
    const data = {
      mac: this.props.selected,
      manufacturer: this.state.manufacturerObject ? this.state.manufacturerObject.name : "",
      model: this.state.modelObject ? this.state.modelObject.name : "",
      type: this.state.typeObject ? this.state.typeObject.name : ""
    };
    apiRequest("/assets", "put", data)
      .then(x => {
        if (x.status > 0)
          //this.setState({ update: false }, () => this.props.getAsset());
          this.props.onAssigned();
        if (x.status > 0 && x.message == null)
          this.props.showMessageBox("SUCCESS");
        else
          this.props.showMessageBox(x.message);
        this.props.onClose();
      })
      .catch(err => {
        this.props.onClose();
        this.props.showMessageBox("ERROR");
      });
  }

  render() {
    const { classes, selected } = this.props;
    return (
      <PopUp
        open={this.props.open}
        title={"Assign Devices"}
        handleAssign={this.handleAssign}
        handleClose={this.props.onClose}
        //assignDisabled={!this.state.EntityId}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Autocomplete
            //PopperComponent={PopperMy}
            id="manu"
            disabled={selected.length == 0 || this.state.manufacturerData.length == 0}
            options={this.state.manufacturerData.sort(byName)}
            value={this.state.manufacturerObject}
            getOptionSelected={(option, value) => option.id == value.id}
            getOptionLabel={(option) => option.name}
            onChange={(evt, obj) => {
              console.log("wt", obj);
              if (obj == null) {
                this.setState({ manufacturerObject: obj, modelObject: obj, modelData: [] });
              } else {
                this.setState({ manufacturerObject: obj, modelObject: null }, () => {
                  if (obj)
                    this.fetchData("assetmodels", obj.id);
                });
              }
            }}
            renderOption={(option) => (
              <div style={{ padding: "8px 2px" }}>{option.name}</div>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Manufacturer"
                variant="outlined"
                margin="dense"
                className={classes.textField}
              />
            )}
          />

          <Autocomplete
            id="model"
            //PopperComponent={PopperMy}
            disabled={selected.length == 0 || !this.state.manufacturerObject || this.state.modelData.length == 0}
            options={this.state.modelData.sort(byName)}
            value={this.state.modelObject ? this.state.modelObject : null}
            defaultValue={null}
            getOptionSelected={(option, value) => option.id == value.id}
            getOptionLabel={(option) => option.name}
            onChange={(evt, obj) => { console.log("wt", obj); this.setState({ modelObject: obj }); }}
            renderOption={(option) => (
              <div style={{ padding: "8px 2px" }}>{option.name}</div>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Model"
                variant="outlined"
                margin="dense"
                className={classes.textField}
              />
            )}
          />

          <Autocomplete
            id="type"
            //PopperComponent={PopperMy}
            disabled={selected.length == 0 || this.state.typeData.length == 0}
            options={this.state.typeData.sort(byName)}
            value={this.state.typeObject}
            getOptionSelected={(option, value) => option.id == value.id}
            getOptionLabel={(option) => option.name}
            onChange={(evt, obj) => { console.log("wt", obj); this.setState({ typeObject: obj }); }}
            renderOption={(option) => (
              <div style={{ padding: "8px 2px" }}>{option.name}</div>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Device Type"
                variant="outlined"
                margin="dense"
                className={classes.textField}
              />
            )}
          />

          {/* <TextField
            disabled={selected.length == 0 || this.state.manufacturerData.length == 0}
            width="90%"
            id="outlined-select"
            select
            style={{
              marginTop: 20,
              marginBottom: 0
            }}
            label="Manufacturer"
            placeholder="Manufacturer"
            margin="dense"
            className={classes.textField}
            value={this.state.manufacturer}
            onChange={(v) => {
              console.log("asd", v.target.value);
              this.setState({ manufacturer: v.target.value }, () => this.fetchData("assetmodels", v.target.value.id));
            }}
            SelectProps={{
              MenuProps: {
                className: classes.menu
              }
            }}
            InputProps={{ className: classes.input }}
            InputLabelProps={{
              shrink: true,
              className: classes.label
            }}
            variant="outlined"
          >
            {this.state.manufacturerData.map(option => (
              <MenuItem key={option.id} value={option}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            disabled={selected.length == 0 || !this.state.manufacturer || this.state.modelData.length == 0}
            width="90%"
            id="outlined-select"
            select
            style={{
              marginTop: 20,
              marginBottom: 0
            }}
            label="Model"
            placeholder="Model"
            margin="dense"
            className={classes.textField}
            value={this.state.model}
            onChange={(v) => {
              console.log("asd", v.target.value);
              this.setState({ model: v.target.value });
            }}
            SelectProps={{
              MenuProps: {
                className: classes.menu
              }
            }}
            InputProps={{ className: classes.input }}
            InputLabelProps={{
              shrink: true,
              className: classes.label
            }}
            variant="outlined"
          >
            {this.state.modelData.map(option => (
              <MenuItem key={option.id} value={option}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            disabled={selected.length == 0 || this.state.typeData.length == 0}
            width="90%"
            id="outlined-select"
            select
            style={{
              marginTop: 20,
              marginBottom: 0
            }}
            label="Device Type"
            placeholder="Type"
            margin="dense"
            className={classes.textField}
            value={this.state.type}
            onChange={(v) => {
              console.log("asd", v.target.value);
              this.setState({ type: v.target.value });
            }}
            SelectProps={{
              MenuProps: {
                className: classes.menu
              }
            }}
            InputProps={{ className: classes.input }}
            InputLabelProps={{
              shrink: true,
              className: classes.label
            }}
            variant="outlined"
          >
            {this.state.typeData.map(option => (
              <MenuItem key={option.id} value={option}>
                {option.name}
              </MenuItem>
            ))}
          </TextField> */}
        </div>
      </PopUp>
    );
  }
}

const sty = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  // rootcheckbox: {
  //   color: "#4b2e83",
  //   "&$checked": {
  //     color: "#4b2e83"
  //   }
  // },
  // table: {
  //   minWidth: 50
  // },
  // tableWrapper: {
  //   overflowX: "auto"
  // },
  button: {
    color: "#4b2e83",
    height: "40px",
    width: "100%",
    padding: "0px !important"
  },
  input: {
    height: 38
  },
  label: {
    marginTop: "-5px"
  },
  textField: { width: "100%", margin: "0px !important" }
});

export const AssignPopUpwithStyle = withStyles(sty)(AssignPopUp)

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    let scroll = document.getElementById("tableScroll");
    let tr = document.getElementById("headerRow");
    let length = 0;
    let i = 0;
    while (tr.childNodes[i].id != property) {
      length += tr.childNodes[i].offsetWidth;
      i++;
    }
    //console.log("what- before", length, scroll.scrollLeft, scroll.scrollWidth);
    //Save position
    before = length;
    pos = scroll.scrollLeft;
    //////////
    this.props.onRequestSort(event, property);
  };

  render() {
    const {
      handleSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount
    } = this.props;

    return (
      <TableHead>
        <TableRow /*style={{ height: 45 }}*/ id="headerRow">
          {isCheckBoxColumnActive && (
            <TableCell
              style={{ /*display: "flex",*/ whiteSpace: "nowrap" }}
            >
              <Checkbox
                style={{ padding: 0 }}
                disabled={rowCount === 0}
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected > 0 && numSelected === rowCount}
                color="primary"
                onChange={handleSelectAllClick}
              />
              Select all
            </TableCell>
          )}
          {rows.filter(r => r.isActive).map(
            row => (
              <TableCell
                style={{ color: "#4b2e83", whiteSpace: "nowrap" }}
                key={row.id}
                id={row.id}
                align={row.numeric ? "right" : "left"}
                padding={row.disablePadding ? "none" : "default"}
                sortDirection={orderBy === row.id ? order : false}
              >
                {(row.id != 'map' && row.id != 'DeviceImage') ? (
                  <Tooltip
                    title={"Sort by " + row.label}
                    placement={row.numeric ? "bottom-end" : "bottom-start"}
                    enterDelay={300}
                  >
                    <TableSortLabel
                      style={{ color: "#4b2e83" }}
                      active={orderBy === row.id}
                      direction={order}
                      onClick={this.createSortHandler(row.id)}
                    >
                      {row.label}
                    </TableSortLabel>
                  </Tooltip>
                ) : (
                  row.label
                )}
              </TableCell>
            ),
            this
          )}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  numSelected: PropTypes.number.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 2
  },
  root1: {
    width: "100%",
    marginTop: 5
  },
  table: {
  },
  tableWrapper: {
    overflowX: "auto",
    height: "calc(100vh - 320px)" //"65vh"
  },
  noPadding: {
    padding: 0
  },
  input: {
    height: 40,
    //padding: "20px"
  },
  progress: {
    color: "#4b2e83"
  }
});

class EnhancedTable extends React.Component {
  static contextType = PFContext;
  constructor(props) {
    super(props);
    this.childDiv = React.createRef();
    this.wait = "";
  }

  state = {
    mac: "",
    maptablebackground: "white",
    maptableborder: "null",
    savebtnstate: true,
    deletebtnstate: true,
    deviceid: "0",
    isMapHidden: true,
    isHidden: this.props.location && this.props.location.state && ( Object.keys(this.props.location.state).length > 1 || !this.props.location.state.hasOwnProperty("searchText")) ? false : true,
    isHiddenDetails: true,
    openAssign: false,
    openDelete: false,
    deleteRadio: "hard",
    order: "asc",
    orderBy: "",
    selected: "",
    data: [],
    selectedAssetDetail: {},
    page: 0,
    rowsPerPage: 25,
    searchText: this.props.location.state ? this.props.location.state.searchText : "",
    haveNoAcess: false,
    showNoDeviceMsg: false,
    syncData: {},
    //isCheckBoxColumnActive: false,
    selectedAssets: []
  };

  componentDidMount() {
    apiRequest("/labels", "get")
      .then(response => {
        this.setState({ labels: response.data }/*, () => console.log("labels", this.state.labels)*/);
      });
    // this.getSyncData();

    // setInterval(() => {
    //   this.getSyncData();
    // }, 120000);
  }

  /*componentDidMount() {
    apiRequest(`/accesscontrol`, "get").then(x => {
      if (x.data.length == 0) {
        this.props.history.push("/noaccess");
      } else {
        if (this.props.location.search.includes("BATTERY")) {
          if (this.props.location.search.includes("id")) {
            this.props.getAsset(
              `entity_id=${this.props.location.search.substring(
                4,
                this.props.location.search.indexOf("&")
              )}&battery_level_between=0,30`
            );
          } else {
            this.props.getAsset(`battery_level_between=0,30`);
          }
        } else if (this.props.location.search.includes("NOT_TRACEABLE")) {
          this.props.getAsset(
            `entity_id=${this.props.location.search.substring(
              4,
              this.props.location.search.indexOf("&")
            )}&device_flag=NOT_TRACEABLE`
          );
        } else if (this.props.location.search.includes("MISSING_SYNC")) {
          this.props.getAsset(
            `entity_id=${this.props.location.search.substring(
              4,
              this.props.location.search.indexOf("&")
            )}&device_flag=MISSING_SYNC`
          );
        } else if (this.props.location.search.includes("HEALTHY")) {
          this.props.getAsset(
            `entity_id=${this.props.location.search.substring(
              4,
              this.props.location.search.indexOf("&")
            )}&device_flag=HEALTHY`
          );
        } else if (this.props.location.search.includes("id")) {
          this.props.getAsset(
            `entity_id=${this.props.location.search.substring(
              4,
              this.props.location.search.indexOf("&")
            )}`
          );
        } else {
          //this.props.getAsset();
        }
      }
    });
  }*/

  componentWillReceiveProps(prop) {
    const { assets, hideLoader } = prop;
    //console.log("hideLoader", assets, hideLoader);
    if (window.x == 0)
      this.setState({
        isHiddenDetails: true
      });
    window.x = 1;
    if (hideLoader && window.y != window.z) {
      //console.log("hide");
      if (assets.length == 0) {
        this.setState({
          showNoDeviceMsg: true
        });
      }
      let access = isPortalAdmin ? null : getAccess().sort((a, b) => {
        if (a.access_level === b.access_level) {
          if (a.collection_id == b.collection_id) {
            if (a.location_id == b.location_id) {
              return a.entity_id - b.entity_id;
            }
            return a.location_id - b.location_id;
          }
          //if (a.collection_id == null || b.collection_id == null || a.location_id === b.location_id)
            return a.collection_id - b.collection_id;
          //return b.location_id - a.location_id;
        }
        return b.access_level - a.access_level;
      });
      let data = assets.map((n, i) => {
        if (n.CollectionIds) {
          n.CollectionIds = JSON.parse("[" + n.CollectionIds + "]");
        }
        n.CollectionNames = null;
        if (n.CollectionName) {
          n.CollectionNames = n.CollectionName.split(",");
        }
        if (n.DeviceFlag == "NOT_TRACEABLE") {
          n.ZoneName = "";
          n.MeridianBuildingName = "";
          n.FloorNumber = "";
        }
        if (this.state.labels && this.state.labels.length > 0) {
          n.Labels = this.state.labels.filter(l => l.mac == n.MacId);
        }
        n.FirstAssignment = n.EntityId == null ? "Unassigned" : n.FirstAssignment ? formatDate(new Date(n.FirstAssignment + "Z")) : "";
        if (isPortalAdmin) {
          n.AccessLevel = 500;
        } else {
          let policy = access.find(item =>
            item.entity_id === n.EntityId &&
            (item.location_id == null || item.location_id === n.LocationId) &&
            (item.collection_id == null || item.collection_id === n.DepartmentId  || (n.CollectionIds != null && n.CollectionIds.some(c => c === item.collection_id)))
          );
          n.AccessLevel = policy == null ? 100 : policy.access_level;
        }
        return n;
      });
      if (this.state.searchText) {
        let search = this.state.searchText.toLowerCase();
        data = data.filter(d =>
          //Object.values(d).some(p => typeof p === 'string' && p.toLowerCase().includes(this.state.searchText.toLowerCase()))
          d.hasOwnProperty("Name") && typeof d.Name === 'string' && d.Name.toLowerCase().includes(search) ||
          d.hasOwnProperty("TagCode") && typeof d.TagCode === 'string' && d.TagCode.toLowerCase().includes(search) ||
          d.hasOwnProperty("MeridianBuildingName") && typeof d.MeridianBuildingName === 'string' && d.MeridianBuildingName.toLowerCase().includes(search) ||
          d.hasOwnProperty("MacId") && typeof d.MacId === 'string' && d.MacId.toLowerCase().includes(search) ||
          d.hasOwnProperty("Labels") && d.Labels != null && d.Labels.some(l => l.name.toLowerCase().includes(search))
        )
      }
      data = stableSort(data, getSorting(this.state.order, this.state.orderBy));
      this.setState({
        // data: assets.map(n => {
        //   if (n.DeviceFlag == "NOT_TRACEABLE") {
        //     n.ZoneName = "";
        //     n.MeridianBuildingName = "";
        //     n.FloorNumber = "";
        //   }
        //   return n;
        // }),
        data: data,
        page: 0,
        showNoDeviceMsg: data.length == 0 ? true : this.state.showNoDeviceMsg
      });
    }
  }

  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden
    });
  }

  handleScroll = () => {
    const { index, selected } = this.props;
    if (index === selected) {
      const that = this;

      that.childDiv.current.scrollIntoView({
        behavior: "smooth",
        block: "start"
      });
    }
  };

  MapHidden(value, macid, hidden, locid, floorid) {
    // this.setState({
    //   deviceid: value,
    //   selectedmacid: macid,
    //   selectedLocationId: locid,
    //   selectedFloorId: floorid
    // });
    if (hidden) {
      this.setState({
        isMapHidden: hidden
      });
    } else {
      this.getAssetFromMeridian(locid, macid).then(data => {
        if (data) {
          this.setState({
            deviceid: value,
            selectedmacid: macid,
            selectedLocationId: data.location,
            selectedFloorId: data.map.id,
            //isMapHidden: hidden
          },
          // () => {
          //   this.handleScroll();
          //   this.setState({ isMapHidden: hidden });
          // }
          )
        }
      }
      );
      this.handleScroll();
      this.setState({ isMapHidden: hidden });
    }

    // this.setState({ isMapHidden: value2 });
  }

  getAssetFromMeridian(locid, macid) {
    var url =`https://edit.meridianapps.com/api/locations/${locid}/asset-beacons/` + macid;
    return fetch(url, getOption())
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          this.setState({ hideMap: true });
        }
      })
      .then(data => {
        return data;
      })
      .catch(err => {
        console.log("error:", err);
    });
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({ order: order, orderBy: orderBy, data: stableSort(this.state.data, getSorting(order, orderBy)) }, () => {
      let scroll = document.getElementById("tableScroll");
      let tr = document.getElementById("headerRow");
      let length = 0;
      let i = 0;
      while (tr.childNodes[i].id != property) {
        length += tr.childNodes[i].offsetWidth;
        i++;
      }
      //console.log("what- after", length, scroll.scrollLeft, scroll.scrollWidth);
      //console.log("what - apply", /*scroll.scrollLeft*/ pos - (before - length));
      scroll.scrollLeft = pos - (before - length);
    });
  };

  toggleHiddenDetails(selectedAssetDetail) {
    // this.setState({
    //   isHiddenDetails: !this.state.isHiddenDetails
    // });
    if (this.state.isHiddenDetails) {
      window.scrollTo(0, 0);
    }

    this.setState({
      deviceid: selectedAssetDetail.id,
      isMapHidden: true,
      selectedAssetDetail
    });
    let data = {
      "selectedAssetDetail": selectedAssetDetail
    };
    this.props.history.push({
      pathname: `/asset/${selectedAssetDetail.DeviceId}`,
      state: data
    });
  }

  handleClick = (event, id) => {
    this.setState({ selected: "0", selected: id });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  handleChangePage = (event, page) => {
    this.setState({ page }, () => {
      atLastPageScrollToTop(this.state.data.length, this.state.rowsPerPage, page);
    });
  };

  handleChangeRowsPerPage = event => {
    let max = event.target.value * this.state.page;
    //PONTENTION IMPROVEMENT FOR FUTURE

    // if (/*event.target.value*/ max > this.state.data.length && this.state.page != 0) {
    //   let newPageNo = Math.floor(max / this.state.data.length);
    //   console.log("calc", newPageNo, event.target.value);
    //   this.setState({ rowsPerPage: event.target.value, page: newPageNo-1 /*0*/ });
    // } else {
    //   console.log("calc2", this.state.rowsPerPage, event.target.value, this.state.page);
    //   let currentMax = this.state.rowsPerPage * (this.state.page + 1);
    //   let newPageNo = Math.floor(currentMax / event.target.value);
    //   newPageNo = newPageNo > 1 ? newPageNo - 1 : 0;
    //   this.setState({ rowsPerPage: event.target.value, page: newPageNo });
    //   //this.setState({ rowsPerPage: event.target.value });
    // }
    if (max > this.state.data.length && this.state.page != 0)
      this.setState({ rowsPerPage: event.target.value, page: 0 });
    else
      this.setState({ rowsPerPage: event.target.value });
  };

  handleDetailsPanel = Value => {
    this.setState({ isHiddenDetails: Value });
  };

  setSearchText = e => {
    if (e.target.value.length > 0) {
      this.props.history.push({
        pathname: this.props.location.pathname,
        state: {
          ...this.props.location.state,
          searchText: e.target.value
        }
      });
    } else {
      //Remove from state
      let oldState = {...this.props.location.state};
      delete oldState.searchText;
      const isEmpty = Object.keys(oldState).length === 0;
      oldState = isEmpty ? undefined : oldState;
      this.props.history.push({
        pathname: this.props.location.pathname,
        state: oldState
      });
    }
    let search = e.target.value.toLowerCase();
    if (e.target.value) {
      let data = this.props.assets.filter(d =>
        //Object.values(d).some(p => typeof p === 'string' && p.toLowerCase().includes(search))
        // d.Name.toLowerCase().includes(search) ||
        // d.TagCode.toLowerCase().includes(search) ||
        // d.MeridianBuildingName.toLowerCase().includes(search) ||
        // // d.FloorNumber.toLowerCase().includes(search) ||
        // // d.ZoneName.toLowerCase().includes(search) ||
        // // d.Manufacturer.toLowerCase().includes(search) ||
        // // d.DeviceModel.toLowerCase().includes(search) ||
        // // d.DeviceType.toLowerCase().includes(search) ||
        // // d.CollectionName.toLowerCase().includes(search) ||
        // // //d.BatteryLevel.toLowerCase().includes(search) ||
        // // d.DeviceFlag.toLowerCase().includes(search) ||
        // // //d.Timestamp.toLowerCase().includes(search) ||
        // d.MacId.toLowerCase().includes(search)
        d.hasOwnProperty("Name") && typeof d.Name === 'string' && d.Name.toLowerCase().includes(search) ||
        d.hasOwnProperty("TagCode") && typeof d.TagCode === 'string' && d.TagCode.toLowerCase().includes(search) ||
        d.hasOwnProperty("MeridianBuildingName") && typeof d.MeridianBuildingName === 'string' && d.MeridianBuildingName.toLowerCase().includes(search) ||
        d.hasOwnProperty("MacId") && typeof d.MacId === 'string' && d.MacId.toLowerCase().includes(search) ||
        d.hasOwnProperty("Labels") && d.Labels != null && d.Labels.some(l => l.name.toLowerCase().includes(search))
      );
      this.setState({ searchText: e.target.value, data: stableSort(data, getSorting(this.state.order, this.state.orderBy)), showNoDeviceMsg: data.length == 0 ? true : this.state.showNoDeviceMsg, page: 0 });
    } else {
      this.setState({ searchText: e.target.value, data: stableSort(this.props.assets, getSorting(this.state.order, this.state.orderBy)), page: 0 });
    }

    //this.setState({ searchText: e.target.value, data: data });
  };

  getSyncData() {
    apiRequest("/assets/syncreport", "get")
      .then(res => {
        this.setState({
          syncData: JSON.parse(res.data[0])
        });
      })
      .catch(err => {
        console.log(err);
      });
  }

  timeZone = () => /\((.*)\)/.exec(new Date().toString())[1];

  isSelectedRow = id => this.state.selectedAssets.indexOf(id) !== -1;

  selectRow = (evt, id) => {
    if (evt.target.checked) {
      let newList = this.state.selectedAssets;
      newList.push(id);
      this.setState({ selectedAssets: newList });
    } else {
      this.setState({ selectedAssets: this.state.selectedAssets.filter(a => a != id) });
    }
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      if (isPortalAdmin)
        this.setState({ selectedAssets: this.state.data.map(n => n.MacId) });
      else
        this.setState({ selectedAssets: this.state.data.filter(n => n.AccessLevel >= 500).map(n => n.MacId) });
    } else {
      this.setState({ selectedAssets: [] });
    }
  };

  handleDelete = () => {
    const data = {
      mac: this.state.selectedAssets
    };
    const that = this;

    apiRequest(this.state.deleteRadio == "hard" ? "/assets/hard" : "/assets", "delete", data)
      .then(x => {
        if (x.status > 0) {
          isCheckBoxColumnActive = false;
          that.setState({ openDelete: false, selectedAssets: [] }, () => that.props.getAsset());
        }
        if (x.status > 0 && x.message == null)
          that.props.showMessageBox("SUCCESS");
        else
          that.props.showMessageBox(x.message);
        that.setState({ openDelete: false });
      })
      .catch(err => {
        that.setState({ openDelete: false });
        that.props.showMessageBox("ERROR");
      });
  };

  hardDeleteRadio() {
    return(
      <div>
        Delete Asset & Tag<br/>
        This will delete all selected assets and associated tag information from the system.
      </div>
    );
  }

  softDeleteRadio() {
    return(
      <div>
        Delete Asset<br/>
        This will delete all selected assets. However, all associated tag information will remain in Meridian.
      </div>
    );
  }

  renderCells(n, property) {
    if (property == "map")
      return (
        <TableCell
          key={property}
          style={{
            color: "#4b2e83",
          }}
        >
          <Tooltip
            style={{ padding: 0 }}
            title={"Show Map for Device: " + n.DeviceId}
          >
            <IconButton
              aria-label="Show Map"
              onClick={this.MapHidden.bind(
                this,
                n.DeviceId,
                n.MacId,
                false,
                n.MeridianLocationId,
                n.MeridianMapId
              )}
              style={{ padding: 0 }}
            >
              <MapIcon style={{ color: "#4b2e83" }} />
            </IconButton>
          </Tooltip>
        </TableCell>
      );
    else if (property == "DeviceImage")
      return (
        <TableCell key={property} padding="none">
          <img
            className="image"
            style={{
              borderRadius: "50%",
            }}
            src={n.ImageUrl ? n.ImageUrl : defaultImage}
            width="30px"
            height="30px"
            alt=""
          />
        </TableCell>
      );
    else if (property == "MeridianBuildingName")
      return (
        <Tooltip
          key={property}
          //style={{ padding: 0 }}
          title={"Campus as of last data refresh"}
        >
          <TableCell onClick={this.toggleHiddenDetails.bind(this, n)}>
            {n.MeridianBuildingName}
          </TableCell>
        </Tooltip>
      );
    else if (property == "FloorNumber")
      return (
        <Tooltip
          key={property}
          //style={{ padding: 0 }}
          title={"Floor as of last data refresh"}
        >
          <TableCell onClick={this.toggleHiddenDetails.bind(this, n)}>
            {n.FloorNumber}
          </TableCell>
        </Tooltip>
      );
    else if (property == "CollectionNames")
      return (
        <TableCell key={property} onClick={this.toggleHiddenDetails.bind(this, n)}>
          {n.CollectionNames && n.CollectionNames.length > 0 && (
            <Tooltip title={<div>{n.CollectionNames.map(c => <span key={c}>{c}<br/></span>)}</div>} placement="right">
              <Badge badgeContent={n.CollectionNames.length} color="secondary">
                <CollectionsBookmarkOutlined color="primary" />
              </Badge>
            </Tooltip>
          )}
        </TableCell>
      );
    else if (property == "Labels")
      return (
        <TableCell key={property} onClick={this.toggleHiddenDetails.bind(this, n)}>
          {n.Labels && n.Labels.length > 0 && (
            <Tooltip title={<div>{n.Labels.map(l => <span key={l.id}>{l.name}<br/></span>)}</div>} placement="right">
              <Badge badgeContent={n.Labels.length} color="secondary">
                <LocalOfferOutlined color="primary" />
              </Badge>
            </Tooltip>
          )}
        </TableCell>
      );
    else if (property == "DeviceFlag")
      return (
        <TableCell key={property} onClick={this.toggleHiddenDetails.bind(this, n)}>
          {dict[property].format(n, n[property])/*n.DeviceFlag == "NOT_MAPPED" ? "NO DEPARTMENT" : n.DeviceFlag*/}
        </TableCell>
      );
    else if (property == "BatteryLevel")
      return (
        <TableCell key={property} onClick={this.toggleHiddenDetails.bind(this, n)}>
          {dict[property].format(n, n[property])/*n.BatteryLevel > 100 ? "Unknown" : n.BatteryLevel + "%"*/}
        </TableCell>
      );
    else if (property == "Timestamp")
      return (
        <TableCell key={property} onClick={this.toggleHiddenDetails.bind(this, n)}>
          {dict[property].format(n, n[property])/*n.Timestamp ? formatDate(new Date(n.Timestamp*1000)) : ""*/}
        </TableCell>
      );
    else if (property == "MeridianCreated")
      return (
        <TableCell key={property} onClick={this.toggleHiddenDetails.bind(this, n)}>
          {dict[property].format(n, n[property])/*n.MeridianCreated ? formatDate(new Date(n.MeridianCreated + "Z")) : ""*/}
        </TableCell>
      );
    else if (property == "MeridianModified")
      return (
        <TableCell key={property} onClick={this.toggleHiddenDetails.bind(this, n)}>
          {dict[property].format(n, n[property])/*n.MeridianModified ? formatDate(new Date(n.MeridianModified + "Z")) : ""*/}
        </TableCell>
      );
    else if (property == "TagCode")
      return (
        <TableCell
          key={property}
          component="th"
          scope="row"
          onClick={this.toggleHiddenDetails.bind(this, n)}
        >
          {n.TagCode}
        </TableCell>
      );
    return (
      <TableCell key={property} onClick={this.toggleHiddenDetails.bind(this, n)}>
        {n[property]}
      </TableCell>
    );
  }

  render() {
    const { classes } = this.props;
    const { data, order, orderBy, selected, rowsPerPage, page, selectedAssets } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
    const { numSelected } = this.props;
    //const sortedData = (data.length > 0 && this.props.hideLoader) ? stableSort(data, getSorting(order, orderBy)) : [];
    const columns = rows.filter(r => r.isActive);
    var access = getAccess();
    var mm = require('moment-timezone');
    var zone = mm.tz.zone('America/New_York');
    var abbs = " " + mm.tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('z').toString();
    var abbs2 = " " + mm.tz(Intl.DateTimeFormat().resolvedOptions().timeZone).zoneAbbr();
    //console.log("abbs", mm.tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('z'), abbs2, window.Intl.DateTimeFormat().resolvedOptions().timeZone);
    //console.log("bakuu", mm.tz([2012, 0], 'Asia/Tokyo').format('ha z'));
    //console.log("guess", mm.tz.guess(), mm.tz.zone(mm.tz.guess()).abbr(new Date().getTimezoneOffset()));
    //console.log("TZ", this.state.syncData.sync_time, this.timeZone(), mm.tz('America/Los_Angeles').format('z'), zone, Intl.DateTimeFormat().resolvedOptions().timeZone);
    const a = this.state.syncData.sync_time
      ? this.state.syncData.sync_time.replace(/-/g, "/")
      : "";
    var dateTime = a == ""
      ? ""
      : new Date(`${a} UTC`).toISOString();
    return (
      <div ref={this.childDiv} style={{ paddingBottom: 20 }}>
        {/* <Collapse in={this.state.isHiddenDetails}>
          <Grid container spacing={8}>
            <Grid item xs={6}>
              <div
                style={{
                  textAlign: "center",
                  borderBottom: "2px solid #4b2e83"
                }}
              >
                <div className="nav-link">
                  <h5
                    style={{
                      color: "#4b2e83"
                    }}
                  >
                    Tabular View
                  </h5>
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div
                style={{
                  textAlign: "center",
                  borderBottom: "2px solid lightgrey"
                }}
              >
                <Link className="nav-link" to="/devices/viewmap">
                  <h5
                    style={{
                      color: "lightgrey"
                    }}
                  >
                    Map View
                  </h5>{" "}
                </Link>
              </div>
            </Grid>
          </Grid>
        </Collapse> */}

        <Collapse in={this.state.isHiddenDetails}>
          <Grid
            container
            spacing={2}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Grid item xs={10}>
              <TextField
                style={{
                  width: "100%",
                  // paddingLeft: 30,
                  // paddingRight: 10,
                  paddingBottom: "1px",
                }}
                value={this.state.searchText}
                margin="dense"
                placeholder="Search"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  className: classes.input,
                }}
                onChange={this.setSearchText}
              />
            </Grid>
            <Grid
              item
              xs
              //xs={2}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="outlined"
                onClick={this.toggleHidden.bind(this)}
                style={{
                  color: "#4b2e83",
                  //opacity: "1",
                  //outline: "none"
                }}
                //className="buttonnobg float-center"
              >
                Filter
              </Button>
            </Grid>
            <Grid item /*xs={6} md={4} lg={8}*/ style={{ "textAlign": "end", alignSelf: "center", width: "24px", marginLeft: "8px" }}>

            </Grid>
          </Grid>

          {/* <Toolbar style={{ paddingTop: 3, paddingBottom: 3, paddingLeft: 0, paddingRight: 0 }}>
            <TextField
              style={{
                width: "100%",
                // paddingLeft: 30,
                // paddingRight: 10,
                paddingBottom: "1px"
              }}
              value={this.state.searchText}
              margin="dense"
              placeholder="Search"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment>
                    <SearchIcon />
                  </InputAdornment>
                ),
                className: classes.input
              }}
              onChange={this.setSearchText}
            />
            <div>
              <Tooltip title="Advanced Search">
                <Button
                  //aria-label="Advanced Search"
                  onClick={this.toggleHidden.bind(this)}
                  style={{ outline: "none" }}
                >
                  Filter
                </Button>
              </Tooltip>
            </div>
          </Toolbar> */}

          <Collapse
            in={
              !this.state.isHidden ||
              this.props.location.search.includes("flag=")
            }
          >
            <PFContext.Consumer>
              {(data) => (
                <Filters
                  //searchString={this.state.searchText}
                  searchString=""
                  params={this.props.location.search}
                  resetSearchText={() => this.setState({ searchText: "" })}
                  values={data}
                  setLoading={v => this.setState({ showNoDeviceMsg: v })}
                />
              )}
            </PFContext.Consumer>
          </Collapse>
        </Collapse>
        {this.state.isHiddenDetails && (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
            </div>
            <div style={{ display: "flex" }}>
              <CSVExport
                columns={rows}
                data={data}
                filename={"Assets.csv"}
                disabled={!this.props.hideLoader}
              >
                <Tooltip title="Export to CSV">
                  <span>
                    <IconButton size="small" disabled={!this.props.hideLoader}>
                      <SaveIcon color="primary" />
                    </IconButton>
                  </span>
                </Tooltip>
              </CSVExport>
              {(isPortalAdmin || access.some(item => item.access_level >= 500/* && item.resource_type != "collection"*/)) && (
                <Tooltip title="Select rows">
                  <span>
                    <IconButton size="small" disabled={!this.props.hideLoader} onClick={() => {
                      isCheckBoxColumnActive = !isCheckBoxColumnActive;
                      this.setState({ update: true });
                    }}>
                      <EditIcon color="primary" />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
              <ColumnFilter onChange={() => {
                rows = [ ...rows ];
                this.setState({ update: true });
              }} />
            </div>
          </div>
        )}
        {!this.state.isMapHidden && (
          <Paper style={{ transition: 0.5 }} className={classes.root}>
            <div>
              <Dialog
                fullScreen
                open={
                  this.state.isFullScreen
                } /*onClose={handleClose} TransitionComponent={Transition}*/
              >
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingInline: "24px" }}>
                  <Typography
                    style={{ color: "#4b2e83" }}
                    variant="h6"
                    id="tableTitle"
                  >
                    Device ID: {this.state.deviceid}
                  </Typography>
                  {/* <Tooltip
                    //style={{ padding: 0 }}
                    title="Close Fullscreen"
                  >
                    <IconButton
                      //aria-label="Show Map"
                      onClick={() => this.setState({ isFullScreen: false })}
                      style={{ width: "fit-content" }}
                    >
                      <Close style={{ color: "#4b2e83" }} />
                    </IconButton>
                  </Tooltip> */}
                  <Tooltip title="Close Fullscreen">
                    <IconButton
                      onClick={() => this.setState({ isFullScreen: false })}
                    >
                      <SvgIcon>
                        <path fill="none" stroke="#4b2e83" strokeWidth="2" d="M2,14 L10,14 L10,22 M1,23 L10,14 M23,1 L14,10 M22,10 L14,10 L14,2"></path>
                      </SvgIcon>
                    </IconButton>
                  </Tooltip>
                </div>
                <Page2Map
                  id={this.state.deviceid}
                  macid={this.state.selectedmacid}
                  locid={this.state.selectedLocationId}
                  floorid={this.state.selectedFloorId}
                  element={"meridian-map2"}
                />
              </Dialog>
              <Toolbar scr numSelected={selected.length}>
                <div className="w-100">
                  <Typography
                    style={{ color: "#4b2e83" }}
                    variant="h6"
                    id="tableTitle"
                  >
                    Device ID: {this.state.deviceid}
                  </Typography>
                </div>
                <div style={{ flex: "1 1 100%" }} />
                <div style={{ display: "flex" }}>
                  <Tooltip title="See Fullscreen">
                    <IconButton
                      onClick={() => this.setState({ isFullScreen: true })}
                    >
                      <SvgIcon>
                        <path fill="none" stroke="#4b2e83" strokeWidth="2" d="M10,14 L2,22 M1,15 L1,23 L9,23 M22,2 L14,10 M15,1 L23,1 L23,9"></path>
                      </SvgIcon>
                    </IconButton>
                  </Tooltip>
                  {/* <Tooltip
                    //style={{ padding: 0 }}
                    title="See Fullscreen"
                  >
                    <IconButton
                      //aria-label="Show Map"
                      onClick={() => this.setState({ isFullScreen: true })}
                      //style={{ padding: 0 }}
                    >
                      <Open />
                    </IconButton>
                  </Tooltip> */}
                  <Tooltip title="Hide Map">
                    <IconButton
                      aria-label="Hide Map"
                      onClick={this.MapHidden.bind(this, "", "", true)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </Toolbar>

              <Page2Map
                id={this.state.deviceid}
                macid={this.state.selectedmacid}
                locid={this.state.selectedLocationId}
                floorid={this.state.selectedFloorId}
                element={"meridian-map1"}
              />
            </div>
          </Paper>
        )}

        {data.length > 0 && this.props.hideLoader ? (
          <React.Fragment>
            <Collapse in={this.state.isHiddenDetails}>
              <Paper className={classes.root1}>
                <div className={classes.tableWrapper} id="tableScroll">
                  <Table className={classes.table} aria-labelledby="tableTitle" stickyHeader>
                    <EnhancedTableHead
                      numSelected={selectedAssets.length}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={this.handleRequestSort}
                      rowCount={isPortalAdmin ? data.length : data.reduce((result, n) => n.AccessLevel >= 500 ? result + 1 : result, 0)}
                      handleSelectAllClick={this.handleSelectAllClick}
                    />

                    <TableBody>
                      {data
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map(n => {
                          const isSelected = this.isSelected(n.DeviceId);
                          return (
                            <TableRow
                              hover
                              aria-checked={isSelected}
                              tabIndex={-1}
                              key={n.DeviceId}
                              style={{
                                /*height: "45px",*/ whiteSpace: "nowrap",
                              }}
                              selected={isSelected}
                              onClick={(event) =>
                                this.handleClick(event, n.DeviceId)
                              }
                            >
                              {/*this.state.*/isCheckBoxColumnActive && (
                                <TableCell
                                  //style={{ width: "170px" }}
                                >
                                  <Checkbox
                                    style={{ padding: 0 }}
                                    // classes={{
                                    //   root: classes.rootcheckbox,
                                    //   checked: classes.checked
                                    // }}
                                    color="primary"
                                    disabled={n.AccessLevel < 500}
                                    checked={this.isSelectedRow(n.MacId)}
                                    onChange={evt => this.selectRow(evt, n.MacId)}
                                  />
                                </TableCell>
                              )}
                              {columns.map(r => {
                                return this.renderCells(n, r.id);
                              })}
                            </TableRow>
                          );
                        })}
                      {/* data.length > rowsPerPage && emptyRows > 0 && (
                        <TableRow style={{ height: 45 * emptyRows }}>
                          <TableCell colSpan={12} />
                        </TableRow>
                      ) */}
                    </TableBody>
                  </Table>
                </div>

                <TablePagination
                  rowsPerPageOptions={[10, 25, 50, 100, 125, 150]}
                  component="div"
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{
                    "aria-label": "Previous Page",
                  }}
                  nextIconButtonProps={{
                    "aria-label": "Next Page",
                  }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />

                {isCheckBoxColumnActive && selectedAssets.length > 0 && (
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={6}>
                      <h6
                        className="float-left"
                        style={{
                          paddingLeft: "3px",
                          color: "#4b2e83"
                        }}
                      >
                        {selectedAssets.length + " row(s) selected."}
                      </h6>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} className="text-right">
                      <Button
                        //hidden={!isPortalAdmin}
                        //disabled={selectedAssets.length == 0}
                        onClick={() => this.setState({ openDelete: true })}
                        variant="outlined"
                        style={{ marginRight: 20 }}
                        //className={classNames("float-right", "button1")}
                        className="button1"
                      >
                        Delete
                      </Button>
                      <Button
                        //disabled={selectedAssets.length == 0}
                        onClick={() => this.setState({ openAssign: true })}
                        variant="outlined"
                        //className={classNames("float-right", "button1")}
                        className="button1"
                      >
                        Assign
                      </Button>
                    </Grid>
                  </Grid>
                )}

                <AssignPopUpwithStyle
                  open={this.state.openAssign}
                  onClose={() => this.setState({ openAssign: false })}
                  selected={selectedAssets}
                  onAssigned={() => {
                    isCheckBoxColumnActive = false;
                    this.setState({ openAssign: false, selectedAssets: [] }, () => this.props.getAsset());
                  }}
                  showMessageBox={this.props.showMessageBox}
                  //getAsset={this.props.getAsset}
                  updateLoading={() => this.setState({ loading: !this.state.loading })}
                />

                <AlertDialog
                  open={this.state.openDelete}
                  onClickYes={this.handleDelete}
                  action={"Delete"}
                  close={() => this.setState({ openDelete: false })}
                >
                  <FormControl component="fieldset" style={{width: "400px"}}>
                    <RadioGroup aria-label="delete" name="delete1" value={this.state.deleteRadio} onChange={evt => { console.log("delete", evt.target.value); this.setState({ deleteRadio: evt.target.value }); }}>
                      <FormControlLabel style={{alignItems: "flex-start", marginRight: "0px"}} value="hard" control={<Radio style={{paddingTop: "0px"}} />} label={this.hardDeleteRadio()} />
                      <FormControlLabel style={{alignItems: "flex-start", marginRight: "0px"}} value="soft" control={<Radio style={{paddingTop: "0px"}} />} label={this.softDeleteRadio()} />
                    </RadioGroup>
                  </FormControl>
                </AlertDialog>

              </Paper>
            </Collapse>

            {/* NOT IN USE ANYMORE -> use CofigureSingleComponent */}
            <Collapse
              mountOnEnter
              unmountOnExit
              in={/*!this.state.isHiddenDetails*/ false}
            >
              <Configure
                visiblebackbtn={true}
                data={this.handleDetailsPanel}
                savebtnstate={this.state.savebtnstate}
                deletebtnstate={this.state.deletebtnstate}
                selectedAssetDetail={this.state.selectedAssetDetail}
                lastSync={this.state.syncData.sync_time}
              />
            </Collapse>
          </React.Fragment>
        ) : this.state.showNoDeviceMsg && this.props.hideLoader ? (
          <Paper
            style={{
              marginTop: "10px",
              width: "100%",
              height: "500px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: 30,
            }}
          >
            <h4>No Device Found.</h4>
          </Paper>
        ) : this.props.hideLoader == null ? (
          <Paper
            style={{
              marginTop: "10px",
              width: "100%",
              height: "500px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: 30,
            }}
          >
            <h4>An error has occurred.</h4>
          </Paper>
        ) : (
          <div
            style={{
              width: "100%",
              marginTop: 100,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress
              style={{ width: "80px", height: "80px" }}
              className={classes.progress}
            />
          </div>
        )}
      </div>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { assets, hideLoader } = state;
  return {
    assets,
    hideLoader
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAsset: id => getAssets(dispatch, id),
    showMessageBox: message => dispatch(showMessage(message))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles),
  withRouter
)(EnhancedTable);
