import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import ResetIcon from "@material-ui/icons/Refresh";
import SubmitIcon from "@material-ui/icons/CheckCircleOutline";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { storeEntityId, getEntityId, getMaxEntityAccess, atLastPageScrollToTop } from "../utils/Utils";
import {
  getEntity,
  getLocations,
  getCollections,
  showMessage
} from "../actions/index";
import { connect } from "react-redux";
import { compose } from "recompose";
import CircularProgress from "@material-ui/core/CircularProgress";
import { apiRequest } from "../utils/Utils";
import AlertDialog from "./AlertDialog";
import { Link } from "react-router-dom";
import { PFContext } from "./PrimaryFilterContext";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableRow from "@material-ui/core/TableRow";
import Moment from "react-moment";
import Linkify from 'react-linkify';
import AlertContext from "./AlertContext";


import InputLabel from "@material-ui/core/InputLabel";
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
Quill.register(Quill.import('attributors/style/align'), true);

const cellStyle = theme => ({
  root: {
    "word-break": "break-all",
  },
});
const TableCellS = withStyles(cellStyle)(TableCell);

const styles = theme => ({
  input: {
    height: 38
  },
  label: {
    marginTop: "-5px"
  },
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 2,
    flexGrow: 1
  },
  root2: {
    ...theme.mixins.gutters(),
    paddingBottom: theme.spacing.unit * 1
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  textField: { width: "100%" },
  dense: {
    marginTop: 16
  },
  menu: {
    width: 200
  },
  button: {
    margin: theme.spacing.unit,
    backgroundColor: "#4b2e83",
    width: "250px"
  },
  progress: {
    color: "#4b2e83"
  }
});

const styles2 = theme => ({
  button: {
    margin: theme.spacing.unit,
    paddingTop: 10,
    paddingBottom: 10
  },
  // input: {
  //   borderRadius: "2px",
  //   width: "100%",
  //   padding: "5px",
  //   borderRadius: "5px",
  //   outline: "none",
  //   border: "1px solid lightgrey",
  // },
  textField: { width: "100%" },
  menu: {
    width: "100%"
  },
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 2
  },
  root1: {
    width: "100%",
    marginTop: 5,
    marginBottom: 35
  },
  table: {},
  tableWrapper: {
    overflowX: "auto"
  },
  noPadding: {
    padding: 0
  }
});

const rows = [
  { id: "Type", disablePadding: false, label: "TYPE" },
  { id: "Description", disablePadding: false, label: "DESCRIPTION" },
  { id: "Created", disablePadding: false, label: "DATE ADDED" },
  { id: "IsActive", disablePadding: false, label: "STATUS" },
  { id: "IsDisMissable", disablePadding: false, label: "PERSISTENCE" },
];

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

function desc(a, b, orderBy) {
  let cmpa = "";
  let cmpb = "";
  // cmpa = a[orderBy] ? a[orderBy].toLowerCase() : "";
  // cmpb = b[orderBy] ? b[orderBy].toLowerCase() : "";
  if (orderBy == "IsActive") {
    cmpa = a[orderBy] ? "Active" : "Inactive";
    cmpb = b[orderBy] ? "Active" : "Inactive";
  }
  else if (orderBy == "IsDisMissable") {
    cmpa = a[orderBy] ? "Dismissable" : "Persistent";
    cmpb = b[orderBy] ? "Dismissable" : "Persistent";
  }
  else {
    cmpa = a[orderBy] ? a[orderBy].toLowerCase() : "";
    cmpb = b[orderBy] ? b[orderBy].toLowerCase() : "";
  }
  if (cmpb < cmpa) {
    return -1;
  }
  if (cmpb > cmpa) {
    return 1;
  }
  return 0;
}

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {
      onSelectAllClick,
      order,
      orderBy,
      //numSelected,
      rowCount
    } = this.props;

    return (
      <TableHead>
        <TableRow style={{ height: 45 }}>
          {rows.map(
            row => (
              <TableCell
                style={{ color: "#4b2e83", whiteSpace: "nowrap" }}
                key={row.id}
                align={row.numeric ? "right" : "left"}
                padding={row.disablePadding ? "none" : "default"}
                sortDirection={orderBy === row.id ? order : false}
              >
                  <Tooltip
                    title="Sort"
                    placement={row.numeric ? "bottom-end" : "bottom-start"}
                    enterDelay={300}
                  >
                    <TableSortLabel
                      style={{ color: "#4b2e83" }}
                      active={orderBy === row.id}
                      direction={order}
                      onClick={this.createSortHandler(row.id)}
                    >
                      {row.label}
                    </TableSortLabel>
                  </Tooltip>
              </TableCell>
            ),
            this
          )}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  //numSelected: PropTypes.number.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

class CreateAlert extends React.Component {
  static contextType = AlertContext;
  constructor(props) {
    super(props);
    this.page = props.params;
  }
  state = {
    stateModel: {
      EntityId: sessionStorage.getItem("entityID") ? JSON.parse(sessionStorage.getItem("entityID")) : "",
      Name: "",
      ShortName: "",
      Description: ""
    },
    serverModel: {
      EntityId: "",
      Name: "",
      ShortName: "",
      Description: ""
    },
    entityData: [],
    isSubmitting: false,
    disableFields: false,
    openAlert: false,
    showRedField: false,

    order: "asc",
    orderBy: "IsActive",
    page: 0,
    rowsPerPage: 5,
    alerts: [],
    Type: "",
    Description: "",
    IsActive: false,
    IsDisMissable: true,
    DescriptionLength: 0
  };

  componentDidMount() {
    apiRequest(`/masters/alerts`, "get")
    .then(response => {
      this.setState({ alerts: response.data });
    });
    Quill.import('formats/link').PROTOCOL_WHITELIST = ["https"];
  }

  handleChangePage = (event, page) => {
    this.setState({ page }, () => {
      atLastPageScrollToTop(this.state.alerts.length, this.state.rowsPerPage, page);
    });
  };

  handleChangeRowsPerPage = event => {
    if (event.target.value * this.state.page > this.state.alerts.length && this.state.page != 0)
      this.setState({ rowsPerPage: event.target.value, page: 0 });
    else
      this.setState({ rowsPerPage: event.target.value });
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };

  handleReset = () => {
    if (this.props.params === "Create")
      this.setState({ Type: "", Description: "", DescriptionLength: 0, IsActive: false, IsDisMissable: true, showRedField: false });
    else
      apiRequest(`/masters/alerts`, "get")
      .then(response => {
        this.setState({ alerts: response.data });
      });
  };

  handleSubmt = () => {
    const THAT = this;
    THAT.setState({
      isSubmitting: true,
      openAlert: false,
      showRedField: false
    });
    let data = this.state.stateModel;
    let url = `/masters/alert`;
    let method = "";
    let active = false;
    if (this.props.params === "Create") {
      method = "post";
      data = {
        Type: this.state.Type,
        Description: this.state.Description,
        IsActive: this.state.IsActive,
        IsDisMissable: this.state.IsDisMissable
      };
    } else {
      active = this.state.alerts.find(a => a.IsActive)
      if (active)
        url = `${url}/${active.Id}`;
      else
        url = `${url}/0`;
      method = "put";
    }

    apiRequest(url, method, data)
      .then(x => {
        if (x.status > 0) {
          if (this.props.params != "Create" ||(this.props.params === "Create" && this.state.IsActive)) {
            //set context so navbar can reload
            console.log("isActiveAlertChanged1", this.context, active);
            this.context.change(true);
          }
          if (THAT.props.params === "Create") {
            //Reload alerts
            apiRequest(`/masters/alerts`, "get")
            .then(response => {
              this.setState({ alerts: response.data }, () => THAT.props.history.push("/options/View/alert"));
            });
          }
        }
        THAT.setState({
          isSubmitting: false
        });
        if (x.status > 0 && x.message == null)
          THAT.props.showMessageBox("SUCCESS");
        else
          THAT.props.showMessageBox(x.message);
      })
      .catch(err => {
        THAT.setState({
          isSubmitting: false
        });
        THAT.props.showMessageBox("ERROR");
      });
  };

  stateChange() {
    const { serverModel, stateModel } = this.state;
    return JSON.stringify(serverModel) === JSON.stringify(stateModel)
      ? false
      : true;
  }

  componentWillReceiveProps({ params, entities, id }) {
    if (this.page !== params) {
      this.handleReset();
      this.props.storeEntity();
      this.page = params;
    }

    this.setState({
      entityData: entities
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <div
        className="justify-content-between align-items-center pb-2 mb-3"
        style={{
          paddingLeft: "12px",
          paddingRight: "12px"
        }}
      >
        <AlertDialog
          open={this.state.openAlert}
          onClickYes={this.handleSubmt}
          action={"Save"}
          close={() => {
            this.setState({ openAlert: false });
          }}
        />
        {getMaxEntityAccess() == 700 ? (
          this.props.params === "Create" ? (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <div
                  style={{
                    textAlign: "center",
                    borderBottom: "2px solid #4b2e83"
                  }}
                >
                  <div className="nav-link">
                    <h5
                      style={{
                        color: "#4b2e83",
                        marginTop: "0.5rem"
                      }}
                    >
                      Add Alert
                    </h5>{" "}
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div
                  style={{
                    textAlign: "center",
                    borderBottom: "2px solid lightgrey"
                  }}
                >
                  <Link className="nav-link" to="/options/View/alert" onClick={() => this.setState({ showRedField: false })}>
                    <h5
                      style={{
                        color: "lightgrey",
                        marginTop: "0.5rem"
                      }}
                    >
                      View Alerts
                    </h5>
                  </Link>
                </div>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <div
                  style={{
                    textAlign: "center",
                    borderBottom: "2px solid lightgrey"
                  }}
                >
                  <Link className="nav-link" to="/options/Create/alert" onClick={() => this.setState({ showRedField: false })}>
                    <h5
                      style={{
                        color: "lightgrey",
                        marginTop: "0.5rem"
                      }}
                    >
                      Add Alert
                    </h5>
                  </Link>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div
                  style={{
                    textAlign: "center",
                    borderBottom: "2px solid #4b2e83"
                  }}
                >
                  <div className="nav-link">
                    <h5
                      style={{
                        color: "#4b2e83",
                        marginTop: "0.5rem"
                      }}
                    >
                      View Alert
                    </h5>{" "}
                  </div>
                </div>
              </Grid>
            </Grid>
          )
        ) : null}
        <Paper
          className={classes.root}
          style={{
            padding: 10
          }}
        >
          <div>
            <div>
              <Grid
                container
                spacing={2}
                style={{ marginBottom: "-13px", marginTop: "-13px" }}
              >
                <Grid item xs={8}>
                </Grid>
                {!this.state.isSubmitting ? (
                  <Grid item xs={4}>
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Tooltip title="Reset">
                        <IconButton
                          style={
                            true //this.stateChange()
                              ? {
                                  color: "#4b2e83",
                                  opacity: "1",
                                  outline: "none"
                                }
                              : { color: "#343a40", opacity: "0.4" }
                          }
                          aria-label="Reset"
                          onClick={this.handleReset}
                        >
                          <ResetIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Done">
                        <IconButton
                          style={
                            true//this.stateChange() && !this.state.disableFields
                              ? {
                                  color: "#4b2e83",
                                  opacity: "1",
                                  outline: "none"
                                }
                              : { color: "#343a40", opacity: "0.4" }
                          }
                          aria-label="Done"
                          //disabled={!this.stateChange() || this.state.disableFields}
                          onClick={() => {
                            if (
                              this.props.params === "Create" && (
                              this.state.Type == "" ||
                              this.state.Description == "" ||
                              this.state.DescriptionLength > 192)
                            ) {
                              this.setState({
                                showRedField: true
                              });
                              this.props.showMessageBox(
                                /*this.state.Description.length*/this.state.DescriptionLength > 192 ? "Description can not be longer than 192 characters" :
                                "Please fill the required fields"
                              );
                              return;
                            }
                            this.setState({ openAlert: true });
                          }}
                        >
                          <SubmitIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </Grid>
                ) : (
                  <Grid item xs={4}>
                    <div
                      style={{
                        width: "100%",
                        marginTop: 5,
                        display: "flex",
                        justifyContent: "flex-end"
                      }}
                    >
                      <CircularProgress className={classes.progress} />
                    </div>
                  </Grid>
                )}
              </Grid>
            </div>
            <hr />

            {this.props.params === "Create" ? (
              <Grid container spacing={2}>
                <Grid item xxs={12} sm={6} md={4} lg={4}>
                  <TextField
                    error={this.state.showRedField && this.state.Type == ""}
                    width="100%"
                    id="outlined-select"
                    select
                    required
                    label="Select Type"
                    margin="dense"
                    className={classes.textField}
                    value={this.state.Type}
                    onChange={evt => {
                      this.setState({ Type: evt.target.value })
                    }}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      },
                      className: classes.input
                    }}
                    InputProps={{ className: classes.input }}
                    InputLabelProps={{
                      shrink: true,
                      className: classes.label
                    }}
                    variant="outlined"
                  >
                    {[{id: 'information', name: 'Information'}, {id: 'warning', name: 'Warning' }, {id: 'critical', name: 'Critical'}].map(option => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                {false && <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    error={this.state.showRedField && (this.state.Description == "" || this.state.Description.length > 192)}
                    required
                    id="outlined-name"
                    label="Description"
                    value={this.state.Description}
                    type="text"
                    style={{
                      width: "100%",
                      borderColor: "#4b2e83"
                    }}
                    //disabled={this.state.disableFields || (this.props.params === "Modify" && !this.state.stateModel.Description)}
                    onChange={evt => {
                      this.setState({ Description: evt.target.value })
                    }}
                    margin="dense"
                    multiline
                    rows="3"
                    variant="outlined"
                    placeholder="Description"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                  <Linkify>{this.state.Description}</Linkify>
                </Grid>}
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {false && <InputLabel htmlFor="my-input" classes={classes.label}>Description</InputLabel>}
                  <ReactQuill
                    placeholder="Description"
                    theme="snow"
                    bounds={`[data-bounds="html_editor"]`}
                    scrollingContainer="html"
                    modules={{
                      toolbar: [
                        ['bold', 'italic', 'underline'/*, 'strike'*/],
                        ['link'/*, 'image'*/],
                      ],
                      clipboard: {
                        matchVisual: false,
                      }
                    }}
                    formats={[
                      'bold',
                      'italic',
                      'underline',
                      'link',
                      // 'background',
                      // 'bold',
                      // 'color',
                      // 'font',
                      // 'code',
                      // 'italic',
                      // 'link',
                      // 'size',
                      // 'strike',
                      // 'script',
                      // 'underline',
                      // 'blockquote',
                      // 'header',
                      // 'indent',
                      // 'list',
                      // 'align',
                      // 'direction',
                      // 'code-block',
                      // 'formula',
                      //'image'
                    ]}
                    onChange={(content, delta, source, editor) => {
                      //let cont = "<p>" + editor.getHTML().replace("<p><br></p>","").replaceAll("<p>","").replaceAll("</p>",".") + "</p>";
                      let cont = editor.getHTML().replace("<p><br></p>","");
                      let count = (cont.match(/<p>/g) || []).length;
                      if (count == 0)
                        cont = "<p>" + cont + "</p>";
                      else if (count == 1)
                        cont = cont;
                      else
                        cont = cont.replaceAll("<p>","").replaceAll("</p>"," ");
                      this.setState({ Description: cont, DescriptionLength: editor.getLength()-1 });
                    }}
                    value={this.state.Description}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <FormControlLabel
                    //value="top"
                    labelPlacement="end"
                    label="Active"
                    control={
                    <Checkbox
                      color="primary"
                      //disabled={false}
                      checked={this.state.IsActive}
                      onChange={evt => this.setState({ IsActive: evt.target.checked })}
                    />}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <FormControlLabel
                    //value="top"
                    labelPlacement="end"
                    label="Persistent"
                    control={
                    <Checkbox
                      color="primary"
                      //disabled={false}
                      checked={!this.state.IsDisMissable}
                      onChange={evt => this.setState({ IsDisMissable: !this.state.IsDisMissable /*evt.target.checked*/ })}
                    />}
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {/*!this.state.isLoaded*/ false ? (
                    <div
                      style={{
                        width: "100%",
                        marginTop: 100,
                        display: "flex",
                        justifyContent: "center"
                      }}
                    >
                      <CircularProgress
                        style={{ width: "80px", height: "80px", color: "#4b2e83" }}
                        className={classes.progress}
                      />
                    </div>
                  ) : (
                    <div
                      className={classes.root1}
                      style={{
                        //padding: "17px",
                        //margin: "15px 12px",
                        // padding: "4px 10px 10px 10px",
                        // margin: "20px 12px"
                      }}
                    >
                      {/*this.state.filteredUsers.length > 0*/ true ? (
                        <div className={classes.tableWrapper}>
                          <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                          >
                            <EnhancedTableHead
                              //numSelected={this.state.selectedUser.length}
                              order={this.state.order}
                              orderBy={this.state.orderBy}
                              onRequestSort={this.handleRequestSort}
                              rowCount={this.state.alerts.length}
                            />

                            <TableBody>
                              {stableSort(
                                this.state.alerts,
                                getSorting(this.state.order, this.state.orderBy)
                              )
                                .slice(
                                  this.state.page * this.state.rowsPerPage,
                                  this.state.page * this.state.rowsPerPage +
                                    this.state.rowsPerPage
                                )
                                .map(n => {
                                  const isSelected = false //this.isSelected(n.Created);
                                  var mm= require('moment-timezone');
                                  var abbs = " " + mm.tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('z').toString();
                                  let created = n.Created.replace(/-/g, "/").replace("T"," " );
                                  var dateTime = new Date(`${created} UTC`).toISOString();
                                  return (
                                    <TableRow
                                      hover
                                      aria-checked={isSelected}
                                      tabIndex={-1}
                                      key={n.Created}
                                      style={{
                                        height: 45 /*whiteSpace: "nowrap"*/
                                      }}
                                      selected={isSelected}
                                      // onClick={event =>
                                      //   this.handleClick(event, n.UserId)
                                      // }
                                    >
                                      <TableCell style={{textTransform: "capitalize"}}>
                                        {n.Type}
                                      </TableCell>
                                      <TableCellS>
                                        {false &&<Linkify>{n.Description}</Linkify>}
                                        <div className="quilly" id="quillResult" dangerouslySetInnerHTML={{ __html:n.Description}} />
                                      </TableCellS>
                                      <TableCell>
                                        <Moment local format="DD MMMM YYYY H:mm">
                                          {dateTime}
                                        </Moment>
                                        {abbs}
                                      </TableCell>
                                      <TableCell>
                                        <TextField
                                          width="100%"
                                          id="outlined-select"
                                          select
                                          required
                                          //label="Select Type"
                                          margin="dense"
                                          className={classes.textField}
                                          value={n.IsActive ? "Active" : "Inactive"}
                                          onChange={evt => {
                                            const newItems = [...this.state.alerts];
                                            let index = this.state.alerts.findIndex(alert => alert.Id == n.Id);
                                            if (evt.target.value == "Active") {
                                              newItems.forEach((item, idx) => {
                                                if (idx != index)
                                                  newItems[idx].IsActive = false;
                                                else
                                                  newItems[idx].IsActive = true;
                                              });
                                              this.setState({ alerts:newItems });
                                            } else {
                                              newItems[index].IsActive = false;
                                              this.setState({ alerts:newItems });
                                            }
                                          }}
                                          SelectProps={{
                                            MenuProps: {
                                              className: classes.menu
                                            },
                                            className: classes.input
                                          }}
                                          InputProps={{ className: classes.input }}
                                          InputLabelProps={{
                                            shrink: true,
                                            className: classes.label
                                          }}
                                          variant="outlined"
                                        >
                                          {['Active', 'Inactive'].map(option => (
                                            <MenuItem key={option} value={option}>
                                              {option}
                                            </MenuItem>
                                          ))}
                                        </TextField>
                                      </TableCell>
                                      <TableCell>
                                        {n.IsDisMissable ? "Dismissable" : "Persistent"}
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>

                          <TablePagination
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            component="div"
                            count={this.state.alerts.length}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.state.page}
                            backIconButtonProps={{
                              "aria-label": "Previous Page"
                            }}
                            nextIconButtonProps={{
                              "aria-label": "Next Page"
                            }}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                          />
                        </div>
                      ) : (
                        <div
                          style={{
                            marginTop: "10px",
                            width: "100%",
                            height: "500px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: 30
                          }}
                        >
                          <h4>No Alert Found.</h4>
                        </div>
                      )}
                    </div>
                  )}
                </Grid>
              </Grid>
            )}
          </div>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { entities } = state;
  return {
    entities
  };
};

const mapDispatchToProps = dispatch => {
  return {
    storeEntity: () => getEntity(dispatch),
    getLocation: entityId => getLocations(dispatch, entityId),
    getCollection: (entityId, locId) => getCollections(dispatch, entityId, locId),
    showMessageBox: message => dispatch(showMessage(message))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(CreateAlert);