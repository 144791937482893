import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import CreateDept from "../components/CreateDepartment.jsx";
import AssignOrRemoveUsers from "../components/AssignOrRemoveUsers";
import Paper from "@material-ui/core/Paper";
import { getlocationId } from "../utils/Utils";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { PFContext } from "../components/PrimaryFilterContext";

const styles = theme => ({
  input: {
    height: 38
  },
  label: {
    marginTop: "-5px"
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  textField: { width: "100%" }
});

class Settings extends Component {
  state = {
    showUsers: false,
    entityId: "",
    locationId: "",
    collectionId: "",
    locationProps: this.props.location
  };

  showUsers = (arg, entityId = "", locationId = "", collectionId = "") => {
    this.setState({ showUsers: arg, entityId, locationId, collectionId });
  };

  componentWillReceiveProps(newProps) {
    this.setState({
      locationProps: newProps.location
    });
  }

  render() {
    const { classes } = this.props;
    const { entityId, locationId, collectionId } = this.state;
    return (
      <div
        id="content"
        style={{
          //paddingTop: "60px",
          transition: "0.25s",
          paddingLeft: "0px"
        }}
      >
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-lg-12"
              style={{
                height: "100%",
                width: "100%"
              }}
            >
              <PFContext.Consumer>
                {data =>
                  <div>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <CreateDept
                          params={this.props.match.params.function}
                          showUsers={this.showUsers}
                          id={this.props.match.params.id}
                          history={this.props.history}
                          location={this.state.locationProps}
                          entity={data.entity}
                        />
                      </Grid>
                    </Grid>

                    {this.state.showUsers ? (
                      <AssignOrRemoveUsers
                        type={"collection"}
                        entityId={data.entity}
                        locationId={locationId}
                        collectionId={collectionId}
                        params={this.props.match.params.function}
                      />
                    ) : null}
                  </div>
                }
              </PFContext.Consumer>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Settings);
