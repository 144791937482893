import * as React from 'react';
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Wizzard from './Wizzard';
import { WizzardContext } from './Wizzard';
import { apiRequest, byName, atLastPageScrollToTop, allowedDomains, isPortalAdmin } from "../utils/Utils";
import { withStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';
import Button from "@material-ui/core/Button";
import Dropzone from 'react-dropzone';
import { CSVLink } from "react-csv";
import Edit from "@material-ui/icons/Edit";
//import DownloadIcon from "@material-ui/icons/Download";
import Done from '@material-ui/icons/DoneTwoTone';
import Add from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import LinearProgress from '@material-ui/core/LinearProgress';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import PropTypes from "prop-types";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Papa from 'papaparse';
import CircularProgress from "@material-ui/core/CircularProgress";
import { showMessage } from "../actions/index";
import { compose } from "recompose";
import { connect } from "react-redux";
import './Bulk.css';

const rows = [
  { id: "Email", disablePadding: false, label: "EMAIL" },
  { id: "Full Name", disablePadding: false, label: "FULL NAME" },
  { id: "Phone Number", disablePadding: false, label: "PHONE NUMBER" },
  //{ id: "Alternate Phone Number", disablePadding: false, label: "ALERNATE PHONE NUMBER" },
  { id: "Issue", disablePadding: false, label: "ISSUE" },
  { id: "Delete", disablePadding: false, label: "" },
];

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

function desc(a, b, orderBy) {
  let cmpa = a[orderBy] ? a[orderBy].toLowerCase() : "";
  let cmpb = b[orderBy] ? b[orderBy].toLowerCase() : "";
  if (cmpb < cmpa) {
    return -1;
  }
  if (cmpb > cmpa) {
    return 1;
  }
  return 0;
}

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      columns
    } = this.props;
    return (
      <TableHead>
        <TableRow style={{ height: 45 }}>
          {rows.map(
            row => (
              <TableCell
                style={{ color: "#4b2e83", whiteSpace: "nowrap" }}
                key={row.id}
                align={row.numeric ? "right" : "left"}
                padding={row.disablePadding ? "none" : "default"}
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? "bottom-end" : "bottom-start"}
                  enterDelay={300}
                >
                  <TableSortLabel
                    style={{ color: "#4b2e83" }}
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ),
            this
          )}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  numSelected: PropTypes.number.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};



const styles = theme => ({
    root: {
      width: "100%",
      marginTop: theme.spacing.unit * 2,
      marginBottom: theme.spacing.unit * 2,
    },
    rootcheckbox: {
      color: "#4b2e83",
      "&$checked": {
        color: "#4b2e83"
      }
    },
    table: {
      minWidth: 50
    },
    tableWrapper: {
      overflowX: "auto"
    },
    button: {
      color: "#4b2e83",
      height: "40px",
      width: "100%",
      padding: "0px !important"
    },
    input: {
      height: 38
    },
    label: {
      marginTop: "-5px"
    },
    textField: { width: "100%", margin: "0px !important" },
    meta: {display: "flex", flexDirection: "column", width: 400, gap: "20px"},
    progress: {
      color: "#4b2e83"
    }
  });

class Upload extends React.Component {
    constructor(props) {
      super(props);
      this.users_err = [];
      this.state = {
        file: "",
        data: [],
        existingUsers:  [],

        order: "asc",
        orderBy: "sno",
        page: 0,
        rowsPerPage: 5,
      }
    }

  componentWillMount() {
    apiRequest("/users?is_active=true", "get")
    .then(response => {
      this.setState({ existingUsers: response.data });
    })
    .catch(err => console.log(err));
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => {
    this.setState({ page }, () => {
      atLastPageScrollToTop(this.state.data.length, this.state.rowsPerPage, page);
    });
  };

  handleChangeRowsPerPage = event => {
    if (event.target.value * this.state.page > this.state.data.length && this.state.page != 0)
      this.setState({ rowsPerPage: event.target.value, page: 0 });
    else
      this.setState({ rowsPerPage: event.target.value });
  };

  fetchData(dataType, id = "") {
    let url =
      dataType === "assetmodels"
        ? `/masters/${dataType}/${id}`
        : `/masters/${dataType}`;

    apiRequest(url, "get")
      .then(response => {
        const data = response.data.map(c => {
          return {
            id: c.ID,
            name: c.Value
          };
        });
        switch (dataType) {
          case "manufactures":
            this.setState({
              manufacturerData: data.sort(byName)
            });
            break;
          case "assetmodels":
            this.setState({
              modelData: data.sort(byName)
            });
            break;
          case "assettypes":
            this.setState({
              typeData: data.sort(byName)
            });
            break;
        }
      })
      .catch(error => console.log(error));
  }

  clearValidation() {
    this.users_err.forEach((e, id) => {
      this.users_err[id].email = "";
      this.users_err[id].name = "";
    });
    this.state.data.forEach((r, id) => {
      r.error.email = "";
      r.error.name = "";
      r.error.phone = "";
    });
  }
  validationCheck(setDisable) {
    this.clearValidation();
    let errorsno = 0;
    if (this.state.data && this.state.data.length > 0) {
      this.state.data.forEach((r, id) => {
        if (r["Full Name"].trim().length == 0) {
          errorsno++;
          this.users_err[id].name = "Name is required";
          r.error.name = "Name is required";
        }
        if (r["Email"].trim().length == 0) {
          errorsno++;
          this.users_err[id].email = "Email is required";
          r.error.email = "Email is required";
        }
        if (r["Email"].trim().length != 0 && this.state.data.find((d, index) => d.Email == r.Email && id != index)) {
          errorsno++;
          this.users_err[id].email = "Duplication detected, please remove one";
          r.error.email = "Duplication detected, please remove one";
        }
        if (r["Email"].trim().length != 0 && !isPortalAdmin && !allowedDomains.some(d => r.Email.endsWith(d))) {
          errorsno++;
          this.users_err[id].email = "Ensure that an '@uw.edu' email address is used";
          r.error.email = "Ensure that an '@uw.edu' email address is used";
        }
        if (!r["Email"].match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
          errorsno++;
          this.users_err[id].email = "Email is not valid";
          r.error.email = "Email is not valid";
        }
        //Check if exists!
        if (r["Email"].trim().length != 0 && this.state.existingUsers.find(t => t.Email == r["Email"].trim())) {
          errorsno++;
          this.users_err[id].email = "This Email already in use";
          r.error.email = "This Email already in use";
        }
        //
        if (r["Full Name"].length != 0 && r["Full Name"].length > 250) {
          errorsno++;
          this.users_err[id].name = "Full Name can not be longer than 250 characters";
          r.error.name = "Full Name can not be longer than 250 characters";
        }
        if (r["Email"].length != 0 && r["Email"].length > 50) {
          errorsno++;
          this.users_err[id].email = "Email can not be longer than 50 characters";
          r.error.email = "Email can not be longer than 50 characters";
        }
        if (r.hasOwnProperty("Phone Number") && r["Phone Number"].length != 0 && r["Phone Number"].length > 50) {
          errorsno++;
          this.users_err[id].phone = "Phone Number can not be longer than 50 characters";
          r.error.phone = "Phone Number can not be longer than 50 characters";
        }
      });
      this.setState({ numberOfIssue: errorsno });
    }
    if (errorsno == 0 && this.state.data.length > 0)
      setDisable({ disabled: false, issues: 0 });
    else
      setDisable({ disabled: true, issues: errorsno });
    return errorsno;
  }

  onSave = () => {
    for (let i = 0; i < this.state.data.length; i++) {
      let data = {
        UserId: this.state.data[i].Email,
        Password: "",
        FullName: this.state.data[i]["Full Name"],
        Email: this.state.data[i].Email,
        PhoneNumber: this.state.data[i]["Phone Number"],
        AlternatePhoneNumber: "",//this.state.data[i]["Alternate Phone Number"],
        IsOfficeUser: true
      };

      apiRequest("/users", "post", data)
        .then(x => {
          // if (x.status > 0 && x.message == null)
          //   this.props.showMessageBox("SUCCESS");
          // else
          //   this.props.showMessageBox(x.message);
          // if (x.status > 0) {
          //   this.setState({
          //     disabledFields: true
          //   });
          // }
          if (i == this.state.data.length-1) {
            if (x.status > 0 && x.message == null)
              this.props.showMessageBox("SUCCESS");
            else
              this.props.showMessageBox(x.message);
          }
        })
        .catch(err => {
          this.props.showMessageBox("ERROR");
        });
      this.props.history.push("/options/users/permissions");
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <Wizzard steps={[
        { name: 'Upload file', isSkippable: false },
        { name: 'Summary', isSkippable: false }]} onSave={this.onSave}>
        <WizzardContext.Consumer>
          {(data) => {
            if (data.activeStep == 0)
              return (
                <Box gap="small">
                  <h3>Add Microsoft O365 Users</h3>
                  Add multiple users by browsing to a file on your device.
                  <Box>
                    <Box direction="row" justify="between" style={{ maxWidth: "600px" }}>
                      <Box style={{ maxWidth: "600px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "end" }}>
                      <Tooltip id="download_template_info" placement="top-start" title="Download the template file, add your user information and upload it.">
                        <CSVLink data={[["Email", "Full Name", "Phone Number"/*,"Alternate Phone Number"*/],]} filename="users-template">
                          <IconButton>
                            <Edit color="primary" />
                          </IconButton>
                        </CSVLink>
                        </Tooltip>
                        <Box>
                          <div>Download Template</div>
                        </Box>
                      </Box>
                    </Box>
                    <Box /*border="all"*/ style={{ maxWidth: "600px" }}>
                      {!this.state.file &&
                        <Dropzone   accept= {{
                          'text/csv': ['.csv'],
                          //'application/vnd.ms-excel': ['.xls'],
                        }} maxFiles={1} onDrop={acceptedFiles => {
                          ///reader start
                          acceptedFiles.forEach((file) => {
                            const reader = new FileReader();
                            reader.onprogress = (evt) => {
                              this.setState({ progressValue: parseInt(((evt.loaded / evt.total) * 100), 10) });
                            };
                            reader.onabort = () => console.log('file reading was aborted');
                            reader.onerror = () => console.log('file reading has failed');
                            reader.onload = () => {
                              // Do whatever you want with the file contents
                              const binaryStr = reader.result;
                              console.log(binaryStr);
                            }
                            reader.readAsArrayBuffer(file);
                          })
                          ///reader end

                          this.setState({ file: acceptedFiles[0] });
                          if (/*acceptedFiles[0].type == "application/vnd.ms-excel" ||*/ acceptedFiles[0].type == "text/csv") {
                            Papa.parse(acceptedFiles[0], {
                              delimiter: "",
                              header: true,
                              skipEmptyLines: "greedy",
                              //step: (row, parser) => {
                              //    console.log("Row data:", row.data);
                              //    console.log("Row errors:", row.errors);
                              //    this.setState(prevState => ({
                              //        data: [...prevState.data, row.data]
                              //    }));
                              //},
                              complete: (results) => {
                                console.log(results);
                                if (results && results.data && results.data.length > 0 && results.data[0].hasOwnProperty("Email") && results.data[0].hasOwnProperty("Full Name")) {
                                  for (let i = 0; i <= results.data.length; i += 1) {
                                    this.users_err.push({ email: "", name: "", phone: "" });
                                  }
                                  this.setState({ data: results.data.map((x, i) => ({...x, id: i+1, error: { email: "", name: "", phone: "" } })), uploadIssue: "" }, () => {
                                    this.validationCheck(data.setStepDisable);
                                    //data.setStepDisable(false)
                                  });
                                }
                                else
                                this.setState({ data: [], file: undefined, uploadIssue: "The uploaded file does not align with the latest template. Please download the latest template, edit it and upload again." });
                              }
                            });
                          }
                        }}>
                          {({ getRootProps, getInputProps }) => (
                            <div className="upload-container">
                              <div {...getRootProps()} className="drop-zone">
                                <Box align="center" margin={{ top: "10px" }}>
                                  <Add/>
                                </Box>
                                <input {...getInputProps()} />
                                <p>Drag and drop or browse to your files</p>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                      }
                      {this.state.file &&
                        <Box style={{ maxWidth: "600px" }} /*align="center" border="all"*/ className="upload-container">
                          {this.state.progressValue == 100 ?
                            <Done /> //<DocumentCsv size="medium" />
                            :
                            <div>
                              {/* <CircularProgress style={{ width: "80px", height: "80px" }} className={classes.progress} /> */}
                            </div>
                          }
                          {this.state.file.name}
                          <Box width="80%">
                            <LinearProgress variant="determinate" value={this.state.progressValue} /*type="bar" round background="light-3" max={100} values={[{ value: this.state.progressValue, color: "brand" }]}*/ />
                            <Box style={{display: "flex", justifyContent: "end" }}>
                              Upload{this.state.progressValue == 100 ? "ed" : "ing"} {this.state.progressValue}%
                            </Box>
                          </Box>
                        </Box>
                      }
                      {this.state.uploadIssue}
                    </Box>
                  </Box>
                  <Box id="table" /*style={{"overflow-y": "overlay"}}*/>
                    {this.state.data &&
                      <Paper
                        className={classes.root1}
                        style={{
                          //padding: "17px",
                          //margin: "15px 12px",
                          // padding: "4px 10px 10px 10px",
                          // margin: "20px 12px"
                        }}
                      >
                        {this.state.data.length > 0 ? (
                          <div className={classes.tableWrapper}>
                            <Table
                              className={classes.table}
                              aria-labelledby="tableTitle"
                            >
                              <EnhancedTableHead
                                numSelected={this.state.data.length}
                                order={this.state.order}
                                orderBy={this.state.orderBy}
                                onRequestSort={this.handleRequestSort}
                                rowCount={this.state.data.length}
                                columns={Object.keys(this.state.data[0]).map(key => key)}
                              />

                              <TableBody>
                                {stableSort(
                                  this.state.data,
                                  getSorting(this.state.order, this.state.orderBy)
                                )
                                  .slice(
                                    this.state.page * this.state.rowsPerPage,
                                    this.state.page * this.state.rowsPerPage +
                                      this.state.rowsPerPage
                                  )
                                  .map((n,idx) => {
                                    const isSelected = idx%2 == 0 ? true : false; //this.isSelected(n.UserId);
                                    return (
                                      <TableRow
                                        //hover
                                        aria-checked={isSelected}
                                        tabIndex={-1}
                                        key={n.UserId}
                                        style={{
                                          height: 45 /*whiteSpace: "nowrap"*/
                                        }}
                                        selected={isSelected}
                                      >
                                        {false && Object.keys(n).map((key, index) => (
                                          <TableCell>
                                            <TextField
                                              error={this.users_err[idx].name != ""}
                                              required={index != 1 ? true : false}
                                              id="outlined-name"
                                              label={key}
                                              value={n[key]}
                                              type="text"
                                              style={{
                                                width: "100%",
                                                borderColor: "#4b2e83"
                                              }}
                                              //disabled={this.state.disableFields || (this.props.params === "Modify" && !this.state.stateModel.Description)}
                                              onChange={evt => {
                                                this.setState({ Description: evt.target.value })
                                              }}
                                              margin="dense"
                                              //multiline
                                              //rows="3"
                                              variant="outlined"
                                              placeholder="Description"
                                              InputLabelProps={{
                                                shrink: true
                                              }}
                                            />
                                          </TableCell>
                                        ))}

                                        <TableCell>
                                          <TextField
                                            //error={this.users_err[idx].email != ""}
                                            error={n.error.email}
                                            required={true}
                                            id="outlined-name"
                                            label="Email"
                                            value={n["Email"]}
                                            type="text"
                                            style={{
                                              width: "100%",
                                              borderColor: "#4b2e83"
                                            }}
                                            onChange={evt => {
                                              this.setState({
                                                data: this.state.data.map((item, i) => {
                                                  if (/*n.Email == item["Email"] && i == idx*/n.id==item.id)
                                                    return {
                                                      ...item,
                                                      Email: evt.target.value,
                                                    }
                                                  else
                                                    return item;
                                                })
                                              }, () => this.validationCheck(data.setStepDisable));
                                            }}
                                            margin="dense"
                                            //multiline
                                            //rows="3"
                                            variant="outlined"
                                            placeholder="Email"
                                            InputLabelProps={{
                                              shrink: true
                                            }}
                                          />
                                        </TableCell>
                                        <TableCell>
                                          <TextField
                                            //error={this.users_err[idx].name != ""}
                                            error={n.error.name}
                                            required={true}
                                            id="outlined-name"
                                            label="Full Name"
                                            value={n["Full Name"]}
                                            type="text"
                                            style={{
                                              width: "100%",
                                              borderColor: "#4b2e83"
                                            }}
                                            //disabled={this.state.disableFields || (this.props.params === "Modify" && !this.state.stateModel.Description)}
                                            onChange={evt => {
                                              this.setState({
                                                data: this.state.data.map((item, i) => {
                                                  if (/*n["Full Name"] == item["Full Name"] && i == idx*/n.id==item.id)
                                                    return {
                                                      ...item,
                                                      "Full Name": evt.target.value,
                                                    }
                                                  else
                                                    return item;
                                                })
                                              }, () => this.validationCheck(data.setStepDisable));
                                            }}
                                            margin="dense"
                                            //multiline
                                            //rows="3"
                                            variant="outlined"
                                            placeholder="Full Name"
                                            InputLabelProps={{
                                              shrink: true
                                            }}
                                          />
                                        </TableCell>
                                        <TableCell>
                                          <TextField
                                            required={false}
                                            error={n.error.phone}
                                            id="outlined-name"
                                            label="Phone Number"
                                            value={n["Phone Number"]}
                                            type="text"
                                            style={{
                                              width: "100%",
                                              borderColor: "#4b2e83"
                                            }}
                                            onChange={evt => {
                                              this.setState({
                                                data: this.state.data.map((item, i) => {
                                                  if (/*n["Phone Number"] == item["Phone Number"] && i == idx*/n.id==item.id)
                                                    return {
                                                      ...item,
                                                      "Phone Number": evt.target.value,
                                                    }
                                                  else
                                                    return item;
                                                })
                                              }, () => this.validationCheck(data.setStepDisable));
                                            }}
                                            margin="dense"
                                            //multiline
                                            //rows="3"
                                            variant="outlined"
                                            placeholder="Phone Number"
                                            InputLabelProps={{
                                              shrink: true
                                            }}
                                          />
                                        </TableCell>
                                        {false && <TableCell>
                                          <TextField
                                            required={false}
                                            id="outlined-name"
                                            label="Alternate Phone Number"
                                            value={n["Alternate Phone Number"]}
                                            type="text"
                                            style={{
                                              width: "100%",
                                              borderColor: "#4b2e83"
                                            }}
                                            onChange={evt => {
                                              this.setState({
                                                data: this.state.data.map((item, i) => {
                                                  if (/*n["Alternate Phone Number"] == item["Alternate Phone Number"] && i == idx*/n.id==item.id)
                                                    return {
                                                      ...item,
                                                      "Alternate Phone Number": evt.target.value,
                                                    }
                                                  else
                                                    return item;
                                                })
                                              }, () => this.validationCheck(data.setStepDisable));
                                            }}
                                            margin="dense"
                                            //multiline
                                            //rows="3"
                                            variant="outlined"
                                            placeholder="Alternate Phone Number"
                                            InputLabelProps={{
                                              shrink: true
                                            }}
                                          />
                                        </TableCell>}
                                        <TableCell>
                                          {/*this.users_err[idx].name || this.users_err[idx].email*/}
                                          {n.error.email || n.error.name || n.error.phone}
                                        </TableCell>
                                        <TableCell>
                                          <IconButton
                                            onClick={() => {
                                              this.users_err = this.users_err.filter((item, i) => i != idx);
                                              this.setState({ data: this.state.data.filter((item, i) => /*i != idx*/n.id!=item.id) }, () => this.validationCheck(data.setStepDisable));
                                            }}
                                          >
                                            <DeleteIcon />
                                          </IconButton>
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                              </TableBody>
                            </Table>

                            <TablePagination
                              rowsPerPageOptions={[5, 10, 25, 50, 100]}
                              component="div"
                              count={this.state.data/*data*/.length}
                              rowsPerPage={this.state.rowsPerPage}
                              page={this.state.page}
                              backIconButtonProps={{
                                "aria-label": "Previous Page"
                              }}
                              nextIconButtonProps={{
                                "aria-label": "Next Page"
                              }}
                              onChangePage={this.handleChangePage}
                              onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            />
                          </div>
                        ) : (
                          <div
                            style={{
                              marginTop: "10px",
                              width: "100%",
                              //height: "500px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: 30
                            }}
                          >
                            <h4>No Data Uploaded.</h4>
                          </div>
                        )}
                      </Paper>
                    }
                  </Box>
                </Box>
              );
            if (data.activeStep == 1)
              return (
                <div class="summary">
                  <h3>Review & Create</h3>
                  <div><CheckIcon/><b>{this.state.data.length > 1 ? this.state.data.length + " users added" : "1 user added"} </b></div>
                  <div>When "Finish" is selected the user(s) added will be created.</div>
                  <div>Note this may take time, so please wait for the activity completion message to confirm if the activity was successful. Once complete, the Users table will be updated so please review the Users table to ensure the user(s) were added successfully.</div>
                </div>
              );
          }}
        </WizzardContext.Consumer>
      </Wizzard>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showMessageBox: message => dispatch(showMessage(message))
  };
};

export default compose(
  connect(
    //mapStateToProps,
    null,
    mapDispatchToProps
  ),
  withStyles(styles)
)(Upload);

//export default withStyles(styles)(Upload)