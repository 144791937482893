import React, { Component } from "react";
import { Link } from "react-router-dom";
import Menu from "@material-ui/core/Menu";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";

export default function FadeMenu() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <div>
        <Button
          id="fade-button"
          aria-controls={open ? 'fade-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          variant="outlined"
        >
          Add
        </Button>
        <Menu
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          id="fade-menu"
          getContentAnchorEl={null}
          MenuListProps={{
            'aria-labelledby': 'fade-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          <div style={{display: "flex", flexDirection: "column" ,gap: "8px", paddingInline: "8px"}}>
            <Link /*className="nav-link"*/style={{ textDecoration: "none" }} to="/options/users/create"><Button variant="outlined">Add User</Button></Link>
            <Link style={{ textDecoration: "none" }}  to="/options/users/upload"><Button variant="outlined">Bulk Add</Button></Link>
          </div>
        </Menu>
      </div>
    );
  }