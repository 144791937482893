import React from "react";
import classNames from "classnames";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import { compose } from "recompose";
import { connect } from "react-redux";
import { getEntity, showMessage } from "../actions/index";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import device_image from "../images/noimage.png";
import { atLastPageScrollToTop, defaultImage, isPortalAdmin } from "../utils/Utils";
import { PFContext } from "./PrimaryFilterContext";
import PopUp from "./PopUp";
import { apiRequest, byName } from "../utils/Utils";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Popper from "@material-ui/core/Popper";
import { Link } from "react-router-dom";

const PopperMy = function (props) {
  return (<Popper {...props} /*placement='right-end'*/ />)
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  { id: "DeviceImage", disablePadding: true, label: "IMAGE" },
  {
    id: "MacId",
    disablePadding: false,
    label: "MAC ID"
  },

  { id: "Name", numeric: false, disablePadding: false, label: "NAME" },
  // {
  //   id: "DisplayName",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "DISPLAY NAME"
  // }
];

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {
      classes,
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount
    } = this.props;

    return (
      <TableHead>
        <TableRow>
          <TableCell /*padding="checkbox"*/>
            <Checkbox
              style={{ padding: 0 }}
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              color="primary"
              onChange={onSelectAllClick}
            />{" "}
            Select all
          </TableCell>
          {rows.map(
            row => (
              <TableCell
                key={row.id}
                padding={row.disablePadding ? "none" : "default"}
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? "bottom-end" : "bottom-start"}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ),
            this
          )}
        </TableRow>
      </TableHead>
    );
  }
}

const toolbarStyles = theme => ({
  rootcheckbox: {
    color: "#4b2e83",
    "&$checked": {
      color: "#4b2e83"
    }
  },
  root: {
    paddingRight: theme.spacing.unit
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: "#dfdfff"
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  spacer: {
    flex: "1 1 60%"
  },
  actions: {
    color: "#4b2e83",
    width: "100%"
  },
  title: {
    flex: "0 0 auto"
  }
});

let EnhancedTableToolbar = props => {
  const { numSelected, classes } = props;

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    >
      <div className={classes.title}>
        <h4 style={{ color: "#4b2e83" }}>Map Devices</h4>
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        <Typography color="inherit" variant="subtitle1">
          {numSelected} selected
        </Typography>
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  rootcheckbox: {
    color: "#4b2e83",
    "&$checked": {
      color: "#4b2e83"
    }
  },
  table: {
    minWidth: 50
  },
  tableWrapper: {
    overflowX: "auto"
  },
  button: {
    color: "#4b2e83",
    height: "40px",
    width: "100%",
    padding: "0px !important"
  },
  input: {
    height: 38
  },
  label: {
    marginTop: "-5px"
  },
  textField: { width: "100%", margin: "0px !important" }
});

class EnhancedTable extends React.Component {
  static contextType = PFContext;
  constructor(props) {
    super(props);
    this.state = {
      order: "desc",

      orderBy: "sno",
      selected: [],
      data: props.unmappedDevices || [],
      page: 0,
      // EntityId: sessionStorage.getItem("entityID") ? JSON.parse(sessionStorage.getItem("entityID")) : "",
      EntityId: "",
      entityData: [],
      rowsPerPage: 25,
      search:"",
      open: false,
      manufacturerData: [],
      modelData: [],
      typeData: []
    };
  }

  componentWillMount() {
    this.props.storeEntity();
    this.fetchData("manufactures");
    this.fetchData("assettypes");
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState(state => ({
        selected: this.search().map(n => n.MacId)
      }));
      return;
    }
    this.setState({ selected: [] });
  };

  componentWillReceiveProps({ entities }) {
    this.setState({
      entityData: entities
    });
  }

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page }, () => {
      atLastPageScrollToTop(this.state.data.length, this.state.rowsPerPage, page);
    });
  };

  handleChangeRowsPerPage = event => {
    if (event.target.value * this.state.page > this.search().length && this.state.page != 0)
      this.setState({ rowsPerPage: event.target.value, page: 0 });
    else
      this.setState({ rowsPerPage: event.target.value });
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };
  searchTable(e) {
    // var input, found, table, tr, td, i, j;

    // input = e.target.value.toUpperCase();
    // table = document.getElementsByTagName("table");
    // tr = table[0].getElementsByTagName("tr");
    // for (i = 1; i < tr.length; i++) {
    //   td = tr[i].getElementsByTagName("td");
    //   for (j = 0; j < td.length; j++) {
    //     if (td[j].innerHTML.toUpperCase().indexOf(input) > -1) {
    //       found = true;
    //     }
    //   }
    //   if (found) {
    //     tr[i].style.display = "";
    //     found = false;
    //   } else {
    //     tr[i].style.display = "none";
    //   }
    // }
    this.setState({ search: e.target.value });
  }
  search()
  {
    var search = this.state.search.toLowerCase();
    var data = this.props.unmappedDevices;
    if (!search) {
      return data;
    }
    else {
      var filterdata = data.filter(item => (
        item.MacId.toLowerCase().includes(search) ||
        item.Name.toLowerCase().includes(search) //||
        //item.DisplayName.toLowerCase().includes(search)
      ));
      return filterdata;
    }

  }
  isSelected = id => this.state.selected.indexOf(id) !== -1;

  fetchData(dataType, id = "") {
    let url =
      dataType === "assetmodels"
        ? `/masters/${dataType}/${id}`
        : `/masters/${dataType}`;

    apiRequest(url, "get")
      .then(response => {
        const data = response.data.map(c => {
          return {
            id: c.ID,
            name: c.Value
          };
        });
        switch (dataType) {
          case "manufactures":
            this.setState({
              manufacturerData: data.sort(byName)
            });
            break;
          case "assetmodels":
            this.setState({
              modelData: data.sort(byName)
            });
            break;
          case "assettypes":
            this.setState({
              typeData: data.sort(byName)
            });
            break;
        }
      })
      .catch(error => console.log(error));
  }

  handleAssign = () => {
    console.log("meta",
      this.state.entityObject ? this.state.entityObject.name: "",
      this.state.manufacturerObject ? this.state.manufacturerObject.name : "",
      this.state.modelObject? this.state.modelObject.name : "",
      this.state.typeObject? this.state.typeObject.name : ""
    );
    if (this.props.assignTo === "Organization")
      this.props.getMacId(
        this.state.selected,
        this.state.entityObject? this.state.entityObject.id: "",
        this.state.manufacturerObject? this.state.manufacturerObject.name : "",
        this.state.modelObject? this.state.modelObject.name : "",
        this.state.typeObject? this.state.typeObject.name : ""
      );
    else
      this.props.getMacId(this.state.selected);
    this.setState({ selected: [], open: false });
  }

  render() {
    console.log("reRender?", this.context);
    const { numSelected } = this.props;
    const { classes } = this.props;
    //const data = this.props.unmappedDevices;
    const data = this.search();
    const { order, orderBy, selected, rowsPerPage, page } = this.state;
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    return (
      <div>
        <Paper className={classes.root} style={{ padding: 10, height: "100%" }}>

        <PopUp open={this.state.open} handleClose={() => this.setState({ open: false })} title={"Assign Devices"} handleAssign={this.handleAssign} assignDisabled={!this.state.entityObject}>
          <div style={{display: "flex", flexDirection: "column", gap: "20px"}}>
          <Autocomplete
            //PopperComponent={PopperMy}
                  id="entity"
                  options={this.state.entityData.sort(byName)}
                  value={this.state.entityObject}
                  getOptionSelected={(option, value) => option.id == value.id}
                  getOptionLabel={(option) => option.name}
                  onChange={(evt, obj) => {
                      console.log("wt", obj);
                      this.setState({ entityObject: obj });
                  }}
                  renderOption={(option) => (
                    <div style={{ padding: "8px 2px" }}>{option.name}</div>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Organization"
                      variant="outlined"
                      margin="dense"
                      required
                      className={classes.textField}
                      disabled={this.state.selected.length == 0}
                    />
                  )}
                />

                <Autocomplete
                  //PopperComponent={PopperMy}
                  id="manu"
                  disabled={this.state.selected.length == 0 || this.state.manufacturerData.length == 0}
                  options={this.state.manufacturerData.sort(byName)}
                  value={this.state.manufacturerObject}
                  getOptionSelected={(option, value) => option.id == value.id}
                  getOptionLabel={(option) => option.name}
                  onChange={(evt,obj) => {
                      console.log("wt", obj);
                      if (obj == null) {
                        this.setState({ manufacturerObject: obj, modelObject: obj, modelData: [] });
                      }
                      else {
                        this.setState({ manufacturerObject: obj, modelObject: null }, () => {
                          if (obj)
                            this.fetchData("assetmodels", obj.id);
                        });
                      }
                  }}
                  renderOption={(option) => (
                    <div style={{ padding: "8px 2px" }}>{option.name}</div>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Manufacturer"
                      variant="outlined"
                      margin="dense"
                      className={classes.textField}
                    />
                  )}
                />

                <Autocomplete
                  id="model"
                  //PopperComponent={PopperMy}
                  disabled={this.state.selected.length == 0 || !this.state.manufacturerObject || this.state.modelData.length == 0}
                  options={this.state.modelData.sort(byName)}
                  value={this.state.modelObject ? this.state.modelObject : null}
                  defaultValue={null}
                  getOptionSelected={(option, value) => option.id == value.id}
                  getOptionLabel={(option) => option.name}
                  onChange={(evt, obj) => { console.log("wt", obj); this.setState({ modelObject: obj }); }}
                  renderOption={(option) => (
                    <div style={{ padding: "8px 2px" }}>{option.name}</div>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Model"
                      variant="outlined"
                      margin="dense"
                      className={classes.textField}
                    />
                  )}
                />

                <Autocomplete
                  id="type"
                  //PopperComponent={PopperMy}
                  disabled={this.state.selected.length == 0 || this.state.typeData.length == 0}
                  options={this.state.typeData.sort(byName)}
                  value={this.state.typeObject}
                  getOptionSelected={(option, value) => option.id == value.id}
                  getOptionLabel={(option) => option.name}
                  onChange={(evt, obj) => { console.log("wt", obj); this.setState({ typeObject: obj }); }}
                  renderOption={(option) => (
                    <div style={{ padding: "8px 2px" }}>{option.name}</div>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Device Type"
                      variant="outlined"
                      margin="dense"
                      className={classes.textField}
                    />
                  )}
                />




          {/* <TextField
                  disabled={this.state.selected.length == 0}
                  width="90%"
                  id="outlined-select"
                  select
                  style={{
                    marginTop: 20,
                    marginBottom: 0
                  }}
                  required
                  label="Organization"
                  placeholder="Organization"
                  margin="dense"
                  className={classes.textField}
                  value={this.state.EntityId}
                  //value={entity}
                  onChange={(v) => {
                    console.log("asd", v.target.value);
                    //handeChange(v.target.value);
                    this.setState({ EntityId: v.target.value });
                  }}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  InputProps={{ className: classes.input }}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.label
                  }}
                  variant="outlined"
                >
                  {this.state.entityData.map(option => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField> */}
                {/* <TextField
                  disabled={this.state.selected.length == 0 || this.state.manufacturerData.length == 0}
                  width="90%"
                  id="outlined-select"
                  select
                  style={{
                    marginTop: 20,
                    marginBottom: 0
                  }}
                  label="Manufacturer"
                  placeholder="Manufacturer"
                  margin="dense"
                  className={classes.textField}
                  value={this.state.manufacturer}
                  //value={entity}
                  onChange={(v) => {
                    console.log("asd", v.target.value);
                    //handeChange(v.target.value);
                    this.setState({ manufacturer: v.target.value }, () => this.fetchData("assetmodels", v.target.value.id));
                  }}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  InputProps={{ className: classes.input }}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.label
                  }}
                  variant="outlined"
                >
                  {this.state.manufacturerData.map(option => (
                    <MenuItem key={option.id} value={option}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  disabled={this.state.selected.length == 0 || !this.state.manufacturer || this.state.modelData.length == 0}
                  width="90%"
                  id="outlined-select"
                  select
                  style={{
                    marginTop: 20,
                    marginBottom: 0
                  }}
                  label="Model"
                  placeholder="Model"
                  margin="dense"
                  className={classes.textField}
                  value={this.state.model}
                  //value={entity}
                  onChange={(v) => {
                    console.log("asd", v.target.value);
                    //handeChange(v.target.value);
                    this.setState({ model: v.target.value });
                  }}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  InputProps={{ className: classes.input }}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.label
                  }}
                  variant="outlined"
                >
                  {this.state.modelData.map(option => (
                    <MenuItem key={option.id} value={option}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  disabled={this.state.selected.length == 0 || this.state.typeData.length == 0}
                  width="90%"
                  id="outlined-select"
                  select
                  style={{
                    marginTop: 20,
                    marginBottom: 0
                  }}
                  label="Device Type"
                  placeholder="Type"
                  margin="dense"
                  className={classes.textField}
                  value={this.state.type}
                  //value={entity}
                  onChange={(v) => {
                    console.log("asd", v.target.value);
                    //handeChange(v.target.value);
                    this.setState({ type: v.target.value });
                  }}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  InputProps={{ className: classes.input }}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.label
                  }}
                  variant="outlined"
                >
                  {this.state.typeData.map(option => (
                    <MenuItem key={option.id} value={option}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField> */}
          </div>
        </PopUp>

          {this.props.assignTo === "Organization" ? (
            <Grid className="border-bottom" container spacing={2} style={{marginBottom: 5}}>
              <Grid item xs={6}>
                <h4
                  style={{
                    paddingTop: 10,
                    color: "#4b2e83",
                    width: "100%"
                  }}
                >
                  {`${
                    this.props.assignTo === "Organization"
                      ? "Assign Devices to a Organization"
                      : "Assign Devices to a Department"
                  }`}
                </h4>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  style={{
                    width: "100%"
                  }}
                  margin="dense"
                  placeholder="Device Search"
                  variant="outlined"
                  onChange={e => this.setState({ search: e.target.value, page: 0 })}
                  value={this.state.search}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    className: classes.input
                  }}
                  InputLabelProps={{
                    className: classes.label
                  }}
                />
              </Grid>
              {isPortalAdmin &&
              <Grid item style={{width: "155px"}}>
                <Link className="nav-link" to="/devices/upload">
                  <Button variant="outlined">Add Tags</Button>
                </Link>
              </Grid>}
            </Grid>
          ) : (
            <div className="border-bottom">
              <h4
                style={{
                  color: "#4b2e83",
                  width: "100%"
                }}
              >
                {`${
                  this.props.assignTo === "Organization"
                    ? "Assign Devices to a Organization"
                    : "Assign Devices to a Department"
                }`}
              </h4>
            </div>
          )}

          <div className={classes.tableWrapper}>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="small"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                rowCount={data.length}
              />
              <TableBody>
                {stableSort(data, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(n => {
                    const isSelected = this.isSelected(n.MacId);
                    return (
                      <TableRow
                        hover
                        style={{ height: 42 }}
                        onClick={event => this.handleClick(event, n.MacId)}
                        role="checkbox"
                        aria-checked={isSelected}
                        tabIndex={-1}
                        key={n.MacId}
                        selected={isSelected}
                      >
                        <TableCell
                          style={{ width: "140px" }}
                          //padding="checkbox"
                        >
                          <Checkbox
                            style={{ padding: 0 }}
                            classes={{
                              root: classes.rootcheckbox,
                              checked: classes.checked
                            }}
                            color="primary"
                            checked={isSelected}
                          />
                        </TableCell>
                        <TableCell padding="none" style={{ width: 100 }}>
                          <img
                          className="image"
                            style={{
                              borderRadius: "50%"
                            }}
                            src={n.ImageUrl ? n.ImageUrl : defaultImage}
                            width="30px"
                            height="30px"
                            alt=""
                          />
                        </TableCell>
                        <TableCell style={{ width: "160px" }} align="center">
                          {n.MacId}
                        </TableCell>
                        <TableCell>{n.Name}</TableCell>
                        {/* <TableCell>{n.DisplayName}</TableCell> */}
                      </TableRow>
                    );
                  })}
                {/* emptyRows > 0 && (
                  <TableRow style={{ height: 42 * emptyRows }}>
                    <TableCell colSpan={5} />
                  </TableRow>
                ) */}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={/*rowsPerPage*page > data.length ? 0 :*/ page}
            backIconButtonProps={{
              "aria-label": "Previous Page"
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page"
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />

          {this.state.selected.length > 0 &&
            <Grid container spacing={2}>
              <Grid item xs={12} md={9} lg={9}>
                <h6
                  className="float-left"
                  style={{
                    paddingLeft: "3px",
                    color: "#4b2e83"
                  }}
                >
                  {this.state.selected.length + " row(s) selected."}
                </h6>
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <Button
                  disabled={this.state.selected.length == 0}
                  // onClick={() => {
                  //   if (this.props.assignTo === "Organization")
                  //     this.props.getMacId(
                  //       this.state.selected,
                  //       this.state.EntityId
                  //       //this.context.entity
                  //     );
                  //   else
                  //     this.props.getMacId(this.state.selected);
                  //   this.setState({ selected: [] });
                  // }}
                  onClick={() => this.setState({ open: true })}
                  variant="outlined"
                  style={{}}
                  //className={classNames(classes.button, "float-right", "button1")}
                  className={classNames("float-right", "button1")}
                >
                  {this.props.assignTo === "Organization" ? "Take Ownership" : "Assign"}
                </Button>
              </Grid>
            </Grid>
          }
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { entities } = state;
  return {
    entities
  };
};

const mapDispatchToProps = dispatch => {
  return {
    storeEntity: () => getEntity(dispatch),
    showMessageBox: message => dispatch(showMessage(message))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(EnhancedTable);
