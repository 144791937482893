import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { Provider } from "react-redux";
import rootReducer from "./reducers/index";
import { createStore } from "redux";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.css";
import App from "./App";
// THEME
import {MuiThemeProvider,createMuiTheme} from '@material-ui/core/styles';
import muiTheme from './theme/muiTheme';
//AUTH
import { MsalProvider } from "@azure/msal-react";
import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import { LogLevel } from '@azure/msal-browser';

const store = createStore(rootReducer, window.STATE_FROM_SERVER);
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#4b2e83' //your color
    },
    secondary: {
      main: '#76236c' //your color
    }
  },
  overrides:{
      // MuiTableRow: {
      //     root: { //for the body
      //         height: "40px !important"
      //     },
      //     head: { //for the head
      //         height: "40px !important"
      //     }
      // },
      // MuiTableHead: {
      //   root: {
      //     height: 20
      //   }
      // },
      // MuiOutlinedInput: {
      //   root: {
      //     borderColor: 'red',
      //     '&:hover': {
      //       borderColor: 'red'
      //     },
      //     '&.Mui-focused': {
      //       borderColor: 'red'
      //     }
      //   }
      // },
      MuiTableCell: {
        root: {
          padding: "10px 24px 10px 24px",
        },
        sizeSmall: {
          padding: "10px 24px 10px 24px"
        }
      },
      MuiTableRow: {
        root: {
          "&$selected": { backgroundColor: 'rgba(0, 0, 0, 0.04)', }, //#f09628
          "&$selected:hover": { backgroundColor: 'rgba(0, 0, 0, 0.07)', },
          "&$hover:hover": { backgroundColor: 'rgba(0, 0, 0, 0.07)', },
        },
      },
      MuiAutocomplete:{
        // option: {
        //   wrap: "none",
        //   wordWrap: "normal",
        //   flexWrap: "wrap"
        // },
        // paper: {
        //   height: "auto"
        // },
        listbox: {
          maxHeight: '75vh'
        }
      },
      MuiButton: {
        root: {
          //border: '2px solid #4b2e83 !important',
          '&:hover': {
            backgroundColor: '#4b2e83',
            color: 'white !important',
            //border: '2px solid red !important',
          },
          // "&:active": {
          //   boxShadow: "none",
          //   backgroundColor: "#0062cc",
          //   border: "4px solid green !important",
          // },
          "&:focus": {
            //boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)"
            //border: "4px solid red !important"
            //boxShadow: "none",
            outline: 'none !important'
          },
        },
        outlined: {
          border: '2px solid #4b2e83 !important',
        },
        // disabled: {
        //   backgroundcolor: '#4b2e83',
        //   color: '#7e7e7e !important',
        //   border: '2px solid #e9e9e9 !important'
        // }
        textPrimary: {
          "&:hover": {
            //set to invalid so the colour will be taken from root
            backgroundColor: "0"
          }
        }
      },
      MuiOutlinedInput:{
        adornedStart: {
          paddingLeft: "6px",
        },
        inputAdornedStart: {
          paddingLeft: "4px"
        }
      },
    //   MuiInputBase: {
    //     input: {
    //     height: "17px",
    //   }
    // }
      // MuiFormControlLabel: {
      //   root: {
      //     alignItems: "flex-start",
      //     marginRight: "0px"
      //   }
      // },
      MuiRadio: {
        root: {
          //paddingTop: "0px"
          color: '#4b2e83',
          '&$checked': {
            color: '#4b2e83 !important',
          },
        }
      },
      MuiStepper: {
        root: {
          padding: 0
        }
      }
  }
})
const config = {
  auth: {
      clientId: window.CLIENT_ID,
      authority: "https://login.microsoftonline.com/common/",
      redirectUri: window.location.origin,
      postLogoutRedirectUri: window.location.origin,
      //navigateToLoginRequestUrl: true
  },
  system: {
      loggerOptions: {
          loggerCallback: (level, message, containsPii) => {
              if (containsPii) {
                  return;
              }
              switch (level) {
                  case LogLevel.Error:
                      console.error(message);
                      return;
                  case LogLevel.Info:
                      console.info(message);
                      return;
                  case LogLevel.Verbose:
                      console.debug(message);
                      return;
                  case LogLevel.Warning:
                      console.warn(message);
                      return;
              }
          },
          piiLoggingEnabled: false
      },
  }
};
export const pca = new PublicClientApplication(config);

ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <MsalProvider instance={pca}>
        <App />
      </MsalProvider>
      </MuiThemeProvider>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.register();
