import React, { Component } from "react";
import Navbar from "./Navbar";
import Cookies from "js-cookie";
import { withRouter } from "react-router";
import { getMaxEntityAccess } from "../utils/Utils";
import { getEntity, getLocations, getCollections } from "../actions/index";
import { compose } from "recompose";
import { connect } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { clearMessage } from "../actions/index";
import {
  apiRequest,
  setAccess,
  token,
  getOption,
  byName,
  meridianLocationId
} from "../utils/Utils";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { PFContext } from "./PrimaryFilterContext";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Select from "react-select";
import Tooltip from "@material-ui/core/Tooltip";
import SvgIcon from '@material-ui/core/SvgIcon';
import Dialog from '@material-ui/core/Dialog';

const styles = (theme) => ({
  input: {
    height: 38,
  },
  label: {
    //marginTop: "-5px",
  },
  textField: {
    width: "100%",
    background: "white",
  },
});

class PrimaryFilter extends Component {
  handeChange = (name) => {
    sessionStorage.setItem("entityID", name ? JSON.stringify(name) : "");
    if (name === "") {
      //clear other primary filters if location is empty
      this.handleClear();
      console.log("Primary filter cleared!");
    }
    this.setState({ entity: name });
  };

  setCollectionEnable = (bool) => this.setState({ isCollectionEnabled: bool });

  state = {
    entities: [],
    locationData: [],
    departmentData: [],
    collectionData: [],
    floorData: [],
    meridianBuildings: [],
    entity: sessionStorage.getItem("entityID")
      ? JSON.parse(sessionStorage.getItem("entityID"))
      : "",
    loc: sessionStorage.getItem("locationID")
      ? JSON.parse(sessionStorage.getItem("locationID"))
      : "",
    building: sessionStorage.getItem("buildingID")
      ? sessionStorage.getItem("buildingID")
      : "",
    dept: sessionStorage.getItem("departmentID")
      ? JSON.parse(sessionStorage.getItem("departmentID"))
      : "",
    coll: sessionStorage.getItem("collectionID")
      ? JSON.parse(sessionStorage.getItem("collectionID"))
      : "",
    floor: sessionStorage.getItem("floorID")
      ? sessionStorage.getItem("floorID")
      : "",
    path: this.props.location.pathname + this.props.location.search,
    handeChange: this.handeChange,
    tags: [],
    isCollectionEnabled: true,
    setCollectionEnable: this.setCollectionEnable,
    isFullScreen: false
  };

  componentDidMount() {
    this.getMapsFromMeridian(meridianLocationId);
    this.props.getEntities();
    if (sessionStorage.getItem("entityID")) {
      //Get Locations of the selected entity
      this.getLoc(JSON.parse(sessionStorage.getItem("entityID")));
      //Get Collections of the selected entity
      //Get Collection
      this.getColl(JSON.parse(sessionStorage.getItem("entityID")));
      //this.getMapsFromMeridian(meridianLocationId); - remove as we only need floors when bulding is selected
      //If location is selected then apply it as a param
      if (sessionStorage.getItem("locationID")) {
        //this.getMapsFromMeridian(meridianLocationId); - remove as it's possible to not select building, but an asset straight away
        //Get Department
        this.getDept(JSON.parse(sessionStorage.getItem("entityID")), JSON.parse(sessionStorage.getItem("locationID")));
        //if (sessionStorage.getItem("collectionID"))
          this.getData(JSON.parse(sessionStorage.getItem("entityID")), JSON.parse(sessionStorage.getItem("locationID")), sessionStorage.getItem("collectionID") ? JSON.parse(sessionStorage.getItem("collectionID")) : "", false, sessionStorage.getItem("buildingID"));
        //else
        //  this.getData(JSON.parse(sessionStorage.getItem("entityID")), JSON.parse(sessionStorage.getItem("locationID")));
      } else if (sessionStorage.getItem("buildingID")) {
        this.getData(JSON.parse(sessionStorage.getItem("entityID")), sessionStorage.getItem("locationID") ? JSON.parse(sessionStorage.getItem("locationID")) : "", sessionStorage.getItem("collectionID") ? JSON.parse(sessionStorage.getItem("collectionID")) : "", false, sessionStorage.getItem("buildingID"));
      }
    }
  }

  getMapsFromMeridian(location) {
    var url = `https://edit.meridianapps.com/api/locations/${location}/maps`;
    return fetch(url, getOption())
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
        }
      })
      .then((data) => {
        if (data) {
          //let floors = [];
          let meridianBuildings = {};
          //console.log("floor:", data);
          //console.log("floor1:", data.results.find((i) => i.id == "5634472569470976"));
          let floors = data.results.map((item) => {
            meridianBuildings[item.group] = item.group_name;
            return {
              //name: item.level_label,
              name: item.name,
              floor_id: item.id,
              location_id: location,
              building_id: item.group,
              building_name: item.group_name
            };
          });
          //if (floors.length > 0) {
          //  meridianBuildings = floors.filter((value, index, self) => self.map(x => x.building_id).indexOf(value.building_id) == index)
          //  .map(b => ({
          //    id: b.building_id,
          //    name: b.building_name
          //  }));
            floors = floors.sort(function(a, b) {
              return a.name.localeCompare(b.name, undefined, {
                numeric: true,
                sensitivity: 'base'
              });
            });
          //}
          const entries = Object.entries(meridianBuildings);
          meridianBuildings = [];
          for (const [key, value] of entries) {
            meridianBuildings.push({ id: key, name: value });
          }
          meridianBuildings.sort(byName);
          this.setState({ floorData: floors, meridianBuildings });
        }
        //return data;
      })
      .catch((err) => {
        console.log("error:", err);
      });
  }

  getData(e, b, c, f = false, cb) {
    var url = `/assets/search?entity_id=${e}`;
    if (b && c) {
      //both
      url += `&location_id=${b}&collection_id=${c}`;
      if (false)
        url += `&meridian_map_id=${f}`;
    } else if (b) {
      //only loc
      url += `&location_id=${b}`;
      if (false)
        url += `&meridian_map_id=${f}`;
    } else if (c) {
      //only coll
      url += `&collection_id=${c}`;
    }
    //CURRENT BUILDING
    if (cb)
      url += `&meridian_building_id=${cb}`;

    apiRequest(url, "get").then((response) => {
      let datas = response.data.map((c) => {
        return {
          id: c.MacId,
          name: c.FloorNumber,
          location_id: c.MeridianLocationId,
          floor_id: c.MeridianMapId,
          device_name: c.Name,
          building_id: c.MeridianBuildingId,
          filter: true,
        };
      });
      //Filter for the selected floor id
      this.setState({ tags: datas });
    });
  }

  isFloorMatching(floorid) {
    if (this.state.tags.find((i) => i.floor_id == floorid)) {
      return true;
    } else {
      return false;
    }
  }

  handleChange = (name) => (event, value) => {
    console.log("helokaa", event, value);
    var param = "";
    if (value !== null) {
      switch (name) {
        case "entity":
          param = value.id;
          break;
        case "loc":
          param = value.id;
          break;
        case "building":
          param = value.id;
          break;
        case "dept":
          param = value.id;
          break;
        case "coll":
          param = value.id;
          break;
        case "floor":
          param = value.floor_id;
          break;
        default:
          break;
      }
    }
    this.setState(
      {
        //[name]: event.target.value,
        //[name]: param,
        path: this.props.location.pathname + this.props.location.search,
      },
      () => {
        if (name === "entity") {
          sessionStorage.setItem("entityID", param ? JSON.stringify(param) : "");
          sessionStorage.setItem("locationID", "");
          sessionStorage.setItem("departmentID", "");
          sessionStorage.setItem("collectionID", "");
          sessionStorage.setItem("floorID", "");
          sessionStorage.setItem("buildingID", "");
          this.setState({
            entity: param,
            loc: "",
            dept: "",
            coll: "",
            floor: "",
            building: ""
          });
          if (param) {
            this.props.getLocation(param);
            this.getLoc(param);
            //this.props.getCollection(param,0);
            this.getColl(param);
            //this.getMapsFromMeridian(meridianLocationId); - Replaced after building is selected
          }
        } else if (name === "loc") {
          sessionStorage.setItem("locationID", param ? JSON.stringify(param) : "");
          sessionStorage.setItem("departmentID", "");
          if (this.state.building) {
            this.setState({ loc: param, dept: "" });
          } else {
            sessionStorage.setItem("floorID", "");
            this.setState({ loc: param, dept: "", floor: "" });
          }
          //this.props.getCollection(this.state.entity, event.target.value);
          //this.getMapsFromMeridian(meridianLocationId);
          //this.getData(this.state.entity, param, "");
          this.getData(this.state.entity, param, this.state.coll, false, this.state.building);
          this.getDept(this.state.entity, param);
        } else if (name === "building") {
          sessionStorage.setItem("buildingID", param);
          let floorId = "";
          if (this.state.loc) {
            let lObj = this.state.locationData.find(e => e.id == this.state.loc);
            if (lObj) {
              if (!this.state.building && value) {
                if (value.name == lObj.name)
                  floorId = this.state.floor;
              } else if (this.state.building && !value) {
                let bObj = this.state.meridianBuildings.find(e => e.id == this.state.building);
                if (bObj && bObj.name == lObj.name)
                  floorId = this.state.floor;
              }
            }
          }
          sessionStorage.setItem("floorID", floorId);
          this.setState({ building: param, floor: floorId });
          //this.getData(this.state.entity, "", "", false, param);
          this.getData(this.state.entity, this.state.loc, this.state.coll, false, param);
        } else if (name === "dept") {
          sessionStorage.setItem("departmentID", param ? JSON.stringify(param) : "");
          this.setState({ dept: param });
        } else if (name === "coll") {
          sessionStorage.setItem("collectionID", param ? JSON.stringify(param) : "");
          //console.log("misma", sessionStorage.getItem("collectionID"));
          this.setState({ coll: param });
          //this.getData(this.state.entity, this.state.loc ? this.state.loc : "", param);
          this.getData(this.state.entity, this.state.loc, param, false, this.state.building);
        } else if (name == "floor") {
          sessionStorage.setItem("floorID", param);
          //if (this.state.building) {
            this.setState({ floor: param });
          //} else {
          //  sessionStorage.setItem("buildingID", value.building_id);
          //  this.setState({ building: value.building_id, floor: param });
          //}
        }
      }
    );
    //this.reload();
  };

  getLoc(entityId) {
    apiRequest(`/entities/${entityId}/buildings`, "get")
      .then((response) => {
        if (response.status > 0) {
          const locationData = response.data.map((c) => {
            return {
              id: c.LocationId,
              name: c.LocationName,
              entityId: c.EntityId,
            };
          }).sort(byName);
          this.setState({ locationData: locationData });
        } else {
          this.setState({ locationData: [] });
        }
      })
      .catch((error) => console.log(error));
  }

  getDept(entityId, locationId) {
    const url =
      locationId === ""
        ? `/entities/${entityId}/collections`
        : `/entities/${entityId}/locations/${locationId}/collections`;
    apiRequest(url, "get")
      .then((response) => {
        if (response.status > 0) {
          const departmentData = response.data.map((c) => {
            return {
              id: c.CollectionId,
              name: c.CollectionName,
              location: c.LocationName,
              locationId: c.LocationId,
              entityId: c.EntityId,
              collectionType: c.CollectionType,
            };
          }).sort(byName);
          this.setState({ departmentData: departmentData });
        } else {
          this.setState({ departmentData: [] });
        }
      })
      .catch((error) => console.log(error));
  }

  getColl(entityId, locationId = "") {
    const url =
      locationId === ""
        ? `/entities/${entityId}/collections`
        : `/entities/${entityId}/locations/${locationId}/collections`;
    apiRequest(url, "get")
      .then((response) => {
        if (response.status > 0) {
          const collectionData = response.data.map((c) => {
            return {
              id: c.CollectionId,
              name: c.CollectionName,
              location: c.LocationName,
              locationId: c.LocationId,
              entityId: c.EntityId,
              collectionType: c.CollectionType,
            };
          }).sort(byName);
          this.setState({ collectionData: collectionData });
        } else {
          this.setState({ collectionData: [] });
        }
      })
      .catch((error) => console.log(error));
  }

  visibility() {
    let visible = {
      entity: false,
      loc: false,
      building: false,
      dept: false,
      coll: false,
      floor: false,
      expand: false
    };
    let current = this.props.location.pathname + this.props.location.search;
    if (current.includes("reload")) {
      current = this.state.path;
    }
    if (current.includes("view")) {
      visible.entity = true;
      visible.loc = true;
      visible.dept = false;
      visible.coll = true;
      visible.floor = true;
      if (current.includes("viewtable"))
        visible.building = true;
      if (current.includes("viewmap")) {
        visible.building = true;
        visible.expand = true;
      }
    } else if (current.includes("manage/dept")) {
      visible.entity = true;
      visible.loc = true;
      visible.dept = true;
      visible.coll = false;
      visible.floor = false;
    } else if (current.includes("manage/col")) {
      visible.entity = true;
      visible.loc = false;
      visible.dept = false;
      visible.coll = true;
      visible.floor = false;
    }
    return visible;
  }

  reload() {
    const current = this.props.location.pathname + this.props.location.search;
    this.props.history.replace(`/reload`);
    setTimeout(() => {
      this.props.history.replace(current);
    });
  }

  handleClear() {
    sessionStorage.setItem("entityID", "");
    sessionStorage.setItem("locationID", "");
    sessionStorage.setItem("departmentID", "");
    sessionStorage.setItem("collectionID", "");
    sessionStorage.setItem("floorID", "");
    sessionStorage.setItem("buildingID", "");
    this.setState({
      entity: "",
      loc: "",
      dept: "",
      coll: "",
      floor: "",
      building: ""
    });
    //this.reload();
  }

  render() {
    const { pathname, search } = this.props.location;
    const { classes } = this.props;
    //console.log("benci", this.state.tags, this.state, this.props, sessionStorage.getItem("entityID"));
    const current = this.props.location.pathname + this.props.location.search;
    const v = this.visibility();
    // if (this.props.entities.length == 0) {
    //   return "Loading";
    // }
    var eObj = this.props.entities.find(e => e.id == this.state.entity);
    eObj = eObj == undefined ? null : eObj;
    var lObj = null;
    if (sessionStorage.getItem("locationID")) {
      const sessionLoc = JSON.parse(sessionStorage.getItem("locationID"));
      lObj = this.state.locationData.find(e => e.id == sessionLoc);
      lObj = lObj == undefined ? null : lObj;
    }
    var bObj = this.state.meridianBuildings.find(e => e.id == this.state.building); //to be checked later
    bObj = bObj == undefined ? null : bObj;
    var dObj = null;
    if (sessionStorage.getItem("departmentID")) {
      const sessionDept = JSON.parse(sessionStorage.getItem("departmentID"));
      dObj = this.state.departmentData.find(e => e.id == sessionDept);
      dObj = dObj == undefined ? null : dObj;
    }
    var cObj = null;
    if (sessionStorage.getItem("collectionID")) {
      const sessionColl = JSON.parse(sessionStorage.getItem("collectionID"));
      cObj = this.state.collectionData.find(e => e.id == sessionColl);
      cObj = cObj == undefined ? null : cObj;
    }
    var fObj = null;
    if (sessionStorage.getItem("floorID")) {
      fObj = this.state.floorData.find(e => e.floor_id == sessionStorage.getItem("floorID"));
      fObj = fObj == undefined ? null : fObj;
    }
    //console.log("halo", fObj);
    return (
      <div
        /*style={{ paddingLeft: this.state.padding, paddingTop: "64px" }}*/ style={{
          width: "100%",
        }}
      >
        {!this.state.isFullScreen && (
          <div
            id="test"
            style={{
              background: "#f0f0f0",
              paddingLeft: "14px",
              paddingRight: "11px",
              marginBottom: "15px",
              display: this.props.hasOwnProperty("notVisible") && this.props.notVisible? "none" : ""
            }}
          >
            <Grid container spacing={1} style={{ width: "100%" }}>
              <Grid item xs={2} /*style={{ width: "21%" }}*/>
                <Autocomplete
                  id="entity"
                  options={this.props.entities.sort(byName)}
                  value={eObj}
                  getOptionSelected={(option, value) => option.id == value.id}
                  getOptionLabel={(option) => option.name}
                  onChange={this.handleChange("entity")}
                  renderOption={(option) => (
                    <div style={{ padding: "8px 2px" }}>{option.name}</div>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Organization"
                      variant="outlined"
                      margin="dense"
                      className={classes.textField}
                    />
                  )}
                />
                {/* <TextField
                  width="100%"
                  id="outlined-select"
                  select
                  required
                  margin="dense"
                  label="Organization"
                  className={classes.textField}
                  value={this.state.entity}
                  onChange={this.handleChange("entity")}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  InputProps={{ className: classes.input }}
                  InputLabelProps={{
                    className: classes.label,
                  }}
                  variant="outlined"
                >
                  {this.props.entities.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField> */}
              </Grid>
              {v.coll && (
                <Grid item xs={2}>
                  <Tooltip
                    title={
                      eObj
                        ? !this.state.isCollectionEnabled && current.includes("view")
                          ? "Please ensure Department is deselected before selecting a Collection"
                          : ""
                        : "Please ensure Organization is selected before selecting a Collection"
                    }
                    placement="bottom"
                  >
                    <Autocomplete
                      disabled={!eObj || (!this.state.isCollectionEnabled && current.includes("view"))}
                      id="collection"
                      value={cObj}
                      options={this.state.collectionData/*.sort(byName)*/}
                      getOptionSelected={(option, value) => option.id == value.id}
                      getOptionLabel={(option) => option.name}
                      onChange={this.handleChange("coll")}
                      renderOption={(option) => (
                        <div style={{ padding: "8px 2px" }}>{option.name}</div>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Collection"
                          variant="outlined"
                          margin="dense"
                          className={classes.textField}
                        />
                      )}
                    />
                    {/* <TextField
                      width="100%"
                      id="outlined-select"
                      select
                      required
                      margin="dense"
                      label="Collection"
                      className={classes.textField}
                      value={this.state.coll}
                      onChange={this.handleChange("coll")}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu,
                        },
                      }}
                      InputProps={{ className: classes.input }}
                      InputLabelProps={{
                        className: classes.label,
                      }}
                      variant="outlined"
                    >
                      {this.state.collectionData.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField> */}
                  </Tooltip>
                </Grid>
              )}
              {v.loc && (
                <Grid item xs={2}>
                  <Tooltip
                    title={
                      eObj
                        ? ""
                        : "Please ensure Organization is selected before selecting a Campus"
                    }
                    placement="bottom"
                  >
                    <Autocomplete
                      disabled={eObj ? false : true}
                      id="building"
                      value={lObj}
                      options={this.state.locationData/*.sort(byName)*/}
                      //getOptionDisabled={option => (bObj && this.props.location.pathname.includes("viewmap") && bObj.name != option.name)}
                      getOptionSelected={(option, value) => option.id == value.id}
                      getOptionLabel={(option) => option.name}
                      onChange={this.handleChange("loc")}
                      renderOption={(option) => (
                        <div style={{ padding: "8px 2px" }}>{option.name}</div>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Assigned Campus"
                          variant="outlined"
                          margin="dense"
                          className={classes.textField}
                        />
                      )}
                    />
                    {/* <TextField
                      width="100%"
                      id="outlined-select"
                      select
                      required
                      margin="dense"
                      label="Building"
                      className={classes.textField}
                      value={this.state.loc}
                      onChange={this.handleChange("loc")}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu,
                        },
                      }}
                      InputProps={{ className: classes.input }}
                      InputLabelProps={{
                        className: classes.label,
                      }}
                      variant="outlined"
                    >
                      {this.state.locationData.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField> */}
                  </Tooltip>
                </Grid>
              )}
              {v.building && (
                <Grid item xs={2}>
                  <Tooltip
                    title={
                      eObj
                        ? ""
                        : "Please ensure Organization is selected before selecting a Campus"
                    }
                    placement="bottom"
                  >
                    <Autocomplete
                      disabled={eObj ? false : true}
                      id="current_building"
                      value={bObj}
                      options={this.state.meridianBuildings/*.sort(byName)*/}
                      getOptionSelected={(option, value) => option.id == value.id}
                      getOptionLabel={(option) => option.name}
                      onChange={this.handleChange("building")}
                      renderOption={(option) => (
                        <div style={{ padding: "8px 2px" }}>{option.name}</div>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Current Campus"
                          variant="outlined"
                          margin="dense"
                          className={classes.textField}
                        />
                      )}
                    />
                  </Tooltip>
                </Grid>
              )}
              {v.dept && (
                <Grid item xs={2}>
                  <Tooltip
                    title={
                      lObj
                        ? ""
                        : "Please ensure Campus is selected before selecting a Department"
                    }
                    placement="bottom"
                  >
                    <Autocomplete
                      disabled={lObj ? false : true}
                      id="department"
                      value={dObj}
                      options={this.state.departmentData/*.sort(byName)*/}
                      getOptionSelected={(option, value) => option.id == value.id}
                      getOptionLabel={(option) => option.name}
                      onChange={this.handleChange("dept")}
                      renderOption={(option) => (
                        <div style={{ padding: "8px 2px" }}>{option.name}</div>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Department"
                          variant="outlined"
                          margin="dense"
                          className={classes.textField}
                        />
                      )}
                    />
                    {/* <TextField
                      width="100%"
                      id="outlined-select"
                      select
                      required
                      margin="dense"
                      label="Department"
                      className={classes.textField}
                      value={this.state.dept}
                      onChange={this.handleChange("dept")}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu,
                        },
                      }}
                      InputProps={{ className: classes.input }}
                      InputLabelProps={{
                        className: classes.label,
                      }}
                      variant="outlined"
                    >
                      {this.state.departmentData.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField> */}
                  </Tooltip>
                </Grid>
              )}
              {/* v.coll && (
                <Grid item xs={2}>
                  <Autocomplete
                    id="collection"
                    value={cObj}
                    options={this.state.collectionData}
                    getOptionSelected={((option, value) => option.id == value.id)}
                    getOptionLabel={(option) => option.name}
                    onChange={this.handleChange("coll")}
                    renderOption={(option) => (
                      <div style={{ padding: "8px 2px" }}>
                        {option.name}
                      </div>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Collection"
                        variant="outlined"
                        margin="dense"
                        className={classes.textField}
                      />
                    )}
                  />
                  <TextField
                    width="100%"
                    id="outlined-select"
                    select
                    required
                    margin="dense"
                    label="Collection"
                    className={classes.textField}
                    value={this.state.coll}
                    onChange={this.handleChange("coll")}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    InputProps={{ className: classes.input }}
                    InputLabelProps={{
                      className: classes.label,
                    }}
                    variant="outlined"
                  >
                    {this.state.collectionData.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              ) */}
              {v.floor && (
                <Grid item xs={2}>
                  <Tooltip
                    title={
                      //(this.props.location.pathname + this.props.location.search).includes("viewtable")
                      //  ?
                          lObj || bObj
                            ? ""
                            : "Please ensure Assigned Campus or Current Campus is selected before selecting a Floor"
                      //  :
                      //    lObj
                      //      ? ""
                      //      : "Please ensure Campus is selected before selecting a Floor"
                    }
                    placement="bottom"
                  >
                    <Autocomplete
                      disabled={/*(lObj && bObj && lObj.name != bObj.name) || */!(lObj || bObj)}
                      id="floor"
                      value={fObj}
                      options={
                        bObj
                          ? this.state.floorData.filter((f) => f.building_name == bObj.name)
                          :
                            lObj
                              ? this.state.floorData.filter((f) => f.building_name == lObj.name)
                              : []
                      }
                      getOptionSelected={(option, value) => option.floor_id == value.floor_id}
                      getOptionLabel={(option) => option.name}
                      renderOption={(option) => (
                        <div style={{ padding: "8px 2px" }}>
                          {this.isFloorMatching(option.floor_id) ? (
                            <b>{option.name}</b>
                          ) : (
                            <div>{option.name}</div>
                          )}
                        </div>
                      )}
                      onChange={this.handleChange("floor")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Floor"
                          variant="outlined"
                          margin="dense"
                          className={classes.textField}
                        />
                      )}
                    />
                  </Tooltip>
                </Grid>
              )}
              {/* v.floor && (
                <Grid item xs={2}>
                  <div style={{ marginTop: 8 }}>
                    <Select
                      value={this.state.test}
                      onChange={(opt) => {
                        this.setState({ test: opt });
                      }}
                      placeholder="Floor*"
                      options={this.state.floorData}
                      getOptionLabel={(option) => option.name}
                      isClearable={true}
                      isSearchable={true}
                      formatOptionLabel={(option) => (
                        <div style={{ padding: "8px 2px" }}>
                          {this.isFloorMatching(option.floor_id) ? (
                            <b>{option.name}</b>
                          ) : (
                            <div>{option.name}</div>
                          )}
                        </div>
                      )}
                    />
                  </div>
                </Grid>
              ) */}
                {/* <TextField
                  disabled={this.state.floorData.length == 0 || !this.state.entity ||
                    (!this.state.entity || !this.state.loc) &&
                    (!this.state.entity || !this.state.coll)
                  }
                  width="100%"
                  id="outlined-select"
                  select
                  required
                  margin="dense"
                  label="Floor"
                  className={classes.textField}
                  value={this.state.floor}
                  onChange={this.handleChange("floor")}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  InputProps={{ className: classes.input }}
                  InputLabelProps={{
                    className: classes.label,
                  }}
                  variant="outlined"
                >
                  {this.state.floorData.map((option) => (
                    <MenuItem key={option.floord_id} value={option.floor_id}>
                      {this.isFloorMatching(option.floor_id) ? (
                        <b>{option.name}</b>
                      ) : (
                        <div>{option.name}</div>
                      )}
                    </MenuItem>
                  ))}
                </TextField> */}

              <Grid
                xs
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  //padding: "4px"
                }}
              >
                <Button
                  onClick={() => this.handleClear()}
                  variant="outlined"
                  style={{
                    color: "#4b2e83",
                    //opacity: "1",
                    //outline: "none"
                  }}
                >
                  Clear
                </Button>
              </Grid>
              <Grid
                item
                /*xs={6} md={4} lg={8}*/ style={{
                  "textAlign": "end",
                  alignSelf: "center",
                  width: "24px",
                  marginLeft: "8px",
                }}
              >
                {v.expand && (
                  <Tooltip
                    title={
                      /*this.state.isFullScreen
                        ? "Close Fullscreen"
                        : */"See Fullscreen"
                    }
                  >
                    <IconButton
                      style={{ padding: 0 }}
                      onClick={() => {
                        /*if (this.state.isFullScreen) {
                          this.setState({ isFullScreen: false });
                        } else {*/
                          this.setState({ isFullScreen: true });
                        /*}*/
                      }}
                    >
                      <SvgIcon>
                        {/*this.state.isFullScreen ? (
                          <path
                            fill="none"
                            stroke="#4b2e83"
                            strokeWidth="2"
                            d="M2,14 L10,14 L10,22 M1,23 L10,14 M23,1 L14,10 M22,10 L14,10 L14,2"
                          ></path>
                        ) : */(
                          <path
                            fill="none"
                            stroke="#4b2e83"
                            strokeWidth="2"
                            d="M10,14 L2,22 M1,15 L1,23 L9,23 M22,2 L14,10 M15,1 L23,1 L23,9"
                          ></path>
                        )}
                      </SvgIcon>
                    </IconButton>
                  </Tooltip>
                )}
              </Grid>
            </Grid>
          </div>
        )}
        {!this.state.isFullScreen && (
          <PFContext.Provider value={this.state}>
            {this.props.children}
          </PFContext.Provider>
        )}
        <Dialog fullScreen open={this.state.isFullScreen}>
          <div
            id="test"
            style={{
              background: "#f0f0f0",
              paddingLeft: "14px",
              paddingRight: "11px",
              marginBottom: "15px",
            }}
          >
            <Grid container spacing={1} style={{ width: "100%" }}>
              <Grid item xs={2} /*style={{ width: "21%" }}*/>
                <Autocomplete
                  id="entity"
                  options={this.props.entities.sort(byName)}
                  value={eObj}
                  getOptionSelected={(option, value) => option.id == value.id}
                  getOptionLabel={(option) => option.name}
                  onChange={this.handleChange("entity")}
                  renderOption={(option) => (
                    <div style={{ padding: "8px 2px" }}>{option.name}</div>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Organization"
                      variant="outlined"
                      margin="dense"
                      className={classes.textField}
                    />
                  )}
                />
              </Grid>
              {v.coll && (
                <Grid item xs={2}>
                  <Tooltip
                    title={
                      eObj
                        ? !this.state.isCollectionEnabled && current.includes("view")
                          ? "Please ensure Department is deselected before selecting a Collection"
                          : ""
                        : "Please ensure Organization is selected before selecting a Collection"
                    }
                    placement="bottom"
                  >
                    <Autocomplete
                      disabled={!eObj || (!this.state.isCollectionEnabled && current.includes("view"))}
                      id="collection"
                      value={cObj}
                      options={this.state.collectionData/*.sort(byName)*/}
                      getOptionSelected={(option, value) => option.id == value.id}
                      getOptionLabel={(option) => option.name}
                      onChange={this.handleChange("coll")}
                      renderOption={(option) => (
                        <div style={{ padding: "8px 2px" }}>{option.name}</div>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Collection"
                          variant="outlined"
                          margin="dense"
                          className={classes.textField}
                        />
                      )}
                    />
                  </Tooltip>
                </Grid>
              )}
              {v.loc && (
                <Grid item xs={2}>
                  <Tooltip
                    title={
                      eObj
                        ? ""
                        : "Please ensure Organization is selected before selecting a Campus"
                    }
                    placement="bottom"
                  >
                    <Autocomplete
                      disabled={eObj ? false : true}
                      id="building"
                      value={lObj}
                      options={this.state.locationData/*.sort(byName)*/}
                      //getOptionDisabled={option => (bObj && this.props.location.pathname.includes("viewmap") && bObj.name != option.name)}
                      getOptionSelected={(option, value) => option.id == value.id}
                      getOptionLabel={(option) => option.name}
                      onChange={this.handleChange("loc")}
                      renderOption={(option) => (
                        <div style={{ padding: "8px 2px" }}>{option.name}</div>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Assigned Campus"
                          variant="outlined"
                          margin="dense"
                          className={classes.textField}
                        />
                      )}
                    />
                  </Tooltip>
                </Grid>
              )}
              {v.building && (
                <Grid item xs={2}>
                  <Tooltip
                    title={
                      eObj
                        ? ""
                        : "Please ensure Organization is selected before selecting a Campus"
                    }
                    placement="bottom"
                  >
                    <Autocomplete
                      disabled={eObj ? false : true}
                      id="current_building"
                      value={bObj}
                      options={this.state.meridianBuildings/*.sort(byName)*/}
                      getOptionSelected={(option, value) => option.id == value.id}
                      getOptionLabel={(option) => option.name}
                      onChange={this.handleChange("building")}
                      renderOption={(option) => (
                        <div style={{ padding: "8px 2px" }}>{option.name}</div>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Current Campus"
                          variant="outlined"
                          margin="dense"
                          className={classes.textField}
                        />
                      )}
                    />
                  </Tooltip>
                </Grid>
              )}
              {v.dept && (
                <Grid item xs={2}>
                  <Tooltip
                    title={
                      lObj
                        ? ""
                        : "Please ensure Campus is selected before selecting a Department"
                    }
                    placement="bottom"
                  >
                    <Autocomplete
                      disabled={lObj ? false : true}
                      id="department"
                      value={dObj}
                      options={this.state.departmentData/*.sort(byName)*/}
                      getOptionSelected={(option, value) => option.id == value.id}
                      getOptionLabel={(option) => option.name}
                      onChange={this.handleChange("dept")}
                      renderOption={(option) => (
                        <div style={{ padding: "8px 2px" }}>{option.name}</div>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Department"
                          variant="outlined"
                          margin="dense"
                          className={classes.textField}
                        />
                      )}
                    />
                  </Tooltip>
                </Grid>
              )}
              {v.floor && (
                <Grid item xs={2}>
                  <Tooltip
                    title={
                      //bObj
                      //  ? ""
                      //  : "Please ensure Current Campus is selected before selecting a Floor"
                      lObj || bObj
                        ? ""
                        : "Please ensure Assigned Campus or Current Campus is selected before selecting a Floor"
                    }
                    placement="bottom"
                  >
                    <Autocomplete
                      disabled={/*bObj ? false : true*/!(lObj || bObj)}
                      id="floor"
                      value={fObj}
                      options={
                        bObj
                          ? this.state.floorData.filter((f) => f.building_name == bObj.name)
                          :
                            lObj
                              ? this.state.floorData.filter((f) => f.building_name == lObj.name)
                              : []
                      }
                      getOptionSelected={(option, value) => option.floor_id == value.floor_id}
                      getOptionLabel={(option) => option.name}
                      renderOption={(option) => (
                        <div style={{ padding: "8px 2px" }}>
                          {this.isFloorMatching(option.floor_id) ? (
                            <b>{option.name}</b>
                          ) : (
                            <div>{option.name}</div>
                          )}
                        </div>
                      )}
                      onChange={this.handleChange("floor")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Floor"
                          variant="outlined"
                          margin="dense"
                          className={classes.textField}
                        />
                      )}
                    />
                  </Tooltip>
                </Grid>
              )}

              <Grid
                xs
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  //padding: "4px"
                }}
              >
                <Button
                  onClick={() => this.handleClear()}
                  variant="outlined"
                  style={{
                    color: "#4b2e83",
                    //opacity: "1",
                    //outline: "none"
                  }}
                >
                  Clear
                </Button>
              </Grid>
              <Grid
                item
                /*xs={6} md={4} lg={8}*/ style={{
                  "textAlign": "end",
                  alignSelf: "center",
                  width: "24px",
                  marginLeft: "8px",
                }}
              >
                {v.expand && (
                  <Tooltip
                    title={
                      /*this.state.isFullScreen
                        ? */"Close Fullscreen"
                        /*: "See Fullscreen"*/
                    }
                  >
                    <IconButton
                      style={{ padding: 0 }}
                      onClick={() => {
                        /*if (this.state.isFullScreen) {*/
                          this.setState({ isFullScreen: false });
                        /*} else {
                          this.setState({ isFullScreen: true });
                        }*/
                      }}
                    >
                      <SvgIcon>
                        {/*this.state.isFullScreen ? */(
                          <path
                            fill="none"
                            stroke="#4b2e83"
                            strokeWidth="2"
                            d="M2,14 L10,14 L10,22 M1,23 L10,14 M23,1 L14,10 M22,10 L14,10 L14,2"
                          ></path>
                        )/* : (
                          <path
                            fill="none"
                            stroke="#4b2e83"
                            strokeWidth="2"
                            d="M10,14 L2,22 M1,15 L1,23 L9,23 M22,2 L14,10 M15,1 L23,1 L23,9"
                          ></path>
                        )*/}
                      </SvgIcon>
                    </IconButton>
                  </Tooltip>
                )}
              </Grid>
            </Grid>
          </div>
          <PFContext.Provider value={this.state}>
            {this.props.children}
          </PFContext.Provider>
        </Dialog>
        {/* <div>{React.cloneElement(this.props.children, {halo: this.state.coll})}</div> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { entities, locations, collections, message } = state;
  return {
    entities,
    locations,
    collections,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEntities: () => getEntity(dispatch),
    //storeEntity: () => getEntity(dispatch),
    getLocation: (entityId) => getLocations(dispatch, entityId),
    getCollection: (entityId, locationId = "") => {
      console.log("kollGetColl");
      getCollections(dispatch, entityId, locationId);
    },
    clearMessages: () => dispatch(clearMessage()),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withStyles(styles)
)(PrimaryFilter);
