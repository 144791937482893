import React from "react";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TextField from "@material-ui/core/TextField";
import "./Configure.css";
import Button from "@material-ui/core/Button";
import BackIcon from "@material-ui/icons/ArrowBackIos";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import CollectionsIcon from "@material-ui/icons/CollectionsBookmark";
import Battery80Icon from "@material-ui/icons/Battery80";
import Battery20Icon from "@material-ui/icons/Battery20";
import Battery30Icon from "@material-ui/icons/Battery30";
import Battery50Icon from "@material-ui/icons/Battery50";
import Battery60Icon from "@material-ui/icons/Battery60";
import Battery90Icon from "@material-ui/icons/Battery90";
import BatteryLowIcon from "@material-ui/icons/BatteryAlert";
import BatteryFullIcon from "@material-ui/icons/BatteryFull";
import BatteryUnknown from "@material-ui/icons/BatteryUnknown";
import RemoveIcon from "@material-ui/icons/RemoveCircleOutline";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import ResetIcon from "@material-ui/icons/Refresh";
import { Link } from "react-router-dom";
import {
  getEntity,
  getLocations,
  getCollections,
  getAssets,
  getDeviceFlags,
  cleanData,
  showMessage
} from "../actions/index";
import { compose } from "recompose";
import { connect } from "react-redux";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import { apiRequest, isPortalAdmin, storeEntityId, getOption, token, byPropertyCalled, formatDate, defaultImage } from "../utils/Utils";
import AlertDialog from "./AlertDialog";
import Collapse from "@material-ui/core/Collapse";
import CollectionsTable from "./CollectionsTable";
import DeleteIcon from "@material-ui/icons/Delete";
import RefreshIcon from '@material-ui/icons/Refresh';
import ViewIcon from "@material-ui/icons/OpenInNew";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import NoBarcode from "../images/No_Barcode.png";
import * as MeridianSDK from "@meridian/web-sdk";
import './Map.css';

import History from './TableHistory';
import SvgIcon from '@material-ui/core/SvgIcon';

import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";

var Barcode = require("react-barcode");
const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
    paddingTop: 10,
    paddingBottom: 10
  },
  input2: {
    width: "100%",
    padding: "4px",
    borderRadius: "5px",
    outline: "none",
    border: "1px solid lightgrey"
  },
  input: {
    height: 38
  },
  input3: {
    color: "black !important",
    height: 38
  },
  input1: {
    width: "80%",
    padding: "6px",
    borderRadius: "5px",
    outline: "none",
    border: "1px solid lightgrey",
    height: "40px",
    "&:disabled": {
      backgroundColor: "white"
    }
  },

  label: {
    marginTop: "-5px"
  },
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 2,
    flexGrow: 1
  },
  root2: {
    ...theme.mixins.gutters(),
    paddingBottom: theme.spacing.unit * 1
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  textField: { width: "100%" },
  dense: {
    marginTop: 16
  },
  menu: {
    width: 200
  },
  tableHead: {
    fontWeight: 400,
    fontSize: 14,
    fontWeight: 500,
    fontSize: "0.75rem",
    color: "rgba(0, 0, 0, 0.54)"
  },
  tableRow: {
    paddingTop: 0,
    paddingBottom: 0,
    verticalAlign: "middle",
    fontWeight: 400,
    fontSize: "0.8125rem",
    color: "rgba(0, 0, 0, 0.87)"
  },
  progress: {
    color: "#4b2e83"
  },
  appBar: {
    position: "relative",
    backgroundColor: "#4b2e83"
  },
  title: {
    marginLeft: 10,
    flex: 1,
    color: "white"
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class Configure extends React.Component {
  constructor(props) {
    super(props);
    this.lastKnownModified = "";
    this.state = {
      isSubmitting: false,
      created: "2019-05-08 11:30:03.727",
      modified: "2019-05-010 09:15:02.233",
      savebuttondisabled: this.props.savebtnstate,
      deletebuttondisabled: this.props.deletebtnstate,
      backbtnvisible: this.props.visiblebackbtn,
      isEditable: false,
      isDeleteAllowed: false,
      canRetireDevice: false,
      manufacturerData: [],
      modelData: [],
      typeData: [],
      flagData: [],
      EntityId: "",
      MacId: props.selectedAssetDetail.MacId,
      Manufacturer: "",
      DeviceType: "",
      DeviceModel: "",
      DisplayName: "",
      DeviceFlag: "",
      Description: "",
      DeviceStatus: "",
      manufacturerId: "",
      ExternalId: "",
      SerialNumber: "",
      data: [],
      hideMap: false,
      openAlert: false,
      actionType: "",
      openAddColection: false,
      openAddDepartment: false,
      collectiondata: [],
      showCollectionTable: true,
      selectedCollection: {},
      newCollection: "",
      newDepartment: "",
      departmentdata: [],
      deviceToDelete: {},
      loaded: false,
      message: "",
      orphanedDevices: [],
      showOrphanedList: false,
      showRemoveEntityButton: false,
      selectedCollType: "",
      meridiandata: [],
      Name: "",
      isNameChanged: false,
      isCurrentlySyncing: false,
      index: 0,
      tab: 0,
      currentZone: "",
      isFullScreen: false,
      searchText: ""
    };
  }

  inputChange = name => e => {
    const value = e.target.value;
    this.setState(
      {
        [name]: value
      },
      () => {
        if (name === "Manufacturer") {
          let id = "";
          for (let i = 0; i < this.state.manufacturerData.length; i++) {
            if (this.state.manufacturerData[i].name === value) {
              id = this.state.manufacturerData[i].id;
            }
          }
          if (id === "") {
            this.setState({ modelData: [], DeviceModel: "" });
          }
          else
            this.fetchData("assetmodels", id);

          this.setState({
            manufacturerId: id
          });
        }
      }
    );
  };

  handleAdd(name) {
    const that = this;
    if (name === "Manufacturer") {
      const { Manufacturer } = this.state;
      if (Manufacturer == "" || Manufacturer == null) {
        this.props.showMessageBox(
          "Please select from list or add new Manufacturer"
        );
        return;
      }

      apiRequest(`/masters/addmanufactures?name=${Manufacturer}`, "post")
        .then(x => {
          that.fetchData("manufactures");
          if (x.status > 0) {
            that.setState({
              manufacturerId: x.data[0]
            });
          }
          if (x.status > 0 && x.message == null)
            that.props.showMessageBox("SUCCESS");
          else
            that.props.showMessageBox(x.message);
        })
        .catch(err => {
          that.props.showMessageBox("ERROR");
        });
    } else if (name === "Model") {
      const { DeviceModel, manufacturerId, Manufacturer } = this.state;
      if (Manufacturer == "" || Manufacturer == null) {
        this.props.showMessageBox("Please select Manufacturer first");
        return;
      }
      if (DeviceModel == "" || DeviceModel == null) {
        this.props.showMessageBox("Please select from list or add new Model");
        return;
      }
      let id = manufacturerId;
      if (manufacturerId == "") {
        for (let i = 0; i < this.state.manufacturerData.length; i++) {
          if (this.state.manufacturerData[i].name === Manufacturer) {
            id = this.state.manufacturerData[i].id;
          }
        }
      }
      apiRequest(
        `/masters/addassetmodels?manfactureId=${id}&model=${DeviceModel}`,
        "post"
      )
        .then(x => {
          if (x.status > 0) that.fetchData("assetmodels", manufacturerId);

          if (x.status > 0 && x.message == null)
            that.props.showMessageBox("SUCCESS");
          else
            that.props.showMessageBox(x.message);
        })
        .catch(err => {
          that.props.showMessageBox("ERROR");
        });
    } else if (name === "Type") {
      const { DeviceType } = this.state;
      if (DeviceType == "" || DeviceType == null) {
        this.props.showMessageBox("Please select from list or add new Type");
        return;
      }
      apiRequest(`/masters/addassettypes?name=${DeviceType}`, "post")
        .then(x => {
          if (x.status > 0) that.fetchData("assettypes");

          if (x.status > 0 && x.message == null)
            that.props.showMessageBox("SUCCESS");
          else
            that.props.showMessageBox(x.message);
        })
        .catch(err => {
          that.props.showMessageBox("ERROR");
        });
    }
  }

  renderMap(mapID = this.state.meridiandata.map.id) {
    let isCurrent = this.state.index == 0 ? true : false;
    let point = !isCurrent ? [{
      type: "point",
      x: this.props.selectedAssetDetail.X,
      y: this.props.selectedAssetDetail.Y,
      // x: 100,
      // y: 100,
      backgroundColor: /*"#c00"*/"none",
      //size: 25,
      size: 100,
      backgroundImage: 'https://hpepngdemostor.blob.core.windows.net/images/locationmarker.gif',
      title: "Last Known"
  }] : [];
    const that = this;
    const api = new MeridianSDK.API({
      environment: "production",
      token: token
    });
    let element = this.state.isFullScreen ? document.getElementById("meridian-map-full") : document.getElementById("meridian-map");
    this.map = MeridianSDK.createMap(
      element,//document.getElementById("meridian-map"),
      {
        api: api,
        //locationID: that.props.selectedAssetDetail.MeridianLocationId,
        //floorID: that.props.selectedAssetDetail.MeridianMapId,
        //locationID: this.state.data.MeridianLocationId,
        //floorID: this.state.data.MeridianMapId,
        locationID: this.state.meridiandata.location || this.props.selectedAssetDetail.MeridianLocationId,
        floorID: mapID, //this.state.meridiandata.map.id,
        shouldMapPanZoom: function restrictedPanZoom(event) {
          if (event.type === "wheel" && !event.altKey) {
            return false;
          } else if (event.type === "touchstart") {
            return event.touches.length >= 2;
          }
          return true;
        },
        annotations: point,
        //height: "500px",
        height: this.state.isFullScreen ? "calc(100vh - 145px)" : "500px",
        placemarks: {
          labelZoomLevel: 0.1,
          filter: t => {
            return true;
          }
        },

        onPlacemarksUpdate: ({ allPlacemarks, filteredPlacemarks }) => {
          this.map.update({
            overlays: filteredPlacemarks
              .filter(placemark => placemark.area)
              .map((placemark, i) => {
                const hue = (i * (360 / 6)) % 360;
                return {
                  type: "polygon",
                  points: MeridianSDK.pointsFromArea(placemark.area),
                  //points: [3032,629],
                  fill: `hsla(${hue}, 100%, 60%, 0.3)`,
                  stroke: `hsl(${hue}, 100%, 60%)`
                };
              })
          });
        },

        tags: {
          filter: function(tag) {
            return isCurrent && tag.mac === that.props.selectedAssetDetail.MacId;
          },
          updateInterval: 10000
        }
      }
    );
  }

  componentDidMount() {
    this.getOrphanDevices();
    let that = this;

    apiRequest(
      `/accesscontrol?resourceType=tag&resourceId=${
        that.props.selectedAssetDetail.MacId
      }`,
      "get"
    )
      .then(x => {
        if (x.data[0] == 500 || x.data[0] == 700) {
          this.setState({
            isDeleteAllowed: true,
            canRetireDevice: true,
            isEditable: true
          });
        } else if (x.data[0] == 300) {
          this.setState({
            isDeleteAllowed: false,
            canRetireDevice: false,
            isEditable: true
          });
        } else {
          this.setState({
            isDeleteAllowed: false,
            canRetireDevice: false,
            isEditable: false
          });
        }
      })
      .catch(err => {
        console.log(err);
      });

    if (
      !that.props.selectedAssetDetail.MeridianLocationId ||
      !that.props.selectedAssetDetail.MeridianMapId
    ) {
      that.setState({
        hideMap: true
      });
    } else {
      //meridian get
      //if (that.props.selectedAssetDetail.DeviceFlag !== "NOT_TRACEABLE")
      that.getAssetFromMeridian(that.props.selectedAssetDetail.MeridianLocationId,that.props.selectedAssetDetail.MacId);
      // var url = `https://edit.meridianapps.com/api/locations/${that.props.selectedAssetDetail.MeridianLocationId}/asset-beacons/` + that.props.selectedAssetDetail.MacId;
      // fetch(url, getOption())
      //   .then(response => {
      //     if (response.ok) {
      //       return response.json();
      //     } else {
      //       this.setState({ hideMap: true });
      //     }
      //   })
      //   .then(data => {
      //     if (data && data.location && data.map.id) {
      //       console.log("meridiandata", data);
      //       var date = new Date();
      //       var strDate =
      //       date.getFullYear() + "-" + ("00" + (date.getMonth() + 1)).slice(-2)
      //       + "-" + ("00" + date.getDate()).slice(-2)
      //       + " "
      //       + ("00" + date.getHours()).slice(-2) + ":"
      //       + ("00" + date.getMinutes()).slice(-2)
      //       + ":" + ("00" + date.getSeconds()).slice(-2);
      //     that.setState(
      //       {
      //         hideMap: false,
      //         meridiandata: data,
      //         syncTime: strDate,
      //       },
      //       () => {
      //         setTimeout(() => {
      //           this.renderMap();
      //         }, 1000);
      //       }
      //     );
      //   }
      //   else {
      //     this.setState({ hideMap: true, syncTime: "Error has occured" });
      //   }
      //   })
      //   .catch(err => {
      //     console.log(err);
      //   });
    }
  }
  getAssetFromMeridian(locationId, assetId) {
          //meridian get
          var url = `https://edit.meridianapps.com/api/locations/${locationId}/asset-beacons/` + assetId;
          fetch(url, getOption())
            .then(response => {
              if (response.ok) {
                return response.json();
              } else {
                //console.log("meridiandata-not ok");
                this.setState({ hideMap: true });
              }
            })
            .then(data => {
              if (data /*&& data.location && data.map.id*/) {
                var modi = data.modified + "Z";
                var modi_date = new Date(modi);
                var date = new Date(data.last_heard * 1000);
                var strDate = formatDate(date);
                if (this.lastKnownModified == "")
                  this.lastKnownModified = strDate;
                console.log("meridiandata", data, strDate, formatDate(modi_date));
                // date.getFullYear() + "-" + ("00" + (date.getMonth() + 1)).slice(-2)
                // + "-" + ("00" + date.getDate()).slice(-2)
                // + " "
                // + ("00" + date.getHours()).slice(-2) + ":"
                // + ("00" + date.getMinutes()).slice(-2)
                // + ":" + ("00" + date.getSeconds()).slice(-2);

              //GET ZONE
              if (data.map.id) {
                let zoneUrl = `https://edit.meridianapps.com/api/locations/${locationId}/tag-zones?map_id=${data.map.id}`;
                //let zoneUrl = `https://edit.meridianapps.com/api/locations/${locationId}/tag-zones?tag_id=${this.props.selectedAssetDetail.MacId}`;
                fetch(zoneUrl, getOption())
                .then(response => {
                  if (response.ok) {
                    return response.json();
                  } else {
                    //NO ZONE
                  }
                })
                .then(data => {
                  //this.setState({ zone: data });
                  let isZoneFound = false;
                  data.results.forEach(zone => {
                    //console.log("currentZone", zone);
                    if (zone.tag_ids.includes(this.props.selectedAssetDetail.MacId)) {
                      this.setState({ currentZone: zone.name });
                      isZoneFound = true;
                      return;
                    }
                  });
                  if (!isZoneFound) {
                    this.setState({ currentZone: "No Zone" });
                  }
                })
                .catch(error => this.setState({ currentZone: "Zone Error" }))
              }

              this.setState(
                {
                  hideMap: false,
                  meridiandata: data,
                  syncTime: strDate,
                  isCurrentlySyncing: false
                },
                () => {
                  setTimeout(() => {
                    if (document.getElementById("meridian-map"))
                      this.renderMap();
                  }, 1000);
                }
              );
            }
            else {
              this.setState({ hideMap: true, syncTime: "Error has occured" });
            }
            })
            .catch(err => {
              console.log(err);
            });
  }

  handleBackButton = () => {
    this.props.data("false");
  };

  handleChange = name => event => {
    this.setState(
      {
        [name]: event.target.value
      },
      () => {
        if (this.state.message != "" && name == "newDepartment") {
          this.setState({
            message: ""
          });
        }
        if (name === "Manufacturer") {
          let id = "";
          for (let i = 0; i < this.state.manufacturerData.length; i++) {
            if (this.state.manufacturerData[i].name === event.target.value) {
              id = this.state.manufacturerData[i].id;
            }
          }
          if (id === "")
            this.setState({ modelData: [], DeviceModel: "" });
          else
            this.fetchData("assetmodels", id);
        }
        if (name === "Name") {
          this.setState({ isNameChanged: true });
        }
      }
    );

    this.setState({ savebuttondisabled: false });
  };

  componentWillMount() {
    this.props.getFlags();
    this.fetchData("manufactures");
    this.fetchData("assettypes");
    this.fetchAssetData();
  }

  componentWillUnmount() {
    if (this.map) {
      this.map.destroy();
    }
  }

  fetchData(dataType, id = "") {
    let url =
      dataType === "assetmodels"
        ? `/masters/${dataType}/${id}`
        : `/masters/${dataType}`;

    apiRequest(url, "get")
      .then(response => {
        // create an array of contacts only with relevant data
        const data = response.data.map(c => {
          return {
            id: c.ID,
            name: c.Value
          };
        });
        switch (dataType) {
          case "manufactures":
            this.setState(
              {
                manufacturerData: data.sort(byPropertyCalled("name"))
              },
              () => {
                let id = "";
                let value = this.state.Manufacturer;

                for (let i = 0; i < this.state.manufacturerData.length; i++) {
                  if (this.state.manufacturerData[i].name === value) {
                    id = this.state.manufacturerData[i].id;
                  }
                }
                if (id === "")
                  this.setState({ modelData: [], DeviceModel: "" });
                else
                  this.fetchData("assetmodels", id);
              }
            );
            break;
          case "assetmodels":
            this.setState({
              modelData: data.sort(byPropertyCalled("name"))
            });
            break;
          case "assettypes":
            this.setState({
              typeData: data.sort(byPropertyCalled("name"))
            });
            break;
          default:
            break;
        }
      })
      .catch(error => console.log(error));
  }

  saveChanges() {
    const {
      Manufacturer,
      DeviceType,
      DeviceModel,
      DisplayName,
      DeviceFlag,
      Description,
      ExternalId,
      SerialNumber,
      Name
    } = this.state;

    let data = {
      ...this.props.selectedAssetDetail,
      Manufacturer,
      DeviceType,
      DeviceModel,
      DisplayName,
      DeviceFlag,
      Description,
      ExternalId,
      SerialNumber,
      Name
    };
    const that = this;
    that.setState({
      isSubmitting: true,
      isEditable: false
    });
    apiRequest(`/assets/${that.props.selectedAssetDetail.MacId}`, "put", data)
    .then(x => {
      if (x.status > 0) {
        this.props.getAsset();
        this.fetchAssetData();
      }
      if (x.status > 0 && x.message == null)
        that.props.showMessageBox("SUCCESS");
      else
        that.props.showMessageBox(x.message);
      that.setState({
        isSubmitting: false,
        isEditable: true
      });
    })
    .catch(err => {
      that.props.showMessageBox("ERROR");
      that.setState({
        isSubmitting: false,
        isEditable: true
      });
    });
  }

  saveData = () => {
    this.setState(
      {
        openAlert: false
      },
      () => {
        setTimeout(() => {
          this.setState({
            actionType: ""
          });
        }, 500);
      }
    );

    console.log("namechange:", this.state.isNameChanged);
    if (!this.state.isNameChanged) {
      this.saveChanges();
    }
    else {
      //Meridian Patch Rest api, if its ok, our db can be updated
      var meridianName = {name: this.state.Name};
      var options = getOption('patch');
      options.body = JSON.stringify(meridianName);
      console.log("opt", options);
      this.setState({
        isSubmitting: true,
        isEditable: false
      });
      var url =`https://edit.meridianapps.com/api/locations/${this.props.selectedAssetDetail.MeridianLocationId}/asset-beacons/${this.props.selectedAssetDetail.MacId}`;
      fetch(url, options)
      .then(response => {
        if (response.ok) {
          //Save changes in our db
          this.saveChanges();
        } else {
          //no save
          this.props.showMessageBox("Meridian save was not successful, no changes were made");
        }
        this.setState({
          isSubmitting: false,
          isEditable: true,
          isNameChanged: false
        });
        console.log("resp", response);
        return response.json();
      })
      // .then(data => {
      //   this.props.showMessageBox(data.detail)
      //   return data;
      // })
      .catch(err => {
        console.log("errorResp:", err);
        this.props.showMessageBox("Meridian ERROR");
        this.setState({
          isSubmitting: false,
          isEditable: true,
          isNameChanged: false
        });
      });
    }
  };

  handleRemoveFromEntity = () => {
    this.setState(
      {
        openAlert: false
      },
      () => {
        setTimeout(() => {
          this.setState({
            actionType: ""
          });
        }, 500);
      }
    );
    const that = this;
    const data = {
      entityId: that.state.EntityId,
      mac: [that.state.MacId]
    };

    apiRequest(`/assets/ownership`, "delete", data)
      .then(x => {
        if (x.status > 0) {
          that.handleBackButton();
          that.props.getAsset();
        }
        if (x.status > 0 && x.message == null)
          that.props.showMessageBox("SUCCESS");
        else
          that.props.showMessageBox(x.message);
      })
      .catch(err => {
        that.setState({
          isSubmitting: false,
          isEditable: true
        });
      });
  };

  handleRetire = () => {
    const that = this;
    that.setState({
      isSubmitting: true,
      isEditable: false
    });

    apiRequest(
      `/assets/RetireDevice/${that.props.selectedAssetDetail.MacId}`,
      "post"
    )
      .then(x => {
        that.setState({
          isSubmitting: false,
          isEditable: true
        });
        if (x.status > 0) {
          that.handleBackButton();
          that.props.getAsset();
        }
        if (x.status > 0 && x.message == null)
          that.props.showMessageBox("SUCCESS");
        else
          that.props.showMessageBox(x.message);
      })
      .catch(err => {
        that.setState({
          isSubmitting: false,
          isEditable: true
        });
      });
  };

  handleDelete = () => {
    this.setState(
      {
        openAlert: false
      },
      () => {
        setTimeout(() => {
          this.setState({
            actionType: ""
          });
        }, 500);
      }
    );
    const that = this;
    that.setState({
      isSubmitting: true,
      isEditable: false
    });

    apiRequest(`/assets/${that.props.selectedAssetDetail.MacId}`, "delete")
      .then(x => {
        if (x.status > 0) {
          that.handleBackButton();
          that.props.getAsset();
        }
        that.setState({
          isSubmitting: false,
          isEditable: true
        });
        if (x.status > 0 && x.message == null)
          that.props.showMessageBox("SUCCESS");
        else
          that.props.showMessageBox(x.message);
      })
      .catch(err => {
        that.setState({
          isSubmitting: false,
          isEditable: true
        });
      });
  };

  componentWillReceiveProps({ deviceFlags, collections }) {
    this.setState({
      flagData: deviceFlags,
      collectiondata: collections
    });
  }

  handleScroll = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  fetchAssetData() {
    apiRequest(`/assets/${this.state.MacId}`, "get")
      .then(x => {
        this.setState(
          {
            data: x.data[0],
            EntityId: x.data[0].EntityId,
            MacId: x.data[0].MacId,
            Manufacturer: x.data[0].Manufacturer,
            DeviceType: x.data[0].DeviceType,
            DeviceModel: x.data[0].DeviceModel,
            DisplayName: x.data[0].DisplayName,
            DeviceFlag: x.data[0].DeviceFlag,
            Description: x.data[0].Description,
            DeviceStatus: x.data[0].DeviceStatus,
            ExternalId: x.data[0].ExternalId,
            SerialNumber: x.data[0].SerialNumber,
            Name: x.data[0].Name,
            loaded: true
          },
          () => {
            this.checkEntityAccess();
            if (x.data[0].EntityId) {
              apiRequest(
                `/entities/${x.data[0].EntityId}/locations/0/collections`,
                "get"
              )
                .then(res => {
                  this.setState({
                    departmentdata: res.data
                  });
                })
                .catch(err => console.log(err));
              this.props.getCollection(x.data[0].EntityId);
            }
            let id = "";
            let value = this.state.Manufacturer;
            for (let i = 0; i < this.state.manufacturerData.length; i++) {
              if (this.state.manufacturerData[i].name === value) {
                id = this.state.manufacturerData[i].id;
              }
            }
            if (id === "")
              this.setState({ modelData: [], DeviceModel: "" });
            else
              this.fetchData("assetmodels", id);
          }
        );
      })
      .catch(err => {
        console.log(err);
      });
  }

  showCollection(coll_id) {
    this.setState({
      showCollectionTable: false
    });

    apiRequest(`/assetmaps/collections/${coll_id}`, "get")
      .then(response => {
        if (response.status > 0) {
          this.setState(
            {
              selectedCollection: response.data.length > 0 ? response.data : ""
            },
            () => {
              storeEntityId(response.data[0].EntityId);
            }
          );
        }
      })
      .catch(error => console.log(error));
  }

  addCollection = () => {
    if (this.state.newCollection == "") {
      this.props.showMessageBox("Please select Collection");
      return;
    }
    for (let element of this.state.collectiondata) {
      if (element.name == this.state.newCollection) {
        const data = {
          mac: [this.state.data.MacId],
          CollectionId: element.id
        };
        apiRequest("/assetmaps", "post", data)
          .then(x => {
            if (x.status > 0) {
              this.fetchAssetData();
            }
            if (x.status > 0 && x.message == null)
              this.props.showMessageBox("SUCCESS");
            else {
              this.props.showMessageBox(x.message);
            }
          })
          .catch(err => {
            this.props.showMessageBox("ERROR");
          });
        return;
      }
    }

    const data = {
      EntityId: this.state.data.EntityId,
      CollectionName: this.state.newCollection,
      CollectionType: "User Defined Collection",
      Description: "",
      CollectionTypeId: 2
    };
    let method = "post";
    let url = `/entities/${this.state.data.EntityId}/locations/0/collections`;

    apiRequest(url, method, data)
      .then(x => {
        if (x.status == 0) {
          this.props.showMessageBox(x.message);
          return;
        }
        const data = {
          mac: [this.state.data.MacId],
          CollectionId: x.data[0]
        };
        apiRequest("/assetmaps", "post", data)
          .then(x => {
            if (x.status > 0) {
              this.fetchAssetData();
            }
            if (x.status > 0 && x.message == null)
              this.props.showMessageBox("SUCCESS");
            else {
              this.props.showMessageBox(x.message);
            }
          })
          .catch(err => {
            this.props.showMessageBox("ERROR");
          });
      })
      .catch(err => {
        this.props.showMessageBox("ERROR");
      });
  };

  addDepartment = () => {
    for (let element of this.state.departmentdata) {
      if (element.CollectionName == this.state.newDepartment) {
        const data = {
          mac: [this.state.data.MacId],
          CollectionId: element.CollectionId
        };
        apiRequest("/assetmaps", "post", data)
          .then(x => {
            if (x.status > 0) {
              this.fetchAssetData();
            }
            if (x.status > 0 && x.message == null)
              this.props.showMessageBox("SUCCESS");
            else {
              this.props.showMessageBox(x.message);
            }
          })
          .catch(err => {
            this.props.showMessageBox("ERROR");
          });
        return;
      }
    }

    this.setState({
      message:
        "*Either the Department you are looking for does not exist or you don't have access to that department. Consult Organization admin."
    });
  };

  removeDeviceFromCollection = () => {
    this.setState(
      {
        openAlert: false
      },
      () => {
        setTimeout(() => {
          this.setState({
            actionType: ""
          });
        }, 500);
      }
    );

    apiRequest(`/assetmaps`, "delete", {
      ids: [this.state.deviceToDelete.AssetMapId]
    })
      .then(x => {
        if (x.status > 0) {
          this.showCollection(this.state.deviceToDelete.CollectionId);
        }
        if (x.status > 0 && x.message == null)
          this.props.showMessageBox("SUCCESS");
        else
          this.props.showMessageBox(x.message);
      })
      .catch(err => this.props.showMessageBox("ERROR"));
  };

  handleReplace = () => {
    this.setState(
      {
        openAlert: false
      },
      () => {
        setTimeout(() => {
          this.setState({
            actionType: ""
          });
        }, 500);
      }
    );
    apiRequest(
      `/assets/${this.state.data.MacId}/replace/${
        this.state.deviceToReplaceWith
      }`,
      "post"
    )
      .then(res => {
        if (res.status > 0) {
          this.setState(
            {
              MacId: this.state.deviceToReplaceWith
            },
            () => {
              this.fetchAssetData();
            }
          );
          this.props.getAsset();
        }

        this.setState({
          showOrphanedList: false
        });
        if (res.status == 0)
          this.props.showMessageBox(res.message);
        else
          this.props.showMessageBox("SUCCESS");
      })
      .catch(err => {
        this.props.showMessageBox("ERROR");
      });
  };

  getOrphanDevices = () => {
    apiRequest(`/assets/replace`, "get")
      .then(res => {
        this.setState({
          orphanedDevices: res.data
        });
      })
      .catch(err => {
        this.props.showMessageBox("ERROR");
      });
  };

  handleClickOpen = () => {
    this.setState({
      showOrphanedList: true
    });
  };

  handleClose = () => {
    this.setState({
      showOrphanedList: false
    });
  };

  checkEntityAccess() {
    apiRequest(
      `/accesscontrol?resourceType=entity&resourceId=${this.state.EntityId}`,
      "get"
    )
      .then(response => {
        if (response.data[0] == 700 || response.data[0] == 500) {
          this.setState({
            showRemoveEntityButton: true
          });
        } else {
          this.setState({
            showRemoveEntityButton: false
          });
        }
      })
      .catch(error => console.log("error", error));
  }

  filterOrphans(value) {
    if (this.state.searchText == "") {
      return true;
    }
    else {
      if (value.toLowerCase().includes(this.state.searchText.toLowerCase()))
        return true;
      return false;
    }
  }

  render() {
    console.log("propi", this.props);
    console.log("rnr", this.state.currentZone);
    let styles = [
      {
        cursor: "pointer",
        color: "#4b2e83",
        textAlign: "center",
        borderBottom: "2px solid #4b2e83",
      },
      {
        cursor: "pointer",
        color: "lightgrey",
        textAlign: "center",
        borderBottom: "2px solid lightgrey",
      }
    ,];
    const { classes } = this.props;
    const {
      EntityId,
      EntityName,
      LocationId,
      LocationName,
      CollectionId,
      CollectionName,
      DeviceId,
      MacId,
      Name,
      DisplayName,
      Description,
      TagCode,
      DeviceFlag,
      BatteryLevel,
      FloorLabel,
      FloorNumber,
      Manufacturer,
      DeviceType,
      DeviceModel,
      DeviceStatus,
      IsActive,
      ImageUrl,
      MeridianBuildingName,
      ExternalId,
      SerialNumber
    } = this.state.data;

    const {
      manufacturerData,
      modelData,
      typeData,
      collectiondata,
      selectedCollection,
      orphanedDevices,
      showOrphanedList
    } = this.state;

    const dept = this.state.data.Collections
      ? this.state.data.Collections.filter(
          item => item.CollectionType == "Department"
        )
      : "";

    const date = new Date(`${this.props.lastSync}Z`);
    var lastKnownSync = formatDate(date);

    return (
      <React.Fragment>
        {this.state.loaded ? (
          <div style={{ height: "100%", width: "100%" }}>
            <Dialog
              fullScreen
              open={showOrphanedList}
              onClose={this.handleClose}
              TransitionComponent={Transition}
            >
              <AppBar className={classes.appBar}>
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={this.handleClose}
                    aria-label="Close"
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography variant="h6" className={classes.title}>
                    Select Device to Replace with
                  </Typography>
                </Toolbar>
              </AppBar>

              <Grid
                container
                spacing={2}
                style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
              >
                <Grid item /*xs={12}*/ style={{width: "85%"}}>
                  <TextField
                    style={{
                      width: "100%",
                      // paddingLeft: 30,
                      // paddingRight: 10,
                      paddingBottom: "1px",
                    }}
                    value={this.state.searchText}
                    margin="dense"
                    placeholder="Search"
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment>
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      className: classes.input,
                    }}
                    onChange={evt => this.setState({ searchText: evt.target.value })}
                  />
                </Grid>
              </Grid>

              <table
                style={{ width: "85%", margin: "10px auto" }}
                className="table table-striped coll-table table-bordered table-condensed"
              >
                <thead>
                  <tr>
                    <th className={classes.tableHead}>IMAGE</th>
                    <th className={classes.tableHead}>DEVICE NAME</th>
                    {/* <th className={classes.tableHead}>DISPLAY NAME</th> */}
                    <th className={classes.tableHead}>ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  {orphanedDevices.filter(d => this.filterOrphans(d.Name)).map((item) => (
                    <tr key={item.MacId}>
                      <td
                        style={{
                          paddingTop: 0,
                          paddingBottom: 0,
                          verticalAlign: "middle",
                        }}
                      >
                        <img
                          className="image"
                          style={{ borderRadius: "50%" }}
                          src={item.ImageUrl ? item.ImageUrl : defaultImage}
                          height={30}
                          width={30}
                        />
                      </td>
                      <td className={classes.tableRow}>{item.Name}</td>
                      {/* <td className={classes.tableRow}>{item.DisplayName}</td> */}
                      <td
                        style={{
                          paddingTop: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          verticalAlign: "middle",
                        }}
                      >
                        <Button
                          className="button3"
                          style={{
                            color: "#4b2e83",
                          }}
                          onClick={() => {
                            this.setState({
                              actionType: "REPLACE",
                              openAlert: true,
                              deviceToReplaceWith: item.MacId,
                            });
                          }}
                        >
                          REPLACE
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Dialog>

            <AlertDialog
              open={this.state.openAlert}
              onClickYes={
                this.state.actionType == "REMOVE FROM Organization"
                  ? this.handleRemoveFromEntity
                  : this.state.actionType == "SAVE"
                  ? this.saveData
                  : this.state.actionType == "REMOVE FROM COLLECTION"
                  ? this.removeDeviceFromCollection
                  : this.state.actionType == "REPLACE"
                  ? this.handleReplace
                  : this.handleDelete
              }
              action={
                this.state.actionType == "REMOVE FROM Organization"
                  ? "Remove From Organization"
                  : this.state.actionType == "SAVE"
                  ? "Save"
                  : this.state.actionType == "REPLACE"
                  ? "Replace"
                  : "Delete"
              }
              close={() => {
                this.setState({ openAlert: false });
              }}
            />

            <Grid
              container
              spacing={2}
              //style={{ display: "none" /*paddingTop: "10px"*/ }}
            >
              <Grid item xs={6} md={6} lg={6}>
                <div
                  onClick={() => {
                    if (this.state.tab != 0) {
                      //if return from Asset History
                      this.setState({ tab: 0 }, () => {
                        if (this.props.selectedAssetDetail.MeridianMapId && document.getElementById("meridian-map")) {
                          this.renderMap(this.props.selectedAssetDetail.MeridianMapId);
                        }
                        else if (this.props.selectedAssetDetail.MeridianMapId && document.getElementById("meridian-map-full")) {
                          this.renderMap(this.props.selectedAssetDetail.MeridianMapId);
                        }
                      });
                    }
                  }}
                  style={this.state.tab == 0 ? styles[0] : styles[1]}
                >
                  <h5 style={{ marginBottom: "15px" }}>Asset Detail</h5>
                </div>
              </Grid>
              <Grid item xs={6} md={6} lg={6}>
                <div
                  onClick={() => this.setState({ tab: 1 })}
                  style={this.state.tab == 0 ? styles[1] : styles[0]}
                >
                  <h5 style={{ marginBottom: "15px" }}>Asset History</h5>
                </div>
              </Grid>
            </Grid>

            {/* Asset History */}
            {this.state.tab == 1 ? (
              <History
                name={this.props.selectedAssetDetail.Name}
                mac={this.props.selectedAssetDetail.MacId}
                image={this.props.selectedAssetDetail.ImageUrl}
                assetDetail={this.props.selectedAssetDetail}
              />
            ) : (
              <React.Fragment>
                {this.state.backbtnvisible && (
                  <div className="border-bottom">
                    <Grid container spacing={3}>
                      <Grid item xs={10} sm={6} md={6} lg={6}>
                        <Button
                          //className="button3"
                          style={{
                            color: "#4b2e83",
                            margin: 10,
                            marginLeft: 0,
                            marginTop: 10,
                            paddingTop: 10,
                            paddingBottom: 10,
                          }}
                          onClick={this.handleBackButton}
                        >
                          <BackIcon /> Back
                        </Button>
                      </Grid>
                      <Grid
                        item
                        xs={10}
                        sm={6}
                        md={6}
                        lg={6}
                        style={{ paddingRight: "0px" }}
                      >
                        <div className="text-right">
                          <CircularProgress
                            hidden={!this.state.isSubmitting}
                            className={classes.progress}
                            style={{ color: "#4b2e83", margin: 10 }}
                          />
                          <Button
                            hidden={true}
                            variant="outlined"
                            style={{
                              color: "#F44336",
                              margin: 10,
                              paddingTop: 10,
                              paddingBottom: 10,
                              height: 45,
                            }}
                            className="button1"
                          >
                            Retire Device
                          </Button>{" "}
                          <Button
                            hidden={
                              DeviceFlag == "ORPHAN" || this.state.isSubmitting
                            }
                            onClick={() => {
                              this.setState({
                                showOrphanedList: true,
                              });
                            }}
                            disabled={!this.state.isEditable}
                            variant="outlined"
                            style={{
                              color: "#F44336",
                              margin: 10,
                              paddingTop: 10,
                              paddingBottom: 10,
                              height: 45,
                            }}
                            className="button1"
                          >
                            Replace
                          </Button>{" "}
                          <Button
                            hidden={this.state.isSubmitting || !isPortalAdmin}
                            onClick={() => {
                              this.setState({
                                actionType: "DELETE",
                                openAlert: true,
                              });
                            }}
                            disabled={!this.state.isDeleteAllowed}
                            variant="outlined"
                            style={{
                              color: "#F44336",
                              margin: 10,
                              paddingTop: 10,
                              paddingBottom: 10,
                              height: 45,
                            }}
                            className="button1"
                          >
                            Delete
                          </Button>{" "}
                          <Button
                            hidden={this.state.isSubmitting}
                            onClick={() => {
                              this.setState({
                                actionType: "SAVE",
                                openAlert: true,
                              });
                            }}
                            disabled={!this.state.isEditable}
                            variant="outlined"
                            style={{
                              color: "#4b2e83",
                              margin: 10,
                              height: 45,
                            }}
                            className="button1"
                          >
                            Save
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                )}
                <Grid
                  container
                  //hidden={DeviceFlag == "ORPHAN"}
                  spacing={3}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid
                    style={{ paddingLeft: "0px" }}
                    item
                    xs={12}
                    md={3}
                    lg={3}
                    // md={
                    //   !this.state.openAddColection && !this.state.openAddDepartment
                    //     ? 8
                    //     : 2
                    // }
                    // lg={
                    //   !this.state.openAddColection && !this.state.openAddDepartment
                    //     ? 8
                    //     : 2
                    // }
                  >
                    {DeviceFlag !== "ORPHAN" && (
                      <React.Fragment>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "row",
                            }}
                          >
                            <Tooltip
                              className="float-left"
                              title="Assign to Collection"
                            >
                              <IconButton
                                style={
                                  this.state.isEditable
                                    ? {
                                        color: "#4b2e83",
                                        opacity: "1",
                                        outline: "none",
                                      }
                                    : { color: "#343a40", opacity: "0.4" }
                                }
                                disabled={!this.state.isEditable}
                                aria-label="Assign to Collection"
                                onClick={() =>
                                  this.setState({
                                    openAddColection:
                                      !this.state.openAddColection,
                                    openAddDepartment: false,
                                    message: "",
                                  })
                                }
                              >
                                <AddIcon />
                              </IconButton>
                            </Tooltip>
                            <div>
                              <b>Collection</b>
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "row",
                            }}
                          >
                            <Tooltip
                              className="float-left"
                              title="Assign to Department"
                            >
                              <IconButton
                                style={
                                  this.state.isEditable
                                    ? {
                                        color: "#4b2e83",
                                        opacity: "1",
                                        outline: "none",
                                      }
                                    : { color: "#343a40", opacity: "0.4" }
                                }
                                disabled={!this.state.isEditable}
                                aria-label="Assign to Department"
                                onClick={() =>
                                  this.setState({
                                    openAddDepartment:
                                      !this.state.openAddDepartment,
                                    openAddColection: false,
                                  })
                                }
                              >
                                <AddIcon />
                              </IconButton>
                            </Tooltip>
                            <div>
                              <b>Department</b>
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    )}
                  </Grid>
                  {this.state.openAddColection &&
                  !this.state.openAddDepartment &&
                  DeviceFlag !== "ORPHAN" ? (
                    <React.Fragment>
                      <Grid
                        item
                        xs={12}
                        md={4}
                        lg={4}
                        style={{
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <input
                          list="text_editors2"
                          className={classes.input2}
                          placeholder="Add Collections..."
                          onChange={this.handleChange("newCollection")}
                          value={this.state.newCollection}
                        />
                        <datalist id="text_editors2">
                          <select multiple>
                            {collectiondata.map((user) => (
                              <option key={user.id} value={user.name}>
                                {user.name}
                              </option>
                            ))}
                          </select>
                        </datalist>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        md={2}
                        lg={2}
                        className="text-right"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Tooltip title="Reset">
                          <IconButton
                            style={{
                              color: "#4b2e83",
                              opacity: "1",
                              outline: "none",
                            }}
                            aria-label="Reset"
                            onClick={() => {
                              this.setState({
                                newCollection: "",
                              });
                            }}
                          >
                            <ResetIcon />
                          </IconButton>
                        </Tooltip>
                        <Button
                          variant="outlined"
                          style={{
                            color: "#4b2e83",
                            margin: 10,
                            marginBottom: 0,
                            marginTop: 0,
                          }}
                          onClick={this.addCollection}
                          className="button1"
                        >
                          Add
                        </Button>
                      </Grid>
                    </React.Fragment>
                  ) : null}
                  {!this.state.openAddColection &&
                  this.state.openAddDepartment &&
                  DeviceFlag !== "ORPHAN" ? (
                    <React.Fragment>
                      <Grid
                        item
                        xs={12}
                        md={4}
                        lg={4}
                        style={{
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <input
                          list="text_editors2"
                          className={classes.input2}
                          placeholder="Add Departments..."
                          onChange={this.handleChange("newDepartment")}
                          value={this.state.newDepartment}
                        />
                        <datalist id="text_editors2">
                          <select multiple>
                            {this.state.departmentdata.map((user) => (
                              <option
                                key={user.CollectionId}
                                value={user.CollectionName}
                              >
                                {user.CollectionName}
                              </option>
                            ))}
                          </select>
                        </datalist>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        md={2}
                        lg={2}
                        className="text-right"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Tooltip title="Reset">
                          <IconButton
                            style={{
                              color: "#4b2e83",
                              opacity: "1",
                              outline: "none",
                            }}
                            aria-label="Reset"
                            onClick={() => {
                              this.setState({
                                newDepartment: "",
                              });
                            }}
                          >
                            <ResetIcon />
                          </IconButton>
                        </Tooltip>
                        <Button
                          variant="outlined"
                          style={{
                            color: "#4b2e83",
                            margin: 10,
                            marginBottom: 0,
                            marginTop: 0,
                          }}
                          onClick={this.addDepartment}
                          className="button1"
                        >
                          Add
                        </Button>
                      </Grid>
                    </React.Fragment>
                  ) : null}
                  <Grid item xs={10} md={3} lg={3}>
                    <div
                      style={{
                        display: "flex",
                        float: "right",
                        padding: 0,
                        margin: 0,
                        alignItems: "center",
                      }}
                    >
                      <h6
                        style={{ padding: 0, margin: 0 /*paddingTop: 15*/ }}
                        className="float-right"
                      >
                        {BatteryLevel > 100
                          ? "unknown"
                          : "    " + BatteryLevel + "%"}
                      </h6>
                      {BatteryLevel > 0 && BatteryLevel <= 10 && (
                        <BatteryLowIcon
                          className="float-right"
                          style={{
                            color: "#4b2e83",
                            //paddingTop: 10,
                            height: "39",
                          }}
                        />
                      )}
                      {BatteryLevel > 10 && BatteryLevel <= 25 && (
                        <Battery20Icon
                          className="float-right"
                          style={{
                            color: "#4b2e83",
                            //paddingTop: 10,
                            height: "39",
                          }}
                        />
                      )}
                      {BatteryLevel > 25 && BatteryLevel <= 40 && (
                        <Battery30Icon
                          className="float-right"
                          style={{
                            color: "#4b2e83",
                            //paddingTop: 10,
                            height: "39",
                          }}
                        />
                      )}
                      {BatteryLevel > 40 && BatteryLevel <= 55 && (
                        <Battery50Icon
                          className="float-right"
                          style={{
                            color: "#4b2e83",
                            //paddingTop: 10,
                            height: "39",
                          }}
                        />
                      )}
                      {BatteryLevel > 55 && BatteryLevel <= 75 && (
                        <Battery60Icon
                          className="float-right"
                          style={{
                            color: "#4b2e83",
                            //paddingTop: 10,
                            height: "39",
                          }}
                        />
                      )}
                      {BatteryLevel > 75 && BatteryLevel <= 85 && (
                        <Battery80Icon
                          className="float-right"
                          style={{
                            color: "#4b2e83",
                            //paddingTop: 10,
                            height: "39",
                          }}
                        />
                      )}
                      {BatteryLevel > 85 && BatteryLevel <= 99 && (
                        <Battery90Icon
                          className="float-right"
                          style={{
                            color: "#4b2e83",
                            //paddingTop: 10,
                            height: "39",
                          }}
                        />
                      )}
                      {BatteryLevel == 100 && (
                        <BatteryFullIcon
                          className="float-right"
                          style={{
                            color: "#4b2e83",
                            //paddingTop: 10,
                            height: "39",
                          }}
                        />
                      )}
                      {BatteryLevel > 100 && (
                        <BatteryUnknown
                          className="float-right"
                          style={{
                            color: "#4b2e83",
                            //paddingTop: 10,
                            height: "39",
                          }}
                        />
                      )}

                      <img
                        className="image"
                        src={ImageUrl? ImageUrl : defaultImage}
                        width="30px"
                        style={{
                          borderRadius: "50%",
                          //marginTop: 9,
                          marginLeft: 10,
                        }}
                        height="30px"
                        alt=""
                      />
                    </div>
                  </Grid>
                </Grid>
                <div style={{ color: "red", marginLeft: 15 }}>
                  {this.state.message}
                </div>
                <hr
                  //hidden={DeviceFlag == "ORPHAN"}
                  style={{ marginBottom: 0, marginTop: 0 }}
                />
                <div className="justify-content-between  align-items-center pt-3 pb-2 mb-3">
                  <div>
                    {/* <Grid container spacing={3}>
                      <Grid className="padding0" item xs={10} sm={6} md={4} lg={4}>
                        <TextField
                          id="outlined-name"
                          label="Device Name"
                          disabled={!this.state.isEditable}
                          value={this.state.Name}
                          type="text"
                          style={{
                            width: "100%",
                            borderColor: "#4b2e83",
                          }}
                          required
                          margin="dense"
                          onChange={this.handleChange("Name")}
                          variant="outlined"
                          placeholder="Name"
                          InputProps={{ className: classes.input }}
                          InputLabelProps={{
                            className: classes.label,
                            shrink: true,
                          }}
                        />
                      </Grid>
                      {/* <Grid className="padding0" item xs={10} sm={6} md={2} lg={2}>
                        <TextField
                          id="outlined-name"
                          label="Display Name"
                          disabled={!this.state.isEditable}
                          value={this.state.DisplayName}
                          type="text"
                          style={{
                            width: "100%",
                            borderColor: "#4b2e83",
                          }}
                          required
                          margin="dense"
                          onChange={this.handleChange("DisplayName")}
                          variant="outlined"
                          placeholder="Name"
                          InputProps={{ className: classes.input }}
                          InputLabelProps={{
                            className: classes.label,
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={10} sm={6} md={2} lg={2}>
                        <TextField
                          id="outlined-name"
                          label="Device Id"
                          disabled
                          margin="dense"
                          value={DeviceId}
                          type="text"
                          style={{
                            width: "100%",
                            borderColor: "#4b2e83",
                          }}
                          onChange={this.handleChange("deviceid")}
                          variant="outlined"
                          placeholder="Device Id"
                          InputProps={{ className: classes.input }}
                          InputLabelProps={{
                            className: classes.label,
                            shrink: true,
                          }}
                        />
                      </Grid>

                      <Grid item xs={10} sm={6} md={2} lg={2}>
                        <TextField
                          id="outlined-name"
                          label="Device Flag"
                          disabled
                          value={DeviceFlag}
                          type="text"
                          style={{
                            width: "100%",
                            borderColor: "#4b2e83",
                          }}
                          margin="dense"
                          onChange={this.handleChange("Asset Flag")}
                          variant="outlined"
                          placeholder="Device Flag"
                          InputProps={{ className: classes.input }}
                          InputLabelProps={{
                            className: classes.label,
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={10} sm={6} md={2} lg={2}>
                        <div
                          style={{
                            display: "flex",
                            float: "right",
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          <h6
                            style={{ padding: 0, margin: 0, paddingTop: 15 }}
                            className="float-right"
                          >
                            {BatteryLevel > 100
                              ? "unknown"
                              : "    " + BatteryLevel + "%"}
                          </h6>
                          {BatteryLevel > 0 && BatteryLevel <= 10 && (
                            <BatteryLowIcon
                              className="float-right"
                              style={{
                                color: "#4b2e83",
                                paddingTop: 10,
                                height: "39",
                              }}
                            />
                          )}
                          {BatteryLevel > 10 && BatteryLevel <= 25 && (
                            <Battery20Icon
                              className="float-right"
                              style={{
                                color: "#4b2e83",
                                paddingTop: 10,
                                height: "39",
                              }}
                            />
                          )}
                          {BatteryLevel > 25 && BatteryLevel <= 40 && (
                            <Battery30Icon
                              className="float-right"
                              style={{
                                color: "#4b2e83",
                                paddingTop: 10,
                                height: "39",
                              }}
                            />
                          )}
                          {BatteryLevel > 40 && BatteryLevel <= 55 && (
                            <Battery50Icon
                              className="float-right"
                              style={{
                                color: "#4b2e83",
                                paddingTop: 10,
                                height: "39",
                              }}
                            />
                          )}
                          {BatteryLevel > 55 && BatteryLevel <= 75 && (
                            <Battery60Icon
                              className="float-right"
                              style={{
                                color: "#4b2e83",
                                paddingTop: 10,
                                height: "39",
                              }}
                            />
                          )}
                          {BatteryLevel > 75 && BatteryLevel <= 85 && (
                            <Battery80Icon
                              className="float-right"
                              style={{
                                color: "#4b2e83",
                                paddingTop: 10,
                                height: "39",
                              }}
                            />
                          )}
                          {BatteryLevel > 85 && BatteryLevel <= 99 && (
                            <Battery90Icon
                              className="float-right"
                              style={{
                                color: "#4b2e83",
                                paddingTop: 10,
                                height: "39",
                              }}
                            />
                          )}
                          {BatteryLevel == 100 && (
                            <BatteryFullIcon
                              className="float-right"
                              style={{
                                color: "#4b2e83",
                                paddingTop: 10,
                                height: "39",
                              }}
                            />
                          )}
                          {BatteryLevel > 100 && (
                            <BatteryUnknown
                              className="float-right"
                              style={{
                                color: "#4b2e83",
                                paddingTop: 10,
                                height: "39",
                              }}
                            />
                          )}

                          <img
                            className="image"
                            src={ImageUrl}
                            width="30px"
                            style={{
                              borderRadius: "50%",
                              marginTop: 9,
                              marginLeft: 10,
                            }}
                            height="30px"
                            alt=""
                          />
                        </div>
                      </Grid>
                      <Grid item xs={10} sm={6} md={2} lg={2}>
                        {ExternalId != null && ExternalId != "" ? (
                          <Barcode
                            value={ExternalId}
                            displayValue={false}
                            height={35}
                            width={0.7}
                          />
                        ) : (
                          <img src={NoBarcode} height={45} width={100} />
                        )}
                      </Grid>
                    </Grid> */}

                    <Grid container spacing={3}>
                      <Grid
                        item
                        xs={10}
                        sm={10}
                        md={6}
                        lg={4}
                        style={{ paddingTop: 0 }}
                      >
                        <Paper
                          className={classes.root}
                          style={{ padding: 10, marginTop: 8 }}
                        >
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            <h5
                              style={{
                                color: "#4b2e83",
                                width: "100%",
                                paddingLeft: 2,
                                padding: 0,
                                margin: 0,
                              }}
                            >
                              Info
                            </h5>
                          </div>
                          <hr />
                          <TextField
                            id="outlined-name"
                            label="Device Name"
                            disabled={!this.state.isEditable}
                            value={this.state.Name}
                            type="text"
                            style={{
                              width: "100%",
                              borderColor: "#4b2e83",
                              marginBottom: 13,
                            }}
                            required
                            margin="dense"
                            onChange={this.handleChange("Name")}
                            variant="outlined"
                            placeholder="Name"
                            InputProps={{ className: classes.input }}
                            InputLabelProps={{
                              className: classes.label,
                              shrink: true,
                            }}
                          />
                          <TextField
                            margin="dense"
                            id="outlined-name"
                            label="MAC Address"
                            value={MacId}
                            type="text"
                            disabled
                            style={{
                              width: "100%",
                              borderColor: "#4b2e83",
                              marginBottom: 13,
                            }}
                            onChange={this.handleChange("mac")}
                            variant="outlined"
                            placeholder="MAC Address"
                            InputProps={{ className: classes.input }}
                            InputLabelProps={{
                              className: classes.label,
                              shrink: true,
                            }}
                          />

                          <TextField
                            id="outlined-name"
                            label="Tag Code"
                            disabled
                            className={classes.textField}
                            value={TagCode}
                            type="text"
                            style={{
                              borderColor: "#4b2e83",
                              //marginBottom: 13,
                            }}
                            margin="dense"
                            onChange={this.handleChange("TagCode")}
                            variant="outlined"
                            placeholder="Tag Code"
                            InputProps={{ className: classes.input }}
                            InputLabelProps={{
                              className: classes.label,
                              shrink: true,
                            }}
                          />
                          {/* <TextField
                            id="outlined-name"
                            disabled={!this.state.isEditable}
                            label="Serial Number"
                            value={this.state.SerialNumber}
                            margin="dense"
                            type="text"
                            style={{
                              width: "100%",
                              borderColor: "#4b2e83",
                            }}
                            onChange={this.handleChange("SerialNumber")}
                            multiline
                            rows="1"
                            variant="outlined"
                            placeholder="Serial Number"
                            InputProps={{ className: classes.input }}
                            InputLabelProps={{
                              className: classes.label,
                              shrink: true,
                            }}
                          /> */}
                        </Paper>
                      </Grid>
                      <Grid
                        item
                        xs={10}
                        sm={10}
                        md={6}
                        lg={4}
                        style={{ paddingTop: 0 }}
                      >
                        <Paper
                          className={classes.root}
                          style={{ padding: 10, marginTop: 8 }}
                        >
                          <h5
                            style={{
                              color: "#4b2e83",
                              width: "100%",
                              paddingLeft: 2,
                            }}
                          >
                            Asset Details
                          </h5>
                          <hr />
                          {/* <TextField
                            id="outlined-name"
                            label="Tag Code"
                            disabled
                            className={classes.textField}
                            value={TagCode}
                            type="text"
                            style={{
                              borderColor: "#4b2e83",
                              marginBottom: 13,
                            }}
                            margin="dense"
                            onChange={this.handleChange("TagCode")}
                            variant="outlined"
                            placeholder="Tag Code"
                            InputProps={{ className: classes.input }}
                            InputLabelProps={{
                              className: classes.label,
                              shrink: true,
                            }}
                          /> */}
                          <div>
                            <input
                              disabled={!this.state.isEditable}
                              style={{ marginTop: "6px" }}
                              list="text_editors1"
                              className={classes.input1}
                              onChange={this.inputChange("Manufacturer")}
                              value={this.state.Manufacturer}
                              placeholder="Manufacturer"
                            />
                            <datalist id="text_editors1">
                              <select multiple>
                                {manufacturerData.map((user) => (
                                  <option key={user.id} value={user.name}>
                                    {user.name}
                                  </option>
                                ))}
                              </select>
                            </datalist>
                            <Tooltip title="Add Manufacturer">
                              <IconButton
                                disabled={!this.state.isEditable}
                                style={
                                  this.state.isEditable
                                    ? {
                                        color: "#4b2e83",
                                        opacity: "1",
                                        outline: "none",
                                      }
                                    : { color: "#343a40", opacity: "0.4" }
                                }
                                aria-label="Add Manufacturer"
                                onClick={() => this.handleAdd("Manufacturer")}
                              >
                                <AddIcon />
                              </IconButton>
                            </Tooltip>
                          </div>
                          <div>
                            <input
                              disabled={!this.state.isEditable}
                              style={{ marginTop: "13px" }}
                              list="text_editors2"
                              className={classes.input1}
                              onChange={this.inputChange("DeviceModel")}
                              type="text"
                              value={this.state.DeviceModel}
                              placeholder="Model"
                            />
                            <datalist id="text_editors2">
                              <select multiple>
                                {modelData.map((user) => (
                                  <option key={user.id} value={user.name}>
                                    {user.name}
                                  </option>
                                ))}
                              </select>
                            </datalist>
                            <Tooltip title="Add Model">
                              <IconButton
                                disabled={!this.state.isEditable}
                                style={
                                  this.state.isEditable
                                    ? {
                                        color: "#4b2e83",
                                        opacity: "1",
                                        outline: "none",
                                      }
                                    : { color: "#343a40", opacity: "0.4" }
                                }
                                aria-label="Add Model"
                                onClick={() => this.handleAdd("Model")}
                              >
                                <AddIcon />
                              </IconButton>
                            </Tooltip>
                          </div>
                          <div>
                            <input
                              disabled={!this.state.isEditable}
                              style={{ marginTop: "13px" }}
                              list="text_editors3"
                              className={classes.input1}
                              onChange={this.inputChange("DeviceType")}
                              value={this.state.DeviceType}
                              placeholder="Type"
                            />
                            <datalist id="text_editors3">
                              <select multiple>
                                {typeData.map((user) => (
                                  <option key={user.id} value={user.name}>
                                    {user.name}
                                  </option>
                                ))}
                              </select>
                            </datalist>
                            <Tooltip title="Add Type">
                              <IconButton
                                disabled={!this.state.isEditable}
                                style={
                                  this.state.isEditable
                                    ? {
                                        color: "#4b2e83",
                                        opacity: "1",
                                        outline: "none",
                                      }
                                    : { color: "#343a40", opacity: "0.4" }
                                }
                                aria-label="Add Type"
                                onClick={() => this.handleAdd("Type")}
                              >
                                <AddIcon />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </Paper>
                      </Grid>

                      <Grid
                        item
                        xs={10}
                        sm={10}
                        md={6}
                        lg={4}
                        style={{ paddingTop: 0 }}
                      >
                        <Paper
                          className={classes.root}
                          style={{ padding: 10, marginTop: 8 }}
                        >
                          <h5
                            style={{
                              color: "#4b2e83",
                              width: "100%",
                              paddingLeft: 2,
                            }}
                          >
                            Status
                          </h5>
                          <hr />

                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            <TextField
                              id="outlined-name"
                              label="Organization"
                              type="text"
                              disabled={true}
                              margin="dense"
                              value={EntityName}
                              style={{
                                width: "100%",
                                borderColor: "#4b2e83",
                                marginBottom: 13,
                                paddingRight: 2,
                              }}
                              onChange={this.handleChange("enitity")}
                              variant="outlined"
                              placeholder="Organization"
                              InputProps={{ className: classes.input }}
                              InputLabelProps={{
                                className: classes.label,
                                shrink: true,
                              }}
                            />
                            <Tooltip
                              className="float-right"
                              title="Remove asset from Organization"
                            >
                              <IconButton
                                onClick={() => {
                                  this.setState({
                                    actionType: "REMOVE FROM Organization",
                                    openAlert: true,
                                  });
                                }}
                                disabled={!this.state.showRemoveEntityButton}
                                style={
                                  this.state.showRemoveEntityButton
                                    ? {
                                        color: "#4b2e83",
                                        outline: "none",
                                        height: "50px",
                                        width: "50px",
                                        opacity: "1",
                                      }
                                    : {
                                        color: "#343a40",
                                        opacity: "0.4",
                                        height: "50px",
                                        width: "50px",
                                      }
                                }
                                aria-label="Remove asset from Organization"
                              >
                                <RemoveIcon />
                              </IconButton>
                            </Tooltip>
                          </div>

                          {/* <TextField
                            id="outlined-name"
                            label="Building"
                            disabled={true}
                            type="text"
                            margin="dense"
                            value={LocationName}
                            style={{
                              width: "100%",
                              borderColor: "#4b2e83",
                              marginBottom: 13,
                            }}
                            onChange={this.handleChange("location")}
                            variant="outlined"
                            placeholder="Location"
                            InputProps={{ className: classes.input }}
                            InputLabelProps={{
                              className: classes.label,
                              shrink: true,
                            }}
                          /> */}
                          {/* <TextField
                            id="outlined-name"
                            label="Meridian Building Name"
                            disabled={true}
                            type="text"
                            margin="dense"
                            value={MeridianBuildingName}
                            style={{
                              width: "100%",
                              borderColor: "#4b2e83",
                              marginBottom: 13,
                            }}
                            onChange={this.handleChange("meridianbuildingname")}
                            variant="outlined"
                            placeholder="Meridian Building Name"
                            InputProps={{ className: classes.input }}
                            InputLabelProps={{
                              className: classes.label,
                              shrink: true,
                            }}
                          /> */}
                          <TextField
                            id="outlined-name"
                            label="Department"
                            type="text"
                            margin="dense"
                            disabled={true}
                            value={
                              dept.length > 0 ? dept[0].CollectionName : ""
                            }
                            style={{
                              width: "100%",
                              borderColor: "#4b2e83",
                              marginBottom: 13,
                            }}
                            onChange={this.handleChange("department")}
                            variant="outlined"
                            placeholder="Department"
                            InputProps={{ className: classes.input }}
                            InputLabelProps={{
                              className: classes.label,
                              shrink: true,
                            }}
                          />
                          <TextField
                            id="outlined-name"
                            label="Device Flag"
                            disabled
                            value={
                              DeviceFlag == "NOT_MAPPED"
                                ? "NO DEPARTMENT"
                                : DeviceFlag
                            }
                            type="text"
                            style={{
                              width: "100%",
                              borderColor: "#4b2e83",
                            }}
                            margin="dense"
                            onChange={this.handleChange("Asset Flag")}
                            variant="outlined"
                            placeholder="Device Flag"
                            InputProps={{ className: classes.input }}
                            InputLabelProps={{
                              className: classes.label,
                              shrink: true,
                            }}
                          />
                        </Paper>
                      </Grid>
                    </Grid>
                  </div>

                  <Collapse
                    in={!this.state.showCollectionTable}
                    style={{ marginTop: 10 }}
                  >
                    <Paper style={{ padding: 15 }}>
                      <Grid container spacing={3}>
                        <Grid item xs={10} sm={6} md={8} lg={8}>
                          <Button
                            className="button3"
                            style={{
                              color: "#4b2e83",
                              paddingBottom: 10,
                            }}
                            onClick={() => {
                              this.setState({
                                showCollectionTable: true,
                                selectedCollection: "",
                              });
                            }}
                          >
                            <BackIcon /> Back to Collection List
                          </Button>
                        </Grid>
                        <Grid item xs={10} sm={3} md={3} lg={3}>
                          {selectedCollection.length > 0 ? (
                            <Link
                              disabled={!this.state.isEditable}
                              to={
                                this.state.isEditable
                                  ? this.state.selectedCollType ==
                                    "User Defined Collection"
                                    ? `/options/Modify/collection/${selectedCollection[0].CollectionId}?type=col`
                                    : `/options/Modify/collection/${selectedCollection[0].CollectionId}?type=dept`
                                  : "/devices/viewtable"
                              }
                            >
                              <p style={{ float: "right" }}>
                                Collection:{" "}
                                {selectedCollection.length > 0
                                  ? selectedCollection[0].CollectionName
                                  : ""}
                              </p>
                            </Link>
                          ) : null}
                        </Grid>
                      </Grid>
                      <Grid container spacing={16} style={{ marginLeft: 10 }}>
                        {selectedCollection.length > 0 ? (
                          <React.Fragment>
                            <h6
                              style={{
                                marginLeft: 10,
                                width: "100%",
                                marginTop: 4,
                              }}
                            >
                              List of devices assigned to{" "}
                              {selectedCollection.length > 0
                                ? selectedCollection[0].CollectionName
                                : ""}
                            </h6>
                            <table
                              style={{ width: "97%" }}
                              className="table table-striped coll-table table-bordered table-condensed"
                            >
                              <thead>
                                <tr>
                                  <th className={classes.tableHead}>IMAGE</th>
                                  <th className={classes.tableHead}>
                                    DEVICE NAME
                                  </th>
                                  {/* <th className={classes.tableHead}>
                                    DISPLAY NAME
                                  </th> */}
                                  <th className={classes.tableHead}>ACTION</th>
                                </tr>
                              </thead>
                              <tbody>
                                {selectedCollection.map((item) => (
                                  <tr>
                                    <td
                                      style={{
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                        verticalAlign: "middle",
                                      }}
                                    >
                                      <img
                                        className="image"
                                        style={{ borderRadius: "50%" }}
                                        src={item.ImageUrl ? item.ImageUrl : defaultImage}
                                        height={30}
                                        width={30}
                                      />
                                    </td>
                                    <td className={classes.tableRow}>
                                      {item.Name}
                                    </td>
                                    {/* <td className={classes.tableRow}>
                                      {item.DisplayName}
                                    </td> */}
                                    <td
                                      style={{
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        verticalAlign: "middle",
                                      }}
                                    >
                                      <Tooltip title="View Device">
                                        <IconButton
                                          aria-label="View Device"
                                          className={classes.icons}
                                          onClick={() => {
                                            this.setState(
                                              {
                                                MacId: item.MacId,
                                                showCollectionTable: true,
                                              },
                                              () => {
                                                this.fetchAssetData();
                                                this.handleScroll();
                                              }
                                            );
                                          }}
                                          style={{
                                            color: "#4b2e83",
                                            opacity: "1",
                                            outline: "none",
                                          }}
                                        >
                                          <ViewIcon fontSize="small" />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip title="Delete">
                                        <IconButton
                                          aria-label="Delete"
                                          className={classes.icons}
                                          hidden={!this.state.isEditable}
                                          onClick={() => {
                                            this.setState({
                                              deviceToDelete: item,
                                              openAlert: true,
                                              actionType:
                                                "REMOVE FROM COLLECTION",
                                            });
                                          }}
                                          style={{
                                            color: "#4b2e83",
                                            opacity: "1",
                                            outline: "none",
                                          }}
                                        >
                                          <DeleteIcon fontSize="small" />
                                        </IconButton>
                                      </Tooltip>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </React.Fragment>
                        ) : (
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: 30,
                            }}
                          >
                            <h5>No Access</h5>
                          </div>
                        )}
                      </Grid>
                    </Paper>
                  </Collapse>

                  <Collapse in={this.state.showCollectionTable}>
                    <Paper>
                      <CollectionsTable
                        collections={this.state.data.Collections}
                        refreshData={() => this.fetchAssetData()}
                        showCollectionDetail={(coll_id, coll_type) => {
                          this.showCollection(coll_id);
                          this.setState({
                            selectedCollType: coll_type,
                          });
                        }}
                        disableButton={this.state.isEditable}
                      />
                    </Paper>

                    <Grid container spacing={2} style={{ paddingTop: "10px" }}>
                      <Grid item xs={6} md={4} lg={2}>
                        <div
                          onClick={() =>
                            this.setState({ index: 0 }, () => {
                              if (this.state.meridiandata.map.id)
                                this.renderMap();
                            })
                          }
                          style={this.state.index == 0 ? styles[0] : styles[1]}
                        >
                          <h6>Current location</h6>
                        </div>
                      </Grid>
                      <Grid item xs={6} md={4} lg={2}>
                        <div
                          onClick={() =>
                            this.setState({ index: 1 }, () => {
                              if (
                                this.props.selectedAssetDetail.MeridianMapId &&
                                document.getElementById("meridian-map")
                              )
                                this.renderMap(
                                  this.props.selectedAssetDetail.MeridianMapId
                                );
                            })
                          }
                          style={this.state.index == 0 ? styles[1] : styles[0]}
                        >
                          <h6>Last known location</h6>
                        </div>
                      </Grid>
                      <Grid item xs={6} md={4} lg={8} style={{ textAlign: "end" }}>
                        <Tooltip title="See Fullscreen">
                          <IconButton
                            style={{padding: 0}}
                            onClick={() =>
                              this.setState({ isFullScreen: true }, () => {
                                setTimeout(() => {
                                  if (
                                    this.props.selectedAssetDetail
                                      .MeridianMapId &&
                                    document.getElementById("meridian-map-full")
                                  )
                                    this.renderMap(
                                      this.props.selectedAssetDetail.MeridianMapId
                                    );
                                }, 500);
                              })
                            }
                          >
                            <SvgIcon>
                              <path fill="none" stroke="#4b2e83" strokeWidth="2" d="M10,14 L2,22 M1,15 L1,23 L9,23 M22,2 L14,10 M15,1 L23,1 L23,9"></path>
                            </SvgIcon>
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>

                    <Paper>
                      {!this.state.hideMap ? (
                        <Paper
                          className={classes.root}
                          style={{ padding: 11, width: "100%" }}
                        >
                          {/* <Tabs
                            TabIndicatorProps={{ style: { background: "#4b2e83" } }}
                            value={this.state.index}
                            onChange={(event, index) => {
                              console.log("index", event, index);
                              this.setState({ index: index }, () =>
                                this.renderMap()
                              );
                            }}
                          >
                            <Tab index={0} label="Current location"></Tab>
                            <Tab index={1} label="Last known location"></Tab>
                          </Tabs> */}
                          {this.state.index == 0 &&
                            !this.state.meridiandata.map.id && (
                              <div
                                className={classes.root}
                                style={{
                                  width: "100%",
                                  height: "500px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  padding: 30,
                                }}
                              >
                                <h4>
                                  Currently the device is not traceable. Please
                                  check Last known location.
                                </h4>
                              </div>
                            )
                          }
                          {this.state.index == 0 &&
                            this.state.meridiandata.map.id && (
                              <div /*style={{ padding: "10px 0" }}*/>
                                <Grid container spacing={2}>
                                  <Grid item xs={3}>
                                    <TextField
                                      id="building-name"
                                      label="Campus"
                                      disabled={true}
                                      type="text"
                                      margin="dense"
                                      value={MeridianBuildingName}
                                      style={{
                                        width: "100%",
                                        borderColor: "#4b2e83",
                                        marginBottom: 13,
                                      }}
                                      variant="outlined"
                                      placeholder="Campus"
                                      InputProps={{ className: classes.input }}
                                      InputLabelProps={{
                                        className: classes.label,
                                        shrink: true,
                                      }}
                                    />
                                  </Grid>

                                  <Grid item xs={3}>
                                    <TextField
                                      id="floor"
                                      label="Floor"
                                      disabled={true}
                                      type="text"
                                      margin="dense"
                                      value={
                                        this.state.meridiandata.map.level_label
                                      }
                                      style={{
                                        width: "100%",
                                        borderColor: "#4b2e83",
                                        marginBottom: 13,
                                      }}
                                      variant="outlined"
                                      placeholder="Floor"
                                      InputProps={{ className: classes.input }}
                                      InputLabelProps={{
                                        className: classes.label,
                                        shrink: true,
                                      }}
                                    />
                                  </Grid>

                                  <Grid item xs={3}>
                                    <TextField
                                      margin="dense"
                                      id="outlined-name"
                                      label="Zone"
                                      value={this.state.currentZone}
                                      type="text"
                                      disabled
                                      style={{
                                        width: "100%",
                                        borderColor: "#4b2e83",
                                        //marginBottom: 13,
                                      }}
                                      variant="outlined"
                                      placeholder="Zone"
                                      InputProps={{ className: classes.input }}
                                      InputLabelProps={{
                                        className: classes.label,
                                        shrink: true,
                                      }}
                                    />
                                  </Grid>

                                  <Grid item xs={3}>
                                    <div
                                      style={{
                                        //padding: "10px 10px 0px 10px",
                                        display: "flex",
                                        width: "100%",
                                      }}
                                    >
                                      <TextField
                                        id="sync"
                                        disabled
                                        label="Last Heard"
                                        className={classes.textField}
                                        value={this.state.syncTime}
                                        type="text"
                                        style={{
                                          borderColor: "#4b2e83",
                                          marginBottom: 13,
                                        }}
                                        margin="dense"
                                        variant="outlined"
                                        placeholder="Last Heard"
                                        //placeholder={this.props.selectedAssetDetail.DeviceFlag !== "NOT_TRACEABLE" ?"Sync Time" : ""}
                                        InputProps={{
                                          className: classes.input,
                                        }}
                                        InputLabelProps={{
                                          className: classes.label,
                                          shrink: true,
                                        }}
                                      />
                                      <Tooltip
                                        title="Sync"
                                        className="float-right"
                                      >
                                        <IconButton
                                          style={{
                                            color: "#4b2e83",
                                            opacity: "1",
                                            outline: "none",
                                          }}
                                          disabled={
                                            this.state.isCurrentlySyncing
                                          }
                                          aria-label="Sync"
                                          onClick={() =>
                                            this.setState(
                                              { isCurrentlySyncing: true },
                                              () =>
                                                this.getAssetFromMeridian(
                                                  this.props.selectedAssetDetail
                                                    .MeridianLocationId,
                                                  this.props.selectedAssetDetail
                                                    .MacId
                                                )
                                            )
                                          }
                                        >
                                          <RefreshIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </div>
                                  </Grid>
                                </Grid>
                                <div className="justify-content-between align-items-center">
                                  {/* <h6 style={{ width: "100%", paddingLeft: 2 }}>
                                    Floor  {FloorNumber}
                                    {this.state.meridiandata.map.level_label}
                                  </h6> */}
                                  <div className="map" id="meridian-map" />
                                </div>
                              </div>
                            )
                          }
                          {this.state.index !== 0 && (
                            <div /*style={{ padding: "10px 0" }}*/>
                              <Grid container spacing={2}>
                                <Grid item xs={3}>
                                  <TextField
                                    id="building-name"
                                    label="Campus"
                                    disabled={true}
                                    type="text"
                                    margin="dense"
                                    value={MeridianBuildingName}
                                    style={{
                                      width: "100%",
                                      borderColor: "#4b2e83",
                                      marginBottom: 13,
                                    }}
                                    variant="outlined"
                                    placeholder="Campus"
                                    InputProps={{ className: classes.input }}
                                    InputLabelProps={{
                                      className: classes.label,
                                      shrink: true,
                                    }}
                                  />
                                </Grid>

                                <Grid item xs={3}>
                                  <TextField
                                    id="floor"
                                    label="Floor"
                                    disabled={true}
                                    type="text"
                                    margin="dense"
                                    value={FloorLabel}
                                    style={{
                                      width: "100%",
                                      borderColor: "#4b2e83",
                                      marginBottom: 13,
                                    }}
                                    variant="outlined"
                                    placeholder="Floor"
                                    InputProps={{ className: classes.input }}
                                    InputLabelProps={{
                                      className: classes.label,
                                      shrink: true,
                                    }}
                                  />
                                </Grid>

                                <Grid item xs={3}>
                                  <TextField
                                    margin="dense"
                                    id="outlined-name"
                                    label="Zone"
                                    value={
                                      this.props.selectedAssetDetail.ZoneName
                                        ? this.props.selectedAssetDetail
                                            .ZoneName
                                        : "No Zone"
                                    }
                                    type="text"
                                    disabled
                                    style={{
                                      width: "100%",
                                      borderColor: "#4b2e83",
                                      //marginBottom: 13,
                                    }}
                                    variant="outlined"
                                    placeholder="Zone"
                                    InputProps={{ className: classes.input }}
                                    InputLabelProps={{
                                      className: classes.label,
                                      shrink: true,
                                    }}
                                  />
                                </Grid>

                                <Grid item xs={3}>
                                  <TextField
                                    id="lastheard"
                                    label="Last Heard"
                                    disabled={true}
                                    type="text"
                                    margin="dense"
                                    // value={this.props.lastSync.toString()}
                                    //value={lastKnownSync}
                                    value={
                                      /*his.lastKnownModified*/ this.state
                                        .syncTime
                                    }
                                    style={{
                                      width: "100%",
                                      borderColor: "#4b2e83",
                                      marginBottom: 13,
                                    }}
                                    variant="outlined"
                                    placeholder="Last Heard"
                                    InputProps={{ className: classes.input }}
                                    InputLabelProps={{
                                      className: classes.label,
                                      shrink: true,
                                    }}
                                  />
                                </Grid>
                              </Grid>
                              <div className="justify-content-between align-items-center">
                                {/* <h6 style={{ width: "100%", paddingLeft: 2 }}>
                                  {/* Floor  {FloorNumber}*
                                  {this.props.selectedAssetDetail.FloorNumber}
                                </h6> */}
                                <div className="map" id="meridian-map" />
                              </div>
                            </div>
                          )}
                        </Paper>
                      ) : (
                        <div
                          className={classes.root}
                          style={{
                            width: "100%",
                            height: "500px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: 30,
                          }}
                        >
                          <h4>There's no map uploaded for this Device.</h4>
                        </div>
                      )}
                    </Paper>
                  </Collapse>

                  <Dialog fullScreen open={this.state.isFullScreen}>
                    <Collapse in={this.state.isFullScreen}>
                      <Grid
                        container
                        spacing={2}
                        style={{ paddingTop: "10px", width: "100%" }}
                      >
                        <Grid item xs={6} md={4} lg={2}>
                          <div
                            onClick={() =>
                              this.setState({ index: 0 }, () => {
                                if (this.state.meridiandata.map.id)
                                  this.renderMap();
                              })
                            }
                            style={
                              this.state.index == 0 ? styles[0] : styles[1]
                            }
                          >
                            <h6>Current location</h6>
                          </div>
                        </Grid>
                        <Grid item xs={6} md={4} lg={2}>
                          <div
                            onClick={() =>
                              this.setState({ index: 1 }, () => {
                                if (
                                  this.props.selectedAssetDetail
                                    .MeridianMapId &&
                                  document.getElementById("meridian-map")
                                )
                                  this.renderMap(
                                    this.props.selectedAssetDetail.MeridianMapId
                                  );
                              })
                            }
                            style={
                              this.state.index == 0 ? styles[1] : styles[0]
                            }
                          >
                            <h6>Last known location</h6>
                          </div>
                        </Grid>
                        <Grid item xs={6} md={4} lg={8} style={{ textAlign: "end" }}>
                          <Tooltip title="Close Fullscreen">
                            <IconButton
                              style={{padding: 0}}
                              onClick={() =>
                                this.setState({ isFullScreen: false }, () => {
                                  setTimeout(() => {
                                    if (
                                      this.props.selectedAssetDetail
                                        .MeridianMapId &&
                                      document.getElementById("meridian-map")
                                    )
                                      this.renderMap(
                                        this.props.selectedAssetDetail.MeridianMapId
                                      );
                                  }, 500);
                                })
                              }
                            >
                              <SvgIcon>
                                <path
                                  fill="none"
                                  stroke="#4b2e83"
                                  strokeWidth="2"
                                  d="M2,14 L10,14 L10,22 M1,23 L10,14 M23,1 L14,10 M22,10 L14,10 L14,2"
                                ></path>
                              </SvgIcon>
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>

                      <Paper>
                        {!this.state.hideMap ? (
                          <Paper
                            className={classes.root}
                            style={{ padding: 11, width: "100%" }}
                          >
                            {/* <Tabs
                              TabIndicatorProps={{ style: { background: "#4b2e83" } }}
                              value={this.state.index}
                              onChange={(event, index) => {
                                console.log("index", event, index);
                                this.setState({ index: index }, () =>
                                  this.renderMap()
                                );
                              }}
                            >
                              <Tab index={0} label="Current location"></Tab>
                              <Tab index={1} label="Last known location"></Tab>
                            </Tabs> */}
                            {this.state.index == 0 &&
                              !this.state.meridiandata.map.id && (
                                <div
                                  className={classes.root}
                                  style={{
                                    width: "100%",
                                    height: "calc(100vh - 145px)",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: 30,
                                  }}
                                >
                                  <h4>
                                    Currently the device is not traceable.
                                    Please check Last known location.
                                  </h4>
                                </div>
                              )
                            }
                            {this.state.index == 0 &&
                              this.state.meridiandata.map.id && (
                                <div /*style={{ padding: "10px 0" }}*/>
                                  <Grid container spacing={2}>
                                    <Grid item xs={3}>
                                      <TextField
                                        id="building-name"
                                        label="Campus"
                                        disabled={true}
                                        type="text"
                                        margin="dense"
                                        value={MeridianBuildingName}
                                        style={{
                                          width: "100%",
                                          borderColor: "#4b2e83",
                                          marginBottom: 13,
                                        }}
                                        variant="outlined"
                                        placeholder="Campus"
                                        InputProps={{
                                          className: classes.input,
                                        }}
                                        InputLabelProps={{
                                          className: classes.label,
                                          shrink: true,
                                        }}
                                      />
                                    </Grid>

                                    <Grid item xs={3}>
                                      <TextField
                                        id="floor"
                                        label="Floor"
                                        disabled={true}
                                        type="text"
                                        margin="dense"
                                        value={
                                          this.state.meridiandata.map
                                            .level_label
                                        }
                                        style={{
                                          width: "100%",
                                          borderColor: "#4b2e83",
                                          marginBottom: 13,
                                        }}
                                        variant="outlined"
                                        placeholder="Floor"
                                        InputProps={{
                                          className: classes.input,
                                        }}
                                        InputLabelProps={{
                                          className: classes.label,
                                          shrink: true,
                                        }}
                                      />
                                    </Grid>

                                    <Grid item xs={3}>
                                      <TextField
                                        margin="dense"
                                        id="outlined-name"
                                        label="Zone"
                                        value={this.state.currentZone}
                                        type="text"
                                        disabled
                                        style={{
                                          width: "100%",
                                          borderColor: "#4b2e83",
                                          //marginBottom: 13,
                                        }}
                                        variant="outlined"
                                        placeholder="Zone"
                                        InputProps={{
                                          className: classes.input,
                                        }}
                                        InputLabelProps={{
                                          className: classes.label,
                                          shrink: true,
                                        }}
                                      />
                                    </Grid>

                                    <Grid item xs={3}>
                                      <div
                                        style={{
                                          //padding: "10px 10px 0px 10px",
                                          display: "flex",
                                          width: "100%",
                                        }}
                                      >
                                        <TextField
                                          id="sync"
                                          disabled
                                          label="Last Heard"
                                          className={classes.textField}
                                          value={this.state.syncTime}
                                          type="text"
                                          style={{
                                            borderColor: "#4b2e83",
                                            marginBottom: 13,
                                          }}
                                          margin="dense"
                                          variant="outlined"
                                          placeholder="Last Heard"
                                          //placeholder={this.props.selectedAssetDetail.DeviceFlag !== "NOT_TRACEABLE" ?"Sync Time" : ""}
                                          InputProps={{
                                            className: classes.input,
                                          }}
                                          InputLabelProps={{
                                            className: classes.label,
                                            shrink: true,
                                          }}
                                        />
                                        <Tooltip
                                          title="Sync"
                                          className="float-right"
                                        >
                                          <IconButton
                                            style={{
                                              color: "#4b2e83",
                                              opacity: "1",
                                              outline: "none",
                                            }}
                                            disabled={
                                              this.state.isCurrentlySyncing
                                            }
                                            aria-label="Sync"
                                            onClick={() =>
                                              this.setState(
                                                { isCurrentlySyncing: true },
                                                () =>
                                                  this.getAssetFromMeridian(
                                                    this.props
                                                      .selectedAssetDetail
                                                      .MeridianLocationId,
                                                    this.props
                                                      .selectedAssetDetail.MacId
                                                  )
                                              )
                                            }
                                          >
                                            <RefreshIcon />
                                          </IconButton>
                                        </Tooltip>
                                      </div>
                                    </Grid>
                                  </Grid>
                                  <div className="justify-content-between align-items-center">
                                    {/* <h6 style={{ width: "100%", paddingLeft: 2 }}>
                                      Floor  {FloorNumber}
                                      {this.state.meridiandata.map.level_label}
                                    </h6> */}
                                    <div
                                      className="map"
                                      id="meridian-map-full"
                                    />
                                  </div>
                                </div>
                              )
                            }
                            {this.state.index !== 0 && (
                              <div /*style={{ padding: "10px 0" }}*/>
                                <Grid container spacing={2}>
                                  <Grid item xs={3}>
                                    <TextField
                                      id="building-name"
                                      label="Campus"
                                      disabled={true}
                                      type="text"
                                      margin="dense"
                                      value={MeridianBuildingName}
                                      style={{
                                        width: "100%",
                                        borderColor: "#4b2e83",
                                        marginBottom: 13,
                                      }}
                                      variant="outlined"
                                      placeholder="Campus"
                                      InputProps={{ className: classes.input }}
                                      InputLabelProps={{
                                        className: classes.label,
                                        shrink: true,
                                      }}
                                    />
                                  </Grid>

                                  <Grid item xs={3}>
                                    <TextField
                                      id="floor"
                                      label="Floor"
                                      disabled={true}
                                      type="text"
                                      margin="dense"
                                      value={FloorLabel}
                                      style={{
                                        width: "100%",
                                        borderColor: "#4b2e83",
                                        marginBottom: 13,
                                      }}
                                      variant="outlined"
                                      placeholder="Floor"
                                      InputProps={{ className: classes.input }}
                                      InputLabelProps={{
                                        className: classes.label,
                                        shrink: true,
                                      }}
                                    />
                                  </Grid>

                                  <Grid item xs={3}>
                                    <TextField
                                      margin="dense"
                                      id="outlined-name"
                                      label="Zone"
                                      value={
                                        this.props.selectedAssetDetail.ZoneName
                                          ? this.props.selectedAssetDetail
                                              .ZoneName
                                          : "No Zone"
                                      }
                                      type="text"
                                      disabled
                                      style={{
                                        width: "100%",
                                        borderColor: "#4b2e83",
                                        //marginBottom: 13,
                                      }}
                                      variant="outlined"
                                      placeholder="Zone"
                                      InputProps={{ className: classes.input }}
                                      InputLabelProps={{
                                        className: classes.label,
                                        shrink: true,
                                      }}
                                    />
                                  </Grid>

                                  <Grid item xs={3}>
                                    <TextField
                                      id="lastheard"
                                      label="Last Heard"
                                      disabled={true}
                                      type="text"
                                      margin="dense"
                                      // value={this.props.lastSync.toString()}
                                      //value={lastKnownSync}
                                      value={
                                        /*his.lastKnownModified*/ this.state
                                          .syncTime
                                      }
                                      style={{
                                        width: "100%",
                                        borderColor: "#4b2e83",
                                        marginBottom: 13,
                                      }}
                                      variant="outlined"
                                      placeholder="Last Heard"
                                      InputProps={{ className: classes.input }}
                                      InputLabelProps={{
                                        className: classes.label,
                                        shrink: true,
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                                <div className="justify-content-between align-items-center">
                                  {/* <h6 style={{ width: "100%", paddingLeft: 2 }}>
                                    {/* Floor  {FloorNumber}*
                                    {this.props.selectedAssetDetail.FloorNumber}
                                  </h6> */}
                                  <div className="map" id="meridian-map-full" />
                                </div>
                              </div>
                            )}
                          </Paper>
                        ) : (
                          <div
                            className={classes.root}
                            style={{
                              width: "100%",
                              height: "500px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: 30,
                            }}
                          >
                            <h4>There's no map uploaded for this Device.</h4>
                          </div>
                        )}
                      </Paper>
                    </Collapse>
                  </Dialog>
                </div>
              </React.Fragment>
            )}
          </div>
        ) : (
          <div
            style={{
              width: "100%",
              marginTop: 100,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress
              style={{ width: "80px", height: "80px" }}
              className={classes.progress}
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { entities, collections, locations, assets, deviceFlags } = state;
  return {
    entities,
    collections,
    locations,
    assets,
    deviceFlags
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getEntities: () => getEntity(dispatch),
    getLocation: entityId => getLocations(dispatch, entityId),
    getCollection: (entityId, locationId) => getCollections(dispatch, entityId, locationId),
    getAsset: () => getAssets(dispatch),
    getFlags: () => getDeviceFlags(dispatch),
    resetData: () => dispatch(cleanData()),
    showMessageBox: message => dispatch(showMessage(message))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(Configure);
