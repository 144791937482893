import React from "react";
import classNames from "classnames";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import IconButton from "@material-ui/core/IconButton";
import { apiRequest } from "../utils/Utils";
import { showMessage } from "../actions/index";
import { connect } from "react-redux";
import { compose } from "recompose";
import AlertDialog from "./AlertDialog";
import CircularProgress from "@material-ui/core/CircularProgress";

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const tableHeader = [
  { id: "0", label: "Image" },
  { id: "1", label: "User Id" },
  { id: "2", label: "Name" },
  { id: "3", label: "Action" }
];

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 2
  },
  rootcheckbox: {
    color: "#4b2e83",
    "&$checked": {
      color: "#4b2e83"
    }
  },
  table: { marginTop: 10 },
  tableWrapper: {
    overflowX: "auto"
  },
  button: {
    color: "#4b2e83",
    height: "40px",
    padding: "0px !important",
    marginRight: "18px",
    width: "90px"
  },
  tableHead: {
    fontWeight: 400,
    fontSize: 14,
    fontWeight: 500,
    fontSize: "0.75rem",
    color: "rgba(0, 0, 0, 0.54)"
  },
  tableRow: {
    paddingTop: "0px !important",
    paddingBottom: "0px !important",
    verticalAlign: "middle !important",
    fontWeight: 400,
    fontSize: "0.8125rem",
    color: "rgba(0, 0, 0, 0.87)"
  }
});

class EnhancedTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: "desc",
      orderBy: "sno",
      selected: [],
      MappedUserData: props.data,
      page: 0,
      rowsPerPage: 1,
      deptId: props.deptId,
      hideControls: true,
      memberToDelete: "",
      openAlert: false,
      showLoader: false
    };
  }

  componentDidMount() {
    this.checkAccess();
  }

  componentWillReceiveProps({ data, deptId }) {
    this.setState({
      MappedUserData: data,
      deptId: deptId
    });
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState(state => ({ selected: state.data.map(n => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  handleDelete = () => {
    this.setState({
      openAlert: false,
      showLoader: true
    });
    apiRequest(`/accesscontrol/${this.state.memberToDelete}`, "delete")
      .then(x => {
        if (x.status > 0 && x.message == null) {
          this.setState({
            showLoader: false
          });
          this.props.fetchUsers();
          this.props.showMessageBox("SUCCESS");
        }
        else
          this.props.showMessageBox(x.message);
      })
      .catch(err => console.log(err));
  };

  checkAccess() {
    apiRequest(
      `/accesscontrol?resourceType=collection&resourceId=${this.state.deptId}`,
      "get"
    )
      .then(response => {
        if (response.data[0] <= 300) {
          this.setState({
            hideControls: true
          });
        } else {
          this.setState({
            hideControls: false
          });
        }
      })
      .catch(error => console.log("error", error));
  }

  render() {
    const { classes } = this.props;
    const tableHead = (
      <thead>
        <tr>
          {tableHeader.map(row => (
            <th className={classes.tableHead} key={row.id}>
              {row.label}
            </th>
          ))}
        </tr>
      </thead>
    );
    return (
      <div>
        <AlertDialog
          open={this.state.openAlert}
          onClickYes={this.handleDelete}
          action={"Remove"}
          close={() => {
            this.setState({ openAlert: false });
          }}
        />
        <Paper
          className={classes.root}
          style={{ padding: 10, height: "100%", marginBottom: 10 }}
        >
          <div className="border-bottom">
            <h4
              style={{
                color: "#4b2e83",
                width: "100%",
                paddingLeft: 14
              }}
            >
              Already Assigned Users
            </h4>
          </div>

          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              Administrator
            </ExpansionPanelSummary>
            <ExpansionPanelDetails style={{ display: "block" }}>
              <table
                style={{ marginBottom: 0 }}
                className="table table-striped coll-table table-bordered table-condensed"
              >
                {tableHead}
                <tbody>
                  {this.state.MappedUserData.filter(
                    item => item.AccessLevel === 500
                  ).map(row => (
                    <tr key={row.MembershipId}>
                      <td
                        style={{
                          paddingTop: 0,
                          paddingBottom: 0,
                          verticalAlign: "middle"
                        }}
                      >
                        <img className="image" src={row.ImageUrl} height="27px" width="27px" />
                      </td>
                      <td className={classes.tableRow}>{row.UserName}</td>
                      <td className={classes.tableRow}>{row.FullName}</td>
                      <td>
                        {!this.state.showLoader ? (
                          <Tooltip title="Delete">
                            <IconButton
                              aria-label="Delete"
                              className={classes.icons}
                              onClick={() => {
                                this.setState({
                                  memberToDelete: row.MembershipId,
                                  openAlert: true
                                });
                              }}
                              disabled={this.state.hideControls}
                              style={{ padding: 0 }}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <CircularProgress
                            style={{ width: "20px", height: "20px" }}
                            className={classes.progress}
                          />
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              Read Only
            </ExpansionPanelSummary>
            <ExpansionPanelDetails style={{ display: "block" }}>
              <table
                style={{ marginBottom: 0 }}
                className="table table-striped coll-table table-bordered table-condensed"
              >
                {tableHead}
                <tbody>
                  {this.state.MappedUserData.filter(
                    item => item.AccessLevel === 100
                  ).map(row => (
                    <tr key={row.MembershipId}>
                      <td
                        style={{
                          paddingTop: 0,
                          paddingBottom: 0,
                          verticalAlign: "middle"
                        }}
                      >
                        <img className="image" src={row.ImageUrl} height="27px" width="27px" />
                      </td>
                      <td className={classes.tableRow}>{row.UserName}</td>
                      <td className={classes.tableRow}>{row.FullName}</td>
                      <td>
                        {!this.state.showLoader ? (
                          <Tooltip title="Delete">
                            <IconButton
                              aria-label="Delete"
                              className={classes.icons}
                              onClick={() => {
                                this.setState({
                                  memberToDelete: row.MembershipId,
                                  openAlert: true
                                });
                              }}
                              style={{ padding: 0 }}
                            >
                              <DeleteIcon
                                style={{ color: "#4b2e83" }}
                                fontSize="small"
                              />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <CircularProgress
                            style={{ width: "20px", height: "20px" }}
                            className={classes.progress}
                          />
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    showMessageBox: message => dispatch(showMessage(message))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(EnhancedTable);
