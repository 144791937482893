import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import "./Navbar.css";
//import logo from "../images/UWMedicineNavbarLogo2.png";
import HomeIcons from "@material-ui/icons/Home";
import DevicesIcon from "@material-ui/icons/Apps";
import SettingsIcon from "@material-ui/icons/Settings";
import MapIcon from "@material-ui/icons/Map";
import SearchIcon from "@material-ui/icons/Search";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import HelpOutline from "@material-ui/icons/HelpOutline";
import Slide from "@material-ui/core/Slide";
import Domain from "@material-ui/icons/Domain";
import ManageUsersIcon from "@material-ui/icons/People";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";
import LogOutIcon from "@material-ui/icons/ExitToApp";
import Tooltip from "@material-ui/core/Tooltip";
import DynamicFeedIcon from "@material-ui/icons/DynamicFeed";
import MenuItem from "@material-ui/core/MenuItem";
import { compose } from "recompose";
import { connect } from "react-redux";
import Cookies from "js-cookie";
import { apiRequest } from "../utils/Utils";
import {
  getMaxDepartmentAccess,
  getMaxEntityAccess,
  getMaxLocationAccess,
  storeEntityId,
  storelocationId,
  setAccess,
} from "../utils/Utils";
import { getEntity, getLocations, getCollections } from "../actions/index";
import { Paper, TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Moment from "react-moment";
import Grid from "@material-ui/core/Grid";
import Menu from "@material-ui/core/Menu";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import Linkify from 'react-linkify';
// AUTH
import { MsalContext, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";

const styles = (theme) => ({
  root: {
    width: "100%",
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",

    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing.unit,
      width: "auto",
    },
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "#4b2e83",
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 300,
      "&:focus": {
        width: 400,
      },
    },
  },
  notificationContainer: {
    position: "absolute",
    width: "200px",
    right: "140px",
    top: "45px",
  },

  paper: {
    height: "250px",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "5px",
      marginLeft: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "darkgrey",
      outline: "1px solid slategrey",
      borderRadius: "12px",
    },
  },
});
const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

class Navbar extends Component {
  static contextType = MsalContext;
  state = {
    id: this.props.id,
    hidesearch: false,
    collapsesidebar: true,
    open1: this.props.location.pathname.includes("users") ? true : false,
    open2: false,
    open3:
      this.props.location.pathname.includes("master") ||
      this.props.location.pathname.includes("options") ||//&&
        //!this.props.location.pathname.includes("users")) ||
      this.props.location.pathname.includes("devices/map") ||
      this.props.location.pathname.includes("log/")
        ? true
        : false,
    devices_class: "",
    link1: "/devices/viewtable",
    link2: "",
    notificationcount: 0,
    anchorEl: null,
    searchText: "",
    noAccess: false,
    administrator: true,
    entityAdmin: true,
    entityEditor: true,
    locAdmin: true,
    locEditor: true,
    deptAdmin: true,
    deptEditor: true,
    syncData: {},
    entityData: [],


    alerts: [],
    isNotDisMissedByUser: true

  };
  componentWillMount() {
    console.log("cwm");
    this.props.getEntities();
    // if (sessionStorage.getItem("entityID")) {
    //   //Get Locations of the selected entity
    //   this.props.getLocation(sessionStorage.getItem("entityID"));
    //   //Get Collections of the selected entity
    //   //If location is selected then apply it as a param
    //   if (sessionStorage.getItem("LocationID")) {
    //     //Get Department
    //     this.props.getCollection(sessionStorage.getItem("entityID"), sessionStorage.getItem("LocationID"));
    //   }
    //   else {
    //     //Get Collection
    //     this.props.getCollection(sessionStorage.getItem("entityID"));
    //   }
    // }
  }
  componentWillReceiveProps({ entities, isActiveAlertChanged, change }) {
    if (isActiveAlertChanged) {
      change(false);
      apiRequest(`/masters/alert`, "get")
      .then(response => {
        //Check alert cookie
        let activeAlert = response.data.find(a => a.IsActive);
        let notDismiss = true;
        let n = "alert";
        var match = document.cookie.match(new RegExp('(^| )' + n + '=([^;]+)'));
        if (match) {
          let obj = JSON.parse(match[2]);
          if (obj) {
            if (activeAlert && activeAlert.Id == obj.Id && (activeAlert.Updated == null || activeAlert.Updated == obj.Updated)) {
              notDismiss = false;
            } else {
              //other alert was dismissed
              //delete the old
              var today = new Date();
              var expired = new Date(today.getTime() - 24 * 3600 * 1000); // less 24 hours
              document.cookie = n + "=null; path=/; expires=" + expired.toGMTString();
              //save the new
              // var today = new Date();
              // var expiry = new Date(today.getTime() + 30 * 24 * 3600 * 1000); // plus 30 days
              // let kv = JSON.stringify(obj);
              // document.cookie = "alert=" + kv + "; path=/; expires=" + expiry.toGMTString();
            }
          }
        } else {
          //dont have cookie
        }
        this.setState({ alerts: response.data, isNotDisMissedByUser: notDismiss }, () => {
          if (activeAlert && notDismiss)
            this.props.changeHasActiveAlert(true);
          else
            this.props.changeHasActiveAlert(false);
        });
      });
    }
    this.setState({
      entityData: entities,
    });
  }

  componentWillUnmount() {
    window.removeEventListener("focus", this.onFocus);
  }

  onFocus = () => {
    if (this.name != localStorage.getItem("user")) {
      this.name = localStorage.getItem("user");
      apiRequest(`/accesscontrol`, "get").then((x) => {
        setAccess(x.data);
        if (x.data.length == 0) {
          this.setState({
            noAccess: true,
          });
        } else {
          this.setState({
            noAccess: false,
          });
        }
        this.setUpSideBar();
      });
    }
  };

  componentDidMount() {
    console.log("cdm");
    this.name = localStorage.getItem("user");
    window.addEventListener("focus", this.onFocus);

    this.getSyncData();

    setInterval(() => {
      this.getSyncData();
    }, 120000);

    apiRequest(`/accesscontrol`, "get").then((x) => {

      //console.log("sidebar - access", x);

      if (x.data.length == 0) {
        this.setState({
          noAccess: true,
        });
      } else {
        this.setState({
          noAccess: false,
        });
      }
    });

    this.setUpSideBar();

    this.handleExpand();

    apiRequest(`/masters/alert`, "get")
      .then(response => {
        //Check alert cookie
        let activeAlert = response.data.find(a => a.IsActive);
        let notDismiss = true;
        let n = "alert";
        var match = document.cookie.match(new RegExp('(^| )' + n + '=([^;]+)'));
        if (match) {
          let obj = JSON.parse(match[2]);
          if (obj) {
            if (activeAlert && activeAlert.Id == obj.Id && (activeAlert.Updated == null || activeAlert.Updated == obj.Updated)) {
              notDismiss = false;
            } else {
              //other alert was dismissed
              //delete the old
              var today = new Date();
              var expired = new Date(today.getTime() - 24 * 3600 * 1000); // less 24 hours
              document.cookie = n + "=null; path=/; expires=" + expired.toGMTString();
              //save the new
              // var today = new Date();
              // var expiry = new Date(today.getTime() + 30 * 24 * 3600 * 1000); // plus 30 days
              // let kv = JSON.stringify(obj);
              // document.cookie = "alert=" + kv + "; path=/; expires=" + expiry.toGMTString();
            }
          }
        }
        else {
          //dont have cookie
        }
        this.setState({ alerts: response.data, isNotDisMissedByUser: notDismiss }, () => {
          if (activeAlert && notDismiss)
            this.props.changeHasActiveAlert(true);
          else
            this.props.changeHasActiveAlert(false);
        });
      });
  }

  setUpSideBar() {
    if (getMaxEntityAccess() == 700) {
      this.setState({
        entityAdmin: false,
        locAdmin: false,
        deptAdmin: false,
        administrator: false,
      });
    }

    //entity
    else if (getMaxEntityAccess() == 500) {
      this.setState({
        administrator: true,
      });
      if (getMaxLocationAccess() == 500) {
        if (getMaxDepartmentAccess() == 500) {
          this.setState({
            entityAdmin: false,
            entityEditor: true,
            locAdmin: false,
            locEditor: true,
            deptAdmin: false,
            deptEditor: true,
          });
        } else if (getMaxDepartmentAccess() == 300) {
          this.setState({
            entityAdmin: false,
            entityEditor: true,
            locAdmin: false,
            locEditor: true,
            deptAdmin: false,
            deptEditor: true,
          });
        } else if (getMaxDepartmentAccess() == 100) {
          this.setState({
            entityAdmin: false,
            entityEditor: true,
            locAdmin: false,
            locEditor: true,
            deptAdmin: true,
            deptEditor: true,
          });
        }
        this.setState({
          entityAdmin: false,
          entityEditor: true,
          locAdmin: false,
          locEditor: true,
          deptAdmin: false,
          deptEditor: true,
        });
      } else if (getMaxLocationAccess() == 300) {
        this.setState({
          entityAdmin: false,
          entityEditor: true,
          locAdmin: true,
          locEditor: false,
          deptAdmin: false,
          deptEditor: false,
        });
        if (getMaxDepartmentAccess() == 500) {
          this.setState({
            entityAdmin: false,
            entityEditor: true,
            locAdmin: true,
            locEditor: false,
            deptAdmin: false,
            deptEditor: true,
          });
        } else if (getMaxDepartmentAccess() == 300) {
          this.setState({
            entityAdmin: false,
            entityEditor: true,
            locAdmin: true,
            locEditor: false,
            deptAdmin: false,
            deptEditor: false,
          });
        } else if (getMaxDepartmentAccess() == 100) {
          this.setState({
            entityAdmin: false,
            entityEditor: true,
            locAdmin: true,
            locEditor: false,
            deptAdmin: true,
            deptEditor: true,
          });
        }
      } else if (getMaxLocationAccess() == 100) {
        this.setState({
          entityAdmin: false,
          entityEditor: true,
          locAdmin: true,
          locEditor: true,
          deptAdmin: true,
          deptEditor: true,
        });
        if (getMaxDepartmentAccess() == 500) {
          this.setState({
            entityAdmin: false,
            entityEditor: true,
            locAdmin: true,
            locEditor: true,
            deptAdmin: false,
            deptEditor: true,
          });
        } else if (getMaxDepartmentAccess() == 300) {
          this.setState({
            entityAdmin: false,
            entityEditor: true,
            locAdmin: true,
            locEditor: true,
            deptAdmin: false,
            deptEditor: false,
          });
        } else if (getMaxDepartmentAccess() == 100) {
          this.setState({
            entityAdmin: false,
            entityEditor: true,
            locAdmin: true,
            locEditor: true,
            deptAdmin: true,
            deptEditor: true,
          });
        }
      }
      this.setState({
        entityAdmin: false,
        entityEditor: true,
        locAdmin: false,
        locEditor: true,
        deptAdmin: false,
        deptEditor: true,
      });
    }

    //entity
    else if (getMaxEntityAccess() == 300) {
      this.setState({
        entityAdmin: true,
        entityEditor: false,
        locAdmin: true,
        locEditor: false,
        deptAdmin: false,
        deptEditor: false,
      });
      if (getMaxLocationAccess() == 500) {
        if (getMaxDepartmentAccess() == 500) {
          this.setState({
            entityAdmin: true,
            entityEditor: false,
            locAdmin: false,
            locEditor: true,
            deptAdmin: false,
            deptEditor: true,
          });
        } else if (getMaxDepartmentAccess() == 300) {
          this.setState({
            entityAdmin: true,
            entityEditor: false,
            locAdmin: false,
            locEditor: true,
            deptAdmin: false,
            deptEditor: false,
          });
        } else if (getMaxDepartmentAccess() == 100) {
          this.setState({
            entityAdmin: true,
            entityEditor: false,
            locAdmin: false,
            locEditor: true,
            deptAdmin: true,
            deptEditor: true,
          });
        }
        this.setState({
          entityAdmin: true,
          entityEditor: false,
          locAdmin: false,
          locEditor: true,
          deptAdmin: true,
          deptEditor: true,
        });
      } else if (getMaxLocationAccess() == 300) {
        this.setState({
          entityAdmin: true,
          entityEditor: false,
          locAdmin: true,
          locEditor: false,
          deptAdmin: false,
          deptEditor: false,
        });
        if (getMaxDepartmentAccess() == 500) {
          this.setState({
            entityAdmin: true,
            entityEditor: false,
            locAdmin: true,
            locEditor: false,
            deptAdmin: false,
            deptEditor: true,
          });
        } else if (getMaxDepartmentAccess() == 300) {
          this.setState({
            entityAdmin: true,
            entityEditor: false,
            locAdmin: true,
            locEditor: false,
            deptAdmin: false,
            deptEditor: false,
          });
        } else if (getMaxDepartmentAccess() == 100) {
          this.setState({
            entityAdmin: true,
            entityEditor: false,
            locAdmin: true,
            locEditor: false,
            deptAdmin: true,
            deptEditor: true,
          });
        }
      } else if (getMaxLocationAccess() == 100) {
        this.setState({
          entityAdmin: true,
          entityEditor: false,
          locAdmin: true,
          locEditor: true,
          deptAdmin: true,
          deptEditor: true,
        });
        if (getMaxDepartmentAccess() == 500) {
          this.setState({
            entityAdmin: true,
            entityEditor: false,
            locAdmin: true,
            locEditor: true,
            deptAdmin: false,
            deptEditor: true,
          });
        } else if (getMaxDepartmentAccess() == 300) {
          this.setState({
            entityAdmin: true,
            entityEditor: false,
            locAdmin: true,
            locEditor: true,
            deptAdmin: false,
            deptEditor: false,
          });
        } else if (getMaxDepartmentAccess() == 100) {
          this.setState({
            entityAdmin: true,
            entityEditor: false,
            locAdmin: true,
            locEditor: true,
            deptAdmin: true,
            deptEditor: true,
          });
        }
      }
    }

    //entity
    else if (getMaxEntityAccess() == 100) {
      this.setState({
        entityAdmin: true,
        entityEditor: true,
        locAdmin: true,
        locEditor: true,
        deptAdmin: true,
        deptEditor: true,
        administrator: true,
      });
      if (getMaxLocationAccess() == 500) {
        if (getMaxDepartmentAccess() == 500) {
          this.setState({
            entityAdmin: true,
            entityEditor: true,
            locAdmin: false,
            locEditor: true,
            deptAdmin: false,
            deptEditor: true,
          });
        } else if (getMaxDepartmentAccess() == 300) {
          this.setState({
            entityAdmin: true,
            entityEditor: true,
            locAdmin: false,
            locEditor: true,
            deptAdmin: false,
            deptEditor: false,
          });
        } else if (getMaxDepartmentAccess() == 100) {
          this.setState({
            entityAdmin: true,
            entityEditor: true,
            locAdmin: false,
            locEditor: true,
            deptAdmin: true,
            deptEditor: true,
          });
        }
        this.setState({
          entityAdmin: true,
          entityEditor: true,
          locAdmin: false,
          locEditor: true,
          deptAdmin: false,
          deptEditor: true,
        });
      } else if (getMaxLocationAccess() == 300) {
        if (getMaxDepartmentAccess() == 500) {
          this.setState({
            entityAdmin: true,
            entityEditor: true,
            locAdmin: true,
            locEditor: false,
            deptAdmin: false,
            deptEditor: true,
          });
        } else if (getMaxDepartmentAccess() == 300) {
          this.setState({
            entityAdmin: true,
            entityEditor: true,
            locAdmin: true,
            locEditor: false,
            deptAdmin: false,
            deptEditor: false,
          });
        } else if (getMaxDepartmentAccess() == 100) {
          this.setState({
            entityAdmin: true,
            entityEditor: true,
            locAdmin: true,
            locEditor: false,
            deptAdmin: true,
            deptEditor: true,
          });
        }
        this.setState({
          entityAdmin: true,
          entityEditor: true,
          locAdmin: true,
          locEditor: false,
          deptAdmin: false,
          deptEditor: false,
        });
      } else if (getMaxLocationAccess() == 100) {
        if (getMaxDepartmentAccess() == 500) {
          this.setState({
            entityAdmin: true,
            entityEditor: true,
            locAdmin: true,
            locEditor: true,
            deptAdmin: false,
            deptEditor: true,
          });
        } else if (getMaxDepartmentAccess() == 300) {
          this.setState({
            entityAdmin: true,
            entityEditor: true,
            locAdmin: true,
            locEditor: true,
            deptAdmin: false,
            deptEditor: false,
          });
        } else if (getMaxDepartmentAccess() == 100) {
          this.setState({
            entityAdmin: true,
            entityEditor: true,
            locAdmin: true,
            locEditor: true,
            deptAdmin: true,
            deptEditor: true,
          });
        }
      }
    }
  }

  getSyncData() {
    apiRequest("/assets/syncreport", "get")
      .then((res) => {
        this.setState({
          syncData: JSON.parse(res.data[0]),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleBackButton = () => {
    this.props.data(230);
  };
  handleCollapse = () => {
    this.setState({ collapsesidebar: true });
    this.props.data(74);
  };
  handleExpand = () => {
    this.setState({ collapsesidebar: false });
    this.props.data(230);
  };
  handleExpand2 = () => {
    this.setState({ collapsesidebar: !this.state.collapsesidebar });
    this.props.data(230);
    this.setState({
      open2: true,
      open3: false,
    });
  };
  handleExpand3 = () => {
    this.setState({ collapsesidebar: !this.state.collapsesidebar });
    this.props.data("230px");
    this.setState({
      open3: true,
      open2: false,
    });
  };
  handleMenu = () => {
    this.setState({
      open1: !this.state.open1,
    });
  };
  handleMenu2 = () => {
    this.setState({
      open2: !this.state.open2,
    });
  };
  handleMenu3 = () => {
    this.setState({
      open3: !this.state.open3,
    });
  };
  handleMenu4 = () => {
    this.setState({
      open4: !this.state.open4,
    });
  };
  handleMenu5 = () => {
    this.setState({
      open5: !this.state.open5,
    });
  };
  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  search = (e) => {
    if (e.which === 13) {
      this.props.history.push(
        `/devices/viewtable?txt=${this.state.searchText}`
      );
    }
  };
  reload = () => {
    const current = this.props.location.pathname + this.props.location.search;
    this.props.history.replace(`/reload`);
    setTimeout(() => {
      this.props.history.replace(current);
    });
  };

  getColor(status = "information") {
    if (status == "information") {
      return "#CCCCCC";
    }
    else if (status == "warning") {
      return "#FFBC44";
    }
    else if (status == "critical") {
      return "#FC5A5A";
    }
  }

  renderStatus(status = "information") {
    let icon = null;
    let text = null;
    if (status == "information") {
      icon = <InfoOutlinedIcon/>;
      text = "Information: ";
    }
    else if (status == "warning") {
      icon = <ReportProblemOutlinedIcon/>;
      text = "Warning: ";
    }
    else if (status == "critical") {
      icon = <ErrorOutlineOutlinedIcon/>;
      text = "Error: ";
    }
    return (
      <div style={{display: "flex", gap: 8}}>
        {icon}
        <b>{text}</b>
      </div>
    );
  }

  render() {
    const { classes } = this.props;
    const { anchorEl } = this.state;
    const pathname = this.props.pathName;
    const { search } = this.props;
    var mm= require('moment-timezone');
    var abbs = " " + mm.tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('z').toString();
    const a = this.state.syncData.sync_time
      ? this.state.syncData.sync_time.replace(/-/g, "/")
      : "";
    var dateTime = a == "" ? "" : new Date(`${a} UTC`).toISOString();
    //.slice(0, -8)
    //.replace("T", " ");

    let user = localStorage.getItem("user");
    user = user? user : "";
    let activeAlert = this.state.alerts.find(a => a.IsActive);
    let isActiveExisted = activeAlert ? true : false;
    return (
      <div className="TrebuchetFont" /*style={{height: 60}}*/>
        <nav
          style={{}}
          className="navbar navbar-dark fixed-top uowcolor flex-md-nowrap p-1"
        >
          <Grid container style={{ display: "flex", paddingBottom: "5px", alignItems: "center" }}>
            <Grid item xs={4}>
              <div
                className="logoDiv"
                style={{ color: "white", display: "flex" }}
              >
                <div className="logoName">
                  <h4 style={{ margin: "5px 20px 0px 4px" }}>Asset Tracker</h4>
                </div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div
                className="logoDiv"
                style={{ color: "white", display: "flex", justifyContent: "center" }}
              >
                <div className="logoName">
                  <h4 style={{ margin: "5px 0px 0px 0px" }}>UW Medicine</h4>
                </div>
              </div>
            </Grid>

            <Grid item xs={4}>
              <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "end"}}>
              <div>
            <StyledMenu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={this.handleClose}
            >
              <MenuItem
                onClick={() => {
                  this.handleClose();
                  this.props.history.push("/user/profile");
                }}
              >
                View Profile
              </MenuItem>
              <MenuItem
                onClick={() => {
                  this.handleClose();
                  let auth = this.context.accounts.length > 0 ? "O365" : "Local";
                  apiRequest(`/logout?auth=${auth}`, "post")
                    .then(r => {
                      Cookies.remove(`owAuth${window.location.port}`);
                      localStorage.clear();
                      if (this.context.accounts.length > 0) {
                        this.context.instance.logout();
                      } else {
                        //only if not microsoft login
                        this.props.history.push("/");
                      }
                    });
                  //this.props.history.push("/");
                }}
              >
                Logout
              </MenuItem>
            </StyledMenu>
          </div>
          <ul className="navbar-nav px-3" style={{height: "60px"}}>
            <li>
              <Tooltip title="Send Email">
                <div
                  style={{ color: "white" }}
                  className="nav-link"
                  // onClick={() => {
                  //   Cookies.remove(`owAuth${window.location.port}`);
                  //   localStorage.clear();
                  //   this.props.history.push("/");
                  // }}
                >
                  <IconButton style={{paddingRight: "0px"}} href="mailto://appservices.support@hpe.com?cc=assettracker@uw.edu&subject=I%20need%20support%20for%20UWM%20Asset%20Tracker&body=To%20help%20with%20your%20query,%20please%20provide%20some%20details%20and%20a%20member%20of%20support%20will%20contact%20you%20soon.">
                    <HelpOutline style={{ color: "white" }} />
                  </IconButton>
                </div>
              </Tooltip>
            </li>
          </ul>
          <div
            className=" user-name navbar-nav"
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={this.handleClick}
            style={{ cursor: "pointer", marginTop: "5px" }}
          >
            <div
              style={{ color: "white", display: "inline-block" }}
              className="nav-item text-nowrap float-right border-left pl-3"
            >
              <b
                style={{ marginTop: 5, color: "white" }}
              >{user}</b>
              {/* <Tooltip title="View Profile"> */}
                <IconButton
                  style={{ color: "#4b2e83", outline: "none" }}
                  //onClick={() => this.props.history.push("/user/profile")}
                >
                  <AccountCircle style={{ color: "white" }} />
                </IconButton>
              {/* </Tooltip> */}
            </div>
          </div>
              </div>
            </Grid>
          </Grid>
        </nav>

        {/*this.state.alerts.some(a => a.IsActive)*/ isActiveExisted &&
          <Slide
            direction="bottom"
            transition="1"
            in={this.state.isNotDisMissedByUser}
            mountOnEnter
            unmountOnExit
          >
            <div style={{height: 60, marginTop: 60, borderBottom: "1px solid lightgrey", paddingInline: 8, background: this.getColor(activeAlert.Type)}}>
              <div style={{height:"100%", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                <div style={{display: "flex", gap: 8, alignItems: "center"}}>
                  {this.renderStatus(activeAlert.Type)}
                  {false && activeAlert.Description}
                  {false && <Linkify>
                    {activeAlert.Description}
                  </Linkify>}
                  {true && <div class="quilly" id="quillResult" dangerouslySetInnerHTML={{ __html:activeAlert.Description}} />}
                </div>
                {this.state.alerts.find(a => a.IsActive && a.IsDisMissable) && this.state.isNotDisMissedByUser &&
                  <Button
                    onClick={evt => {
                      var today = new Date();
                      var expiry = new Date(today.getTime() + 30 * 24 * 3600 * 1000); // plus 30 days
                      let activeAlertCookie= {Id: activeAlert.Id, Updated: activeAlert.Updated};
                      let kv = JSON.stringify(activeAlertCookie);
                      document.cookie = "alert=" + kv + "; path=/; expires=" + expiry.toGMTString();
                      this.setState({ isNotDisMissedByUser: false }, () => {
                        this.props.changeHasActiveAlert(false);
                      });
                    }}
                    /*variant="outlined"*/
                  >
                    Dismiss
                  </Button>
                }
              </div>
            </div>
          </Slide>
        }

        <Slide
          direction="right"
          transition="0.5"
          in={!this.state.collapsesidebar}
          mountOnEnter
          unmountOnExit
        >
          <div
            id="sidebar"
            style={{ paddingLeft: "12px", height: "100%", paddingTop: /*this.state.alerts.some(a => a.IsActive)*/ isActiveExisted && this.state.isNotDisMissedByUser ? 0 :"60px" }}
          >
            <ul
              className="sidebar-nav"
              style={{
                fontSize: "16px",
                fontFamily: "Trebuchet MS",
              }}
            >
              {/* <li>
                <TextField
                  id="outlined-select"
                  select
                  style={{ width: "100%", paddingRight: "15px" }}
                  required
                  margin="dense"
                  //disabled={this.state.showFilters}
                  //label="Organization"
                  placeholder="Organization"
                  className={classes.textField}
                  value={JSON.parse(sessionStorage.getItem("entityID"))}
                  onChange={(option) => {
                    sessionStorage.setItem("entityID", option.target.value ? JSON.stringify(option.target.value) : "");
                    storeEntityId(option.target.value);
                    storelocationId(-1);
                    this.setState({ selector: option.target.value });
                    this.reload();
                  }}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  InputProps={{ className: classes.input1 }}
                  InputLabelProps={{
                    className: classes.label1,
                  }}
                  variant="outlined"
                >
                  {this.state.entityData.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </li> */}
              <li
                hidden={this.state.entityAdmin}
                style={{ paddingTop: "7px", cursor: "pointer" }}
                className="nav-item"
              >
                <Link
                  className={
                    pathname === "/home" /*|| pathname === "/devices/map"*/
                      ? "active"
                      : ""
                  }
                  to="/home"
                  onClick={() => {
                    this.setState({
                      //open1: false,
                      open2: false,
                      //open3: false,
                    });
                    storeEntityId(-1);
                    storelocationId(-1);
                  }}
                >
                  <HomeIcons style={{ marginRight: "3px" }} /> Dashboard{" "}
                </Link>
              </li>

              <li
                style={{ paddingTop: "5px", cursor: "pointer" }}
                className="nav-item"
                hidden={this.state.noAccess}
              >
                <Link
                  className={pathname === "/devices/viewtable" || pathname.includes("asset") ? "active" : ""}
                  to={!this.state.noAccess ? "/devices/viewtable" : "/noaccess"}
                  onClick={() => {
                    this.setState({
                      //open1: false,
                      open2: false,
                      //open3: false,
                    });
                    window.x = 0;
                    storeEntityId(-1);
                    storelocationId(-1);
                  }}
                >
                  <SearchIcon style={{ marginRight: "3px" }} /> Assets{" "}
                </Link>
              </li>

              <li
                style={{ paddingTop: "5px", cursor: "pointer" }}
                className="nav-item"
                hidden={this.state.noAccess}
              >
                <Link
                  className={pathname === "/devices/viewmap" ? "active" : ""}
                  to={!this.state.noAccess ? "/devices/viewmap" : "/noaccess"}
                  onClick={() => {
                    this.setState({
                      //open1: false,
                      open2: false,
                      //open3: false,
                    });
                    storeEntityId(-1);
                    storelocationId(-1);
                  }}
                >
                  <MapIcon style={{ marginRight: "3px" }} /> Live Map
                </Link>
              </li>
              <li
                className="nav-item"
                hidden={this.state.deptAdmin}
                style={{ paddingTop: "5px" }}
              >
                <Link
                  className={
                    pathname === "/devices/manage/dept" ? "active" : ""
                  }
                  to="/devices/manage/dept"
                  onClick={() => {
                    storeEntityId(-1);
                    storelocationId(-1);
                  }}
                >
                  <Domain style={{ marginRight: "3px" }} /> Departments
                </Link>
              </li>
              <li
                className="nav-item"
                hidden={this.state.deptAdmin}
                style={{ paddingTop: "5px" }}
              >
                <Link
                  className={pathname === "/devices/manage/col" ? "active" : ""}
                  to="/devices/manage/col"
                  onClick={() => {
                    storeEntityId(-1);
                    storelocationId(-1);
                  }}
                >
                  <DynamicFeedIcon style={{ marginRight: "3px" }} /> Collections
                </Link>
              </li>
              {false &&
                <li
                  hidden={this.state.deptAdmin}
                  style={{ paddingTop: "5px" }}
                  className="nav-item"
                >
                  <div onClick={this.handleMenu}>
                    <ManageUsersIcon style={{ marginRight: "3px" }} /> Users Mgmt{" "}
                    {!this.state.open1 ? (
                      <ExpandMore
                        style={{
                          height: "18px",
                          width: "18px",
                          marginLeft: "59px", //105
                        }}
                      />
                    ) : (
                      <ExpandLess
                        style={{
                          height: "18px",
                          width: "18px",
                          marginLeft: "59px",
                        }}
                      />
                    )}
                  </div>

                  <ul
                    hidden={!this.state.open1}
                    className="sidebar-nav"
                    style={{
                      paddingTop: "4px",
                      paddingBottom: "4px",
                      paddingLeft: "33px",
                      fontSize: "14px",
                      fontFamily: "Trebuchet MS",
                    }}
                  >
                    <li className="nav-item">
                      <Link
                        className={
                          pathname === "/options/users/permissions"
                            ? "active"
                            : ""
                        }
                        to="/options/users/permissions"
                        onClick={() => {
                          storeEntityId(-1);
                          storelocationId(-1);
                        }}
                      >
                        Permissions
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className={
                          pathname === "/options/users/manage" ? "active" : ""
                        }
                        to="/options/users/manage"
                        onClick={() => {
                          storeEntityId(-1);
                          storelocationId(-1);
                        }}
                      >
                        Roles
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className={
                          pathname === "/options/users/modify" || pathname === "/options/users/upload" ? "active" : ""
                        }
                        to="/options/users/modify"
                        onClick={() => {
                          storeEntityId(-1);
                          storelocationId(-1);
                        }}
                      >
                        Users
                      </Link>
                    </li>
                  </ul>
                </li>
              }
              {/* <li
                hidden={this.state.deptAdmin}
                style={{ paddingTop: "5px" }}
                className="nav-item"
              >
                <div onClick={this.handleMenu4}>
                  <DevicesIcon style={{ marginRight: "3px" }} /> Department
                  Assets
                  {!this.state.open4 ? (
                    <ExpandMore
                      style={{
                        height: "18px",
                        width: "18px",
                        marginLeft: "14px",
                      }}
                    />
                  ) : (
                    <ExpandLess
                      style={{
                        height: "18px",
                        width: "18px",
                        marginLeft: "14px",
                      }}
                    />
                  )}
                </div>

                <ul
                  hidden={!this.state.open4}
                  className="sidebar-nav"
                  style={{
                    paddingTop: "4px",
                    paddingBottom: "4px",
                    paddingLeft: "33px",
                    fontSize: "14px",
                    fontFamily: "Trebuchet MS",
                  }}
                >
                  <li className="nav-item">
                    <Link
                      className={
                        pathname === "/devices/manage/dept" ? "active" : ""
                      }
                      to="/devices/manage/dept"
                      onClick={() => {
                        storeEntityId(-1);
                        storelocationId(-1);
                      }}
                    >
                      Department Assets List
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={
                        search.includes("type=dept") &&
                        (pathname.includes("Create") ||
                          pathname.includes("Modify"))
                          ? "active"
                          : ""
                      }
                      to={
                        getMaxEntityAccess() >= 300 ||
                        getMaxLocationAccess() >= 300 ||
                        getMaxDepartmentAccess() > 500
                          ? "/options/Create/collection/0?type=dept"
                          : "/options/Modify/collection/0?type=dept"
                      }
                      onClick={() => {
                        storeEntityId(-1);
                        storelocationId(-1);
                      }}
                    >
                      Department Management
                    </Link>
                  </li>
                </ul>
              </li> */}

              {/* <li
                hidden={this.state.deptAdmin}
                style={{ paddingTop: "5px" }}
                className="nav-item"
              >
                <div onClick={this.handleMenu5}>
                  <DynamicFeedIcon style={{ marginRight: "3px" }} /> Collection
                  Assets
                  {!this.state.open5 ? (
                    <ExpandMore
                      style={{
                        height: "18px",
                        width: "18px",
                        marginLeft: "27px",
                      }}
                    />
                  ) : (
                    <ExpandLess
                      style={{
                        height: "18px",
                        width: "18px",
                        marginLeft: "27px",
                      }}
                    />
                  )}
                </div>

                <ul
                  hidden={!this.state.open5}
                  className="sidebar-nav"
                  style={{
                    paddingTop: "4px",
                    paddingBottom: "4px",
                    paddingLeft: "33px",
                    fontSize: "14px",
                    fontFamily: "Trebuchet MS",
                  }}
                >
                  <li className="nav-item">
                    <Link
                      className={
                        pathname === "/devices/manage/col" ? "active" : ""
                      }
                      to="/devices/manage/col"
                      onClick={() => {
                        storeEntityId(-1);
                        storelocationId(-1);
                      }}
                    >
                      Collection Assets List
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={
                        search.includes("type=col") &&
                        (pathname.includes("Create") ||
                          pathname.includes("Modify"))
                          ? "active"
                          : ""
                      }
                      to={"/options/Create/collection/0?type=col"}
                      onClick={() => {
                        storeEntityId(-1);
                        storelocationId(-1);
                      }}
                    >
                      Collection Management
                    </Link>
                  </li>
                </ul>
              </li> */}

              <li
                hidden={this.state.deptAdmin}
                style={{ paddingTop: "5px" }}
                className="nav-item"
              >
                <div onClick={this.handleMenu3}>
                  <SettingsIcon style={{ marginRight: "3px" }} /> Manage{" "}
                  {!this.state.open3 ? (
                    <ExpandMore
                      style={{
                        height: "18px",
                        width: "18px",
                        marginLeft: "88px",
                      }}
                    />
                  ) : (
                    <ExpandLess
                      style={{
                        height: "18px",
                        width: "18px",
                        marginLeft: "88px",
                      }}
                    />
                  )}
                </div>

                <ul
                  hidden={!this.state.open3}
                  className="sidebar-nav"
                  style={{
                    paddingTop: "4px",
                    paddingBottom: "4px",
                    paddingLeft: "33px",
                    fontSize: "14px",
                    fontFamily: "Trebuchet MS",
                  }}
                >
                  <li hidden={this.state.administrator} className="nav-item">
                    <Link
                      className={pathname === "/log/activity" ? "active": ""}
                      to={"/log/activity"}
                    >
                      Activity Log
                    </Link>
                  </li>
                  <li hidden={this.state.administrator} className="nav-item">
                    <Link
                      className={
                        pathname === "/options/Create/alert" ||
                        pathname === "/options/View/alert"
                          ? "active"
                          : ""
                      }
                      to={"/options/Create/alert"}
                    >
                      Alerts
                    </Link>
                  </li>
                  <li hidden={this.state.locAdmin} className="nav-item">
                    <Link
                      className={
                        pathname.includes("/options/Create/loc") ||
                        pathname.includes("/options/Modify/loc")
                          ? "active"
                          : ""
                      }
                      to={
                        getMaxEntityAccess() >= 300 ||
                        getMaxLocationAccess() > 500
                          ? "/options/Create/loc/0"
                          : "/options/Modify/loc/0"
                      }
                      onClick={() => {
                        storeEntityId(-1);
                        storelocationId(-1);
                      }}
                    >
                      Campus
                    </Link>
                  </li>
                  <li className="nav-item" hidden={this.state.deptAdmin}>
                    <Link
                      className={
                        search.includes("type=col") &&
                        (pathname.includes("Create") ||
                          pathname.includes("Modify"))
                          ? "active"
                          : ""
                      }
                      to={"/options/Create/collection/0?type=col"}
                      onClick={() => {
                        storeEntityId(-1);
                        storelocationId(-1);
                      }}
                    >
                      Collections
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={
                        search.includes("type=dept") &&
                        (pathname.includes("Create") ||
                          pathname.includes("Modify"))
                          ? "active"
                          : ""
                      }
                      to={
                        getMaxEntityAccess() >= 300 ||
                        getMaxLocationAccess() >= 300 ||
                        getMaxDepartmentAccess() > 500
                          ? "/options/Create/collection/0?type=dept"
                          : "/options/Modify/collection/0?type=dept"
                      }
                      onClick={() => {
                        storeEntityId(-1);
                        storelocationId(-1);
                      }}
                    >
                      Departments
                    </Link>
                  </li>
                  <li hidden={this.state.deptAdmin} className="nav-item">
                    <Link
                      className={
                        pathname.includes("/master/data/manage") ? "active" : ""
                      }
                      to="/master/data/manage"
                      onClick={() => {
                        storeEntityId(-1);
                        storelocationId(-1);
                      }}
                    >
                      Metadata
                    </Link>
                  </li>
                  <li hidden={this.state.entityAdmin} className="nav-item">
                    <Link
                      className={
                        pathname === "/options/Create/entity" ||
                        pathname === "/options/Modify/entity"
                          ? "active"
                          : ""
                      }
                      to={
                        getMaxEntityAccess() == 700
                          ? "/options/Create/entity"
                          : "/options/Modify/entity"
                      }
                      onClick={() => {
                        storeEntityId(-1);
                        storelocationId(-1);
                      }}
                    >
                      Organizations
                    </Link>
                  </li>
                  <li hidden={this.state.entityAdmin} className="nav-item">
                    <Link
                      className={pathname === "/devices/map" || pathname === "/devices/upload" ? "active" : ""}
                      to={"/devices/map"}
                      onClick={() => {
                        storeEntityId(-1);
                        storelocationId(-1);
                      }}
                    >
                      Orphans
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      className={pathname.includes("/options/users") || pathname.includes("/options/roles") ? "active" : ""}
                      to="/options/users"
                      onClick={() => {
                        storeEntityId(-1);
                        storelocationId(-1);
                      }}
                    >
                      Users
                    </Link>
                  </li>
                </ul>
              </li>
              {/* <li
                hidden={this.state.administrator}
                style={{ paddingTop: "5px", cursor: "pointer" }}
                className="nav-item"
              >
                <Link
                  className={pathname === "/master/data/manage" ? "active" : ""}
                  to="/master/data/manage"
                  onClick={() => {
                    storeEntityId(-1);
                    storelocationId(-1);
                  }}
                >
                  <SettingsIcon style={{ marginRight: "3px" }} /> Metadata{" "}
                </Link>
              </li> */}
            </ul>
          </div>
        </Slide>

        <nav
          style={{}}
          className="fnavbar navbar-light fixed-bottom fcolor flex-md-nowrap p-1"
        >
          <div
            style={{ display: "flex", justifyContent: "space-between" }}
            className="sync-data"
          >
            <Grid container>
              <Grid item xs={4}>
                <p
                  style={{
                    display: "inline",
                    margin: "auto 10px",
                    color: "black",
                    fontSize: "smaller",
                  }}
                >
                  &copy; {new Date().getFullYear().toString()} Hewlett Packard
                  Enterprise Development LP
                </p>
              </Grid>
              <Grid item xs={4} style={{ textAlign: "center" }}>
                <p
                  style={{
                    display: "inline",
                    margin: "auto 10px",
                    color: "black",
                    fontSize: "smaller",
                  }}
                >
                  v3.8.3
                </p>
              </Grid>
              <Grid item xs={4} style={{ textAlign: "end" }}>
                {Object.keys(this.state.syncData).length != 0 && <div>
                  <p
                    style={{
                      display: "inline",
                      margin: "auto 10px",
                      color: "black",
                      fontSize: "smaller",
                    }}
                  >
                    <b>Last Sync: </b>
                    {dateTime ? (
                      <div style={{ display: "inline-block" }}>
                        <Moment local format="DD MMMM YYYY H:mm">
                          {dateTime}
                        </Moment>
                        {abbs}
                      </div>
                    ) : (
                      ""
                    )}
                    <b>|</b>
                    <b>Status</b>: {this.state.syncData.status ? this.state.syncData.status : ""}
                  </p>
                </div>}
              </Grid>
            </Grid>
          </div>
        </nav>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const { entities } = state;
  return {
    entities,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEntities: () => getEntity(dispatch),
    getLocation: (entityId) => getLocations(dispatch, entityId),
    getCollection: (entityId, locationId = "") => getCollections(dispatch, entityId, locationId),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withRouter
)(Navbar);
