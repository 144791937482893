import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import "./ManageUsers.css";
import TableMapped from "./TableMappedUsers.jsx";
import TableUnMapped from "./TableUnmappedUsers.jsx";
import ResetIcon from "@material-ui/icons/Refresh";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { Divider } from "@material-ui/core";
import {
  getEntity,
  getLocations,
  getCollections,
  cleanData,
  showMessage
} from "../actions/index";
import { compose } from "recompose";
import { connect } from "react-redux";
import Collapse from "@material-ui/core/Collapse";
import CircularProgress from "@material-ui/core/CircularProgress";
import { apiRequest, isPortalAdmin, allowedDomains } from "../utils/Utils";
import AlertDialog from "./AlertDialog";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel'

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
    paddingTop: 10,
    paddingBottom: 10
  },
  input: {
    height: 38
  },
  input1: {
    width: "80%",
    padding: "5px",
    borderRadius: "5px",
    outline: "none",
    border: "1px solid lightgrey"
  },
  label: {
    marginTop: "-5px"
  },
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 2,

    flexGrow: 1
  },
  root2: {
    ...theme.mixins.gutters(),
    paddingBottom: theme.spacing.unit * 1
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  textField: { width: "100%" },
  dense: {
    marginTop: 16
  },
  menu: {
    width: 200
  }
});

class ManageDevices extends Component {
  state = {
    users: [],
    user: "",
    search: "",
    phone: "",
    username: "",
    fname: "",
    email: "",
    altphone: "",
    disabledFields: false,
    openAlert: false,
    actionType: "",
    message: "",
    disableAdd: true,
    showRedField: false,
    isO365User: true //isPortalAdmin ? false : true
  };

  componentWillMount() {
    this.fetchUsers();
  }

  fetchUsers() {
    apiRequest(`/users?is_active=true`, "get")
      .then(x => {
        this.setState({
          users: x.data
        });
      })
      .catch(err => console.log(err));
  }

  handleChange = name => event => {
    this.setState(
      {
        [name]: event.target.value
      },
      () => {
        if (name == "username") {

          //If office365 user, then username == office email
          if (this.state.isO365User) {
            this.setState({
              message: "",
              disableAdd: false,
              email: this.state.username
            });
          } else {
            this.setState({
              message: "",
              disableAdd: false
            });
          }
        }
      }
    );
  };

  handleAdd() {
    const {
      phone,
      username,
      fname,
      email,
      altphone,
      showRedField
    } = this.state;
    if (username == "" || fname == "" || email == "") {
      this.setState({
        showRedField: true
      });
      this.props.showMessageBox("Please fill all the required fields");
      return;
    }
    if (showRedField) {
      this.setState({
        showRedField: false
      });
    }
    if (
      !email.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      this.props.showMessageBox("Enter valid Email Id");
      return;
    }
    if (!isPortalAdmin && this.state.isO365User && !allowedDomains.some(d => username.endsWith(d))) {
      this.props.showMessageBox("Ensure that an '@uw.edu' email address is used to access the application");
      return;
    }
    this.setState({
      actionType: "ADD",
      openAlert: true
    });
  }

  addUser = () => {
    this.setState(
      {
        openAlert: false
      },
      () => {
        setTimeout(() => {
          this.setState({
            actionType: ""
          });
        }, 500);
      }
    );
    const that = this;
    const { phone, username, fname, email, altphone, isO365User } = this.state;
    const data = {
      UserId: username,
      Password: "",
      FullName: fname,
      Email: email,
      PhoneNumber: phone,
      AlternatePhoneNumber: altphone,
      IsOfficeUser: isO365User
    };

    apiRequest("/users", "post", data)
      .then(x => {
        if (x.status > 0 && x.message == null)
          that.props.showMessageBox("SUCCESS");
        else
          that.props.showMessageBox(x.message);
        if (x.status > 0) {
          this.setState({
            disabledFields: true
          });
          this.fetchUsers();
        }
      })
      .catch(err => {
        that.props.showMessageBox("ERROR");
      });
  };

  handleReset2 = () => {
    this.setState({
      user: "",
      search: ""
    });
  };

  handleReset1 = () => {
    this.setState({
      phone: "",
      username: "",
      fname: "",
      email: "",
      altphone: "",
      disabledFields: false,
      isO365User: true //isPortalAdmin ? false : true
    });
  };

  checkForDuplicateUserName = () => {
    if (this.state.username != "") {
      for (let i = 0; i < this.state.users.length; i++) {
        if (this.state.username == this.state.users[i].UserId) {
          this.setState({
            message: "*UserName already exists",
            disableAdd: true
          });
          break;
        }
      }
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <AlertDialog
          open={this.state.openAlert}
          onClickYes={
            this.state.actionType == "REMOVE" ? this.removeUser : this.addUser
          }
          action={this.state.actionType == "REMOVE" ? "Remove" : "Save"}
          close={() => {
            this.setState({ openAlert: false });
          }}
        />
        <div>
          <Paper
            className={classes.root}
            style={{
              padding: "2px 10px 2px 10px",
              width: "100%",
              height: "100%",
              flexDirection: "column",
              display: "flex",
              justifyContent: "center"
            }}
          >
            <div
              style={{ paddingBottom: "5px", paddingTop: "5px", width: "100%" }}
            >
              <div
                style={{
                  paddingBottom: "2px",
                  width: "100%"
                }}
              >
                <Grid container spacing={24} style={{ display: "flex" }}>
                  <Grid item xs={10}>
                    <h4
                      style={{
                        color: "#4b2e83",
                        width: "100%",
                        paddingLeft: 4,
                        paddingTop: 16
                      }}
                    >
                      Create New User
                    </h4>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    style={{ display: "flex", alignItems: "right" }}
                  >
                    <Tooltip title="Reset">
                      <IconButton
                        style={{ color: "#4b2e83", paddingTop: 16 }}
                        aria-label="Reset"
                        onClick={this.handleReset1}
                      >
                        <ResetIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    style={{ display: "flex", alignItems: "right" }}
                  >
                    <Button
                      onClick={() => {
                        this.handleAdd();
                      }}
                      style={
                        !this.state.disabledFields && !this.state.disableAdd
                          ? {
                              color: "#4b2e83",
                              opacity: "1",
                              outline: "none"
                            }
                          : { color: "#4b2e83", opacity: "0.4" }
                      }
                      //className="buttonnobg float-center"
                      disabled={
                        this.state.disabledFields || this.state.disableAdd
                      }
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>


                <Grid
                  container
                  spacing={32}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Grid item xs={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={!isPortalAdmin}
                        checked={this.state.isO365User}
                        onChange={evt => {
                          if (evt.target.checked) {
                            this.setState({ isO365User: evt.target.checked, email: this.state.username });
                          } else {
                            this.setState({ isO365User: evt.target.checked });
                          }
                        }}
                        //name="checkedB"
                        color="primary"
                      />
                    }
                    label={<b>Microsoft O365 User</b>}
                  />
                  </Grid>
                  {/* <div style={{ color: "red", marginLeft: 15 }}>
                    {this.state.message}
                  </div> */}
                </Grid>



                <Grid
                  container
                  spacing={32}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Grid item xs={3}>
                    <TextField
                      id="outlined-name"
                      label={this.state.isO365User ? "Microsoft O365 Email" : "Username"}
                      required
                      error={
                        this.state.showRedField && this.state.username == ""
                          ? true
                          : false
                      }
                      margin="dense"
                      disabled={this.state.disabledFields}
                      value={this.state.username}
                      type="text"
                      style={{
                        width: "100%",
                        borderColor: "#4b2e83"
                      }}
                      onChange={this.handleChange("username")}
                      variant="outlined"
                      placeholder={this.state.isO365User ? "Microsoft O365 Email" : "Username"}
                      InputProps={{ className: classes.input }}
                      InputLabelProps={{
                        className: classes.label,
                        shrink: true
                      }}
                      onBlur={this.checkForDuplicateUserName}
                    />
                  </Grid>
                  <div style={{ color: "red", marginLeft: 15 }}>
                    {this.state.message}
                  </div>
                </Grid>
                <Grid container spacing={32} style={{ display: "flex" }}>
                  <Grid item xs={3}>
                    <TextField
                      id="outlined-name"
                      label="Full Name"
                      margin="dense"
                      required
                      error={
                        this.state.showRedField && this.state.fname == ""
                          ? true
                          : false
                      }
                      disabled={this.state.disabledFields}
                      value={this.state.fname}
                      type="text"
                      style={{
                        width: "100%",
                        borderColor: "#4b2e83"
                      }}
                      onChange={this.handleChange("fname")}
                      variant="outlined"
                      placeholder="Full Name"
                      InputProps={{ className: classes.input }}
                      InputLabelProps={{
                        className: classes.label,
                        shrink: true
                      }}
                    />
                  </Grid>
                  <Grid item xs={1} />
                  {false &&<Grid item xs={3}>
                    {!this.state.isO365User && <TextField
                      id="outlined-name"
                      label="Email Id"
                      required
                      margin="dense"
                      error={
                        this.state.showRedField && this.state.email == ""
                          ? true
                          : false
                      }
                      disabled={this.state.disabledFields}
                      value={this.state.email}
                      type="text"
                      style={{
                        width: "100%",
                        borderColor: "#4b2e83"
                      }}
                      onChange={this.handleChange("email")}
                      variant="outlined"
                      placeholder="Email Id"
                      InputProps={{ className: classes.input }}
                      InputLabelProps={{
                        className: classes.label,
                        shrink: true
                      }}
                    />}
                  </Grid>}
                </Grid>
                <Grid
                  container
                  spacing={32}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Grid item xs={3}>
                    <TextField
                      id="outlined-name"
                      label="Phone Number"
                      //required
                      //type="number"
                      autoComplete='off'
                      margin="dense"
                      disabled={this.state.disabledFields}
                      value={this.state.phone}
                      style={{
                        width: "100%",
                        borderColor: "#4b2e83"
                      }}
                      onChange={this.handleChange("phone")}
                      variant="outlined"
                      placeholder="Phone Number"
                      InputProps={{ className: classes.input }}
                      InputLabelProps={{
                        className: classes.label,
                        shrink: true
                      }}
                    />
                  </Grid>
                  <Grid item xs={1} />
                  {false && <Grid item xs={3}>
                    <TextField
                      //type="number"
                      autoComplete='off'
                      id="outlined-name"
                      label="Alternate Phone Number"
                      margin="dense"
                      disabled={this.state.disabledFields}
                      value={this.state.altphone}
                      style={{
                        width: "100%",
                        borderColor: "#4b2e83"
                      }}
                      onChange={this.handleChange("altphone")}
                      variant="outlined"
                      placeholder="Alternate Phone Number"
                      InputProps={{ className: classes.input }}
                      InputLabelProps={{
                        className: classes.label,
                        shrink: true
                      }}
                    />
                  </Grid>}
                  <Grid item xs={3}>
                    {!this.state.isO365User && <TextField
                      id="outlined-name"
                      label="Email Id"
                      required
                      margin="dense"
                      error={
                        this.state.showRedField && this.state.email == ""
                          ? true
                          : false
                      }
                      disabled={this.state.disabledFields}
                      value={this.state.email}
                      type="text"
                      style={{
                        width: "100%",
                        borderColor: "#4b2e83"
                      }}
                      onChange={this.handleChange("email")}
                      variant="outlined"
                      placeholder="Email Id"
                      InputProps={{ className: classes.input }}
                      InputLabelProps={{
                        className: classes.label,
                        shrink: true
                      }}
                    />}
                  </Grid>
                </Grid>
              </div>
            </div>
          </Paper>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {} = state;
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    showMessageBox: message => dispatch(showMessage(message))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(ManageDevices);
