import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import CreateAlert from "../components/CreateAlert.jsx";
import TableForOption from "../components/TableForOption.jsx";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import AssignOrRemoveUsers from "../components/AssignOrRemoveUsers";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import { PFContext } from "../components/PrimaryFilterContext";
import { byName } from "../utils/Utils";


class Settings extends Component {
  //static contextType = PFContext;
  constructor() {
    super();
  }

  render() {
    return (
      <div
        id="content"
        style={{
          //paddingTop: "60px",
          transition: "0.25s",
          paddingLeft: "0px"
        }}
      >
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-lg-12"
              style={{
                height: "100%",
                width: "100%"
              }}
            >
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <CreateAlert
                      params={this.props.match.params.function}
                      history={this.props.history}
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { locations, collections } = state;
  return {
    locations,
    collections
  };
};

export default connect(mapStateToProps)(Settings);
