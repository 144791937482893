import React, { Component } from "react";
import ActionLog from "../components/TableActionLog.jsx";

const ViewActivityLog = () => {
  return (
    <div>
      <div
        id="content"
        style={{
          //paddingTop: "60px",
          transition: "0.25s",
          paddingLeft: "0px"
        }}
      >
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-lg-12"
              style={{
                height: "100%",
                width: "100%"
              }}
            >
              <ActionLog />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewActivityLog;
