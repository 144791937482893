import React, { Component } from "react";
import "./Page2.css";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "../components/Table.jsx";
import { PFContext } from "../components/PrimaryFilterContext";

const styles = theme => ({
  root: {
    width: "100%"
  },
  tabsIndicator: {
    backgroundColor: "#4b2e83"
  },
  tabsRoot: {
    borderBottom: "1px solid white"
  },
  tabRoot: {
    color: "#4b2e83",
    "&:hover": {
      color: "#4b2e83",
      opacity: 1
    },
    "&:focus": {
      color: "#4b2e83"
    }
  }
});

class About extends Component {
  constructor() {
    super();
    this.state = {
      collapse: "true",
      isHidden: true,
      value: 1
    };
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { classes } = this.props;
    const { value } = this.state;

    return (
      <div>
        <div
          id="content"
          style={{
            //paddingTop: "60px",
            transition: "0.25s",
            paddingLeft: "0px"
          }}
        >
          <div className="container-fluid">
            <div className="row">
              <div
                className="col-lg-12"
                style={{
                  height: "100%",
                  width: "100%"
                }}
              >
                {/* <PFContext.Consumer>
                  {data =>
                    <Table values={data} />
                  }
                </PFContext.Consumer> */}
                <Table />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(About);
