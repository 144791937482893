import React, { Component } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import ResetIcon from "@material-ui/icons/Refresh";
import ResetPwd from "@material-ui/icons/VpnKey";
import SubmitIcon from "@material-ui/icons/CheckCircleOutline";
import Tooltip from "@material-ui/core/Tooltip";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { apiRequest, isPortalAdmin, PASSWORD_REGEX, PASSWORD_ERROR } from "../utils/Utils";
import CircularProgress from "@material-ui/core/CircularProgress";
import { showMessage } from "../actions/index";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from "@material-ui/icons/Delete";
import Collapse from "@material-ui/core/Collapse";
import AlertDialog from "../components/AlertDialog";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Link } from "react-router-dom";

const tableHeader = [
  { id: "0", label: "Resource Name" },
  { id: "1", label: "Resource Type" },
  { id: "2", label: "Access Level" },
  { id: "3", label: "Action" }
];

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
    paddingTop: 10,
    paddingBottom: 10
  },
  input: {
    borderRadius: "2px",
    width: "100%",
    padding: "5px",
    borderRadius: "5px",
    outline: "none",
    border: "1px solid lightgrey"
  },
  textField: { width: "100%" },
  menu: {
    width: "100%"
  }
});

class UserPermissions extends Component {
  state = {
    users: [],
    selectedUser: "",
    name: "",
    imageUrl: "",
    email: "",
    permissions: [],
    open: false,
    openAlert: false,
    actionType: "",
    rowToDelete: {},
    openNewPwd: false,
    newPwd: "",
    cnfPwd: "",
    passwordType: "random_password"
  };

  componentWillMount() {
    this.fetchUsers();
  }

  fetchUsers() {
    apiRequest("/users?is_active=true", "get")
      .then(x => {
        this.setState({
          users: x.data
        });
      })
      .catch(err => console.log(err));
  }

  selectUser = event => {
    this.setState({
      selectedUser: event.target.value
    });
  };

  fetchUserData() {
    if (this.state.selectedUser == "") {
      this.props.showMessageBox("Please select a user");
      return;
    }

    const isValidUser = this.state.users.filter(
      user => user.UserId == this.state.selectedUser
    );
    if (isValidUser.length == 0) {
      this.props.showMessageBox("User not found");
      return;
    }

    apiRequest(`/users/${this.state.selectedUser}`, "get")
      .then(response => {
        this.setState({
          name: response.data[0].FullName,
          imageUrl: response.data[0].ImageURLSmall,
          email: response.data[0].Email,
          permissions: response.data[0].Permissiones,
          userName: response.data[0].UserId,
          isO365User: response.data[0].IsOfficeUser
        });
        this.setState({
          open: true
        });
      })
      .catch(err => {
        console.log(err);
      });
  }

  handleReset = () => {
    this.setState({
      selectedUser: "",
      open: false
    });
  };

  handleDelete = () => {
    const { MembershipId } = this.state.rowToDelete;
    const { ControlLevel } = this.state.rowToDelete.ResourceInfo;
    this.setState(
      {
        openAlert: false
      },
      () => {
        setTimeout(() => {
          this.setState({
            actionType: ""
          });
        }, 500);
      }
    );
    if (ControlLevel >= 500) {
      apiRequest(`/accesscontrol/${MembershipId}`, "delete")
        .then(x => {
          if (x.status > 0) {
            this.fetchUserData();
          }
          if (x.status > 0 && x.message == null)
            this.props.showMessageBox("SUCCESS");
          else
            this.props.showMessageBox(x.message);
        })
        .catch(err => console.log(err));
    } else {
      this.props.showMessageBox("Access Denied");
    }
  };

  handleResetPassword = () => {
    const { newPwd, cnfPwd } = this.state;
    this.setState(
      {
        openAlert: false
      },
      () => {
        setTimeout(() => {
          this.setState({
            actionType: ""
          });
        }, 500);
      }
    );

    let data;

    if (this.state.passwordType == "random_password") {
      data = {
        UserId: this.state.selectedUser,
        NewPasswordBase64: "",
        NewPasswordConfirmBase64: ""
      };
    } else {
      data = {
        UserId: this.state.selectedUser,
        NewPasswordBase64: btoa(newPwd),
        NewPasswordConfirmBase64: btoa(cnfPwd)
      };
    }
    apiRequest(`/users/${this.state.selectedUser}/password/1`, "post", data)
      .then(res => {
        if (res.status > 0) {
          this.props.showMessageBox("SUCCESS");
        } else {
          this.props.showMessageBox(res.message);
        }
        this.setState({
          openNewPwd: false,
          newPwd: "",
          cnfPwd: "",
        });
      })
      .catch(err => {
        this.props.showMessageBox("ERROR");
      });
  };

  handleClose() {
    this.setState({ openNewPwd: false, cnfPwd: "", newPwd: "" });
  }

  handleChange = event => {
    this.setState({
      passwordType: event.target.value
    });
  };

  removeUser = () => {
    this.setState(
      {
        openAlert: false
      },
      () => {
        setTimeout(() => {
          this.setState({
            actionType: ""
          });
        }, 500);
      }
    );

    apiRequest(`/users/${this.state.selectedUser}`, "delete")
      .then(x => {
        if (x.status > 0 && x.message == null) {
          this.fetchUsers();
          this.setState({
            user: ""
          });
          this.props.showMessageBox("User Removed");
          this.handleReset();
          //this.fetchUserData();
        }
        else
          this.props.showMessageBox(x.message);
      })
      .catch(err => {
        this.props.showMessageBox("ERROR");
      });
  };

  render() {
    const { users, name, imageUrl, email, permissions, userName, isO365User } = this.state;
    const { classes } = this.props;

    return (
      <div
        id="content"
        style={{
          //paddingTop: "60px",
          transition: "0.25s",
          paddingLeft: "0px"
        }}
      >
        <AlertDialog
          open={this.state.openAlert}
          onClickYes={
            this.state.actionType == "DELETE"
              ? this.handleDelete
              : this.state.actionType == "REMOVE"
              ? this.removeUser
              : this.handleResetPassword
          }
          action={
            this.state.actionType == "DELETE"
              ? "Remove Permission"
              : this.state.actionType == "REMOVE"
              ? "Remove User"
              : "Password Reset"
          }
          close={() => {
            this.setState({ openAlert: false });
          }}
        />

        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={this.state.openNewPwd}
          onClose={() => {
            this.handleClose();
          }}
        >
          <DialogTitle>Set Password</DialogTitle>
          <DialogContent style={{ paddingBottom: 8 }}>
            <RadioGroup
              className={classes.group}
              value={this.state.passwordType}
              onChange={this.handleChange}
              row
            >
              <FormControlLabel
                value="random_password"
                control={<Radio />}
                label="Random Password"
              />
              <FormControlLabel
                value="custom_password"
                control={<Radio />}
                label="Custom Password"
              />
            </RadioGroup>
            <TextField
              id="standard-uncontrolled"
              label="New Password"
              className={classes.textField}
              margin="dense"
              type="password"
              onChange={e => {
                this.setState({ newPwd: e.target.value });
              }}
              disabled={this.state.passwordType == "random_password"}
              value={this.state.newPwd}
            />
            <TextField
              id="standard-uncontrolled"
              label="Confirm Password"
              className={classes.textField}
              margin="dense"
              type="password"
              disabled={this.state.passwordType == "random_password"}
              onChange={e => {
                this.setState({ cnfPwd: e.target.value });
              }}
              value={this.state.cnfPwd}
            />
            <p style={{ fontSize: "12px", color: "red", marginBottom: 4 }}>
              *Password must be between 8-15 characters. Must contains at least
              ONE special characters (!#$_&@%), 1 numeric, 1 UpperCase, 1
              smallcase letters
            </p>
            <p style={{ fontSize: "12px", color: "red", marginBottom: 0 }}>
              *Email with new password will be sent to the User
            </p>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.handleClose();
              }}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                const { newPwd, cnfPwd } = this.state;

                if (this.state.passwordType == "custom_password") {
                  if (newPwd == "" || cnfPwd == "") {
                    this.props.showMessageBox("Fields should not be empty");
                    return;
                  }
                  else {
                    if (newPwd !== cnfPwd) {
                      this.props.showMessageBox(
                        "Confirm Password should match with New Password"
                      );
                      return;
                    }
                    if (!PASSWORD_REGEX.test(newPwd) || !PASSWORD_REGEX.test(cnfPwd)) {
                      this.props.showMessageBox(PASSWORD_ERROR);
                      return;
                    }
                  }
                }
                this.setState({
                  actionType: "RESET",
                  openAlert: true
                });
              }}
              color="primary"
            >
              Done
            </Button>
          </DialogActions>
        </Dialog>
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-lg-12"
              style={{
                height: "100%",
                width: "100%"
              }}
            >
              <Paper
                style={{
                  padding: "4px 10px 10px 10px",
                  margin: "20px 0px"
                }}
              >
                <Grid
                  container
                  spacing={2}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 10,
                    marginBottom: 1,
                    //marginLeft: 10
                  }}
                >
                  <Grid item xs={12} md={2} lg={2}>
                    <h4 style={{ color: "#4b2e83" }}>Select User</h4>
                  </Grid>
                  <Grid item xs={12} md={4} lg={4}>
                    <input
                      list="text_editors1"
                      className={classes.input}
                      onChange={this.selectUser}
                      value={this.state.selectedUser}
                    />
                    <datalist id="text_editors1" style={{ height: 30 }}>
                      <select multiple>
                        {users.map(user => (
                          <option key={user.UserId} value={user.UserId}>
                            {user.FullName}
                          </option>
                        ))}
                      </select>
                    </datalist>
                  </Grid>
                  <Grid item xs={12} md={2} lg={2}>
                    <Tooltip title="Reset">
                      <IconButton
                        style={{
                          color: "#4b2e83",
                          opacity: "1",
                          outline: "none"
                        }}
                        aria-label="Reset"
                        onClick={() => {
                          this.handleReset();
                        }}
                      >
                        <ResetIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Done">
                      <IconButton
                        style={{
                          color: "#4b2e83",
                          outline: "none"
                        }}
                        aria-label="Done"
                        onClick={() => {
                          this.fetchUserData();
                        }}
                      >
                        <SubmitIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} md={2} lg={2}>
                  </Grid>
                  <Grid item xs={12} md={2} lg={2}>
                    <Link style={{ textDecoration: "none" }} to="/options/users/create"><Button variant="outlined">Add User</Button></Link>
                  </Grid>
                </Grid>
                <Collapse in={this.state.open}>
                  <hr />
                  <Grid
                    container
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Grid item lg={1} md={1} xs={12}>
                      <img
                        style={{ /*marginLeft: 20,*/ borderRadius: `50%` }}
                        height={50}
                        width={50}
                        src={imageUrl}
                      />
                    </Grid>
                    <Grid item lg={2} md={2} xs={12} /*style={{ marginLeft: 20 }}*/>
                      <b>Name:</b>
                      {` ${name}`}
                    </Grid>
                    <Grid item lg={4} md={4} xs={12}>
                      <b>User Name:</b>
                      {` ${userName}`}
                    </Grid>
                    <Grid item lg={4} md={4} xs={12}>
                      <b>Email:</b>
                      {` ${email}`}
                    </Grid>
                    <Grid item lg={1} md={1} xs={12}>
                      <b>Auth: </b>
                      {isO365User? "O365" : "Local"}
                    </Grid>
                  </Grid>

                  <hr />

                  {permissions.length > 0 ? (
                    <React.Fragment>
                      <h4
                        style={{
                          color: "#4b2e83",
                          marginBottom: 25,
                          marginLeft: 20,
                          display: "inline"
                        }}
                      >
                        {`${name}'s Resource Permissions`}
                      </h4>
                      <hr />
                      <Table className={classes.table}>
                        <TableHead>
                          <TableRow style={{ height: 20 }}>
                            {tableHeader.map(row => (
                              <TableCell
                                key={row.id}
                                style={{}}
                                align={row.id == 3 ? "center" : "left"}
                              >
                                {row.label}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {permissions
                            .filter(item => item.IsActive == true)
                            .map(row => {
                              let policyGroup = "";
                              if (row.PermissionGroupLevel == 700)
                                policyGroup = "Administrator";
                              else if (row.PermissionGroupLevel == 500)
                                policyGroup = "Admin";
                              else if (row.PermissionGroupLevel == 300)
                                policyGroup = "Editor";
                              else if (row.PermissionGroupLevel == 100)
                                policyGroup = "Read Only";
                              return (
                                <TableRow
                                  style={{ height: 40 }}
                                  key={row.MembershipId}
                                >
                                  <TableCell>{row.ResourceInfo.Name}</TableCell>
                                  <TableCell>{row.ResourceInfo.Type}</TableCell>
                                  <TableCell>{policyGroup}</TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    style={{ padding: "0 24px" }}
                                    align="center"
                                  >
                                    <Tooltip title="Remove Membership">
                                      <IconButton
                                        aria-label="Remove Membership"
                                        className={classes.icons}
                                        disabled={!isPortalAdmin}
                                        onClick={() => {
                                          this.setState({
                                            actionType: "DELETE",
                                            openAlert: true,
                                            rowToDelete: row
                                          });
                                        }}
                                        style={
                                          isPortalAdmin
                                            ? {
                                                color: "#4b2e83",
                                                opacity: "1",
                                                outline: "none"
                                              }
                                            : {
                                                color: "#343a40",
                                                opacity: "0.4"
                                              }
                                        }
                                      >
                                        <DeleteIcon fontSize="small" />
                                      </IconButton>
                                    </Tooltip>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <h4
                        style={{
                          color: "#4b2e83",
                          marginBottom: 25,
                          marginLeft: 20,
                          display: "inline"
                        }}
                      >
                        {`${name} has no Permissions`}
                      </h4>
                    </React.Fragment>
                  )}
                </Collapse>
              </Paper>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    showMessageBox: message => dispatch(showMessage(message))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(UserPermissions);
