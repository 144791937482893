import React, { Component } from "react";
import "./Page2.css";
import TableUnMapped from "../components/TableUnmappedOrphanedDevices";
import { getEntity, showMessage } from "../actions/index";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { apiRequest } from "../utils/Utils";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = theme => ({
  input: {
    height: 38
  },
  label: {
    marginTop: "-5px"
  },
  textField: { width: "60%", marginTop: 20 }
});

class MapDevices extends Component {
  constructor() {
    super();
    this.state = {
      collapse: "true",
      isHidden: true,
      value: 1,
      unmappedAssets: [],
      EntityId: "",
      entityData: [],
      isAssetsLoaded: false
    };
  }

  componentWillMount() {
    this.props.storeEntity();
    this.loadunMapped();
  }

  loadunMapped() {
    let That = this;
    let url = `/assets/search?searchType=ownership&DeviceFlag=ORPHAN`;

    apiRequest(url, "get")
      .then(response => {
        this.setState({
          unmappedAssets: response.data,
          isAssetsLoaded: true
        });
      })
      .catch(error => console.log(error));
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  getMacID(that, id, entityId, manufacturer, model, type) {
    const data = {
      entityId,
      mac: id,
      manufacturer: manufacturer,
      model: model,
      type: type
    };
    if (entityId == "") {
      that.props.showMessageBox("Please select the Organization");
    } else if (id == "") {
      that.props.showMessageBox("Please select the Device(s) to be assigned");
    } else {
      apiRequest(`/assets/ownership`, "post", data)
        .then(x => {
          if (x.status > 0) this.setState({ isAssetsLoaded: false, unmappedAssets: [] }, () => that.loadunMapped());
          if (x.status > 0 && x.message == null)
            that.props.showMessageBox("SUCCESS");
          else
            that.props.showMessageBox(x.message);
        })
        .catch(err => {
          that.props.showMessageBox("ERROR");
        });
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <div
          id="content"
          style={{
            //paddingTop: "60px",
            transition: "0.25s",
            paddingLeft: "0px"
          }}
        >
          <div className="container-fluid">
            <div className="row">
              <div
                className="col-lg-12"
                style={{
                  height: "100%",
                  width: "100%",
                  paddingBottom: 20
                }}
              >
                {this.state.isAssetsLoaded || this.state.unmappedAssets.length > 0  ?  (
                  <div style={{ marginTop: "10px" }}>
                    <TableUnMapped
                      unmappedDevices={this.state.unmappedAssets}
                      getMacId={(id, entityId, manufacturer, model, type) =>
                        this.getMacID(this, id, entityId, manufacturer, model, type)
                      }
                      assignTo={"Organization"}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      width: "100%",
                      marginTop: 150,
                      marginBottom: 150,
                      display: "flex",
                      justifyContent: "center"
                    }}
                  >
                    <CircularProgress
                      style={{
                        width: "80px",
                        height: "80px",
                        color: "#4b2e83"
                      }}
                      className={classes.progress}
                    />
                    <h4 style={{ marginTop: "25px", marginLeft: "5px" }}>
                      Loading Devices
                    </h4>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { entities } = state;
  return {
    entities
  };
};

const mapDispatchToProps = dispatch => {
  return {
    storeEntity: () => getEntity(dispatch),
    showMessageBox: message => dispatch(showMessage(message))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(MapDevices);
