import React, { Component } from "react";
import "./Login.css";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import logo from "../images/UWMedicineLogo.png";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Lock from "@material-ui/icons/Lock";
import InputAdornment from "@material-ui/core/InputAdornment";
import Cookies from "js-cookie";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  API_BASE_URL,
  apiRequest,
  setAccess,
  storeUser,
  getMaxEntityAccess,
  getUser
} from "../utils/Utils";
import $ from "jquery";
import { compose } from "recompose";
import { connect } from "react-redux";
import { showMessage } from "../actions/index";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { rgbToHex } from "@material-ui/core/styles/colorManipulator";
import Collapse from "@material-ui/core/Collapse";

//AUTH
import { MsalContext, AuthenticatedTemplate, UnauthenticatedTemplate} from "@azure/msal-react";
import { pca } from "../../src/index.js";

const backcolor = "#4b2e83";
const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    width: "100%"
  },
  button: {
    width: "inherit",
    margin: 0,
    //margin: theme.spacing.unit,
    backgroundColor: backcolor,
    color: "white",
    border: "1px solid " + backcolor,

    "&:hover": {
      backgroundColor: "#fff",
      color: "#4b2e83 !important",
      border: "1px solid " + backcolor,
    },
  },
  buttonMicro: {
    width: 270,
    margin: 0,
    //margin: theme.spacing.unit,
    backgroundColor: "white",
    color: backcolor,
    border: "1px solid " + backcolor,

    "&:hover": {
      backgroundColor: backcolor,
      color: "white",
      border: "1px solid " + backcolor,
    },
  },
});

class Login extends Component {
  static contextType = MsalContext;
  state = {
    name: "",
    password: "",
    showPassword: false,
    signingIn: false,
    open: false,
    email: "",
    name2: "",
    loginProcessOn: false,
    signInAsAdmin: false
  };

  componentWillMount() {
    window.$ = window.jQuery = require("jquery");
    if (Cookies.get(`owAuth${window.location.port}`)) {
      this.props.history.push("/home");
    }
    else {
      setTimeout(() => {
        this.setState({ loginProcessOn: true });
      }, 2000);
    }
  }

  handleChangeName = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleChangePassword = password => event => {
    this.setState({
      [password]: event.target.value
    });
  };

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  handleSubmit = () => {
    const { name, password } = this.state;
    const THAT = this;
    THAT.setState({
      signingIn: true
    });
    const data = {
      Username: name,
      Password: btoa(password)
    };
    storeUser(name);
    $.ajax({
      method: "post",
      url: `${API_BASE_URL}/login`,
      data,
      cache: false,
      dataType: "json",
      success: function(y) {
        //var expiryTime = new Date(new Date().getTime() + 175 * 60 * 1000);
        var expiryTime = new Date(Date.parse(y.expiresOn) - 5 * 60 * 1000);
        var expiretime = Math.floor(expiryTime / 1000);
        Cookies.set(
          `owAuth${window.location.port}`,
          { token: y.accessToken, expires: expiretime },
          { expires: expiryTime, sameSite: "lax" }
        );
        apiRequest(`/accesscontrol`, "get")
          .then(x => {
            setAccess(x.data);
            THAT.setState({
              signingIn: false
            });
            if (x.data.length == 0) {
              THAT.props.history.push("/noaccess");
              //redirect the user to no access to view page
            } else if (getMaxEntityAccess() <= 300) {
              THAT.props.history.push("/devices/viewtable");
            } else {
              const lastUrl = localStorage.getItem("lastUrl");
              if (lastUrl) {
                localStorage.removeItem("lastUrl");
                if (getUser() == name) {
                  if (lastUrl == "/")
                    THAT.props.history.push("/home");
                  else
                    THAT.props.history.push(lastUrl);
                }
              }
              else
                THAT.props.history.push("/home");
            }
          })
          .catch(err => {
            THAT.props.history.push("/");
          });
      },
      error: function (err) {
        THAT.setState({
          signingIn: false
        });
        if (err.statusText == "Unauthorized") {
          THAT.props.showMessageBox("Username or Password incorrect");
        } else {
          THAT.props.showMessageBox("ERROR");
        }
      }
    });
  };

  handleKeyPress = e => {
    if (e.key === "Enter") {
      this.handleSubmit();
    }
  };

  handleForgetPassword = () => {
    const _this = this;
    $.ajax({
      method: "post",
      url: `${API_BASE_URL}/login/forgetpassword?emailOrId=${encodeURIComponent(_this.state.email)}`,
      cache: false,
      dataType: "json",
      success: res => {
        if (res.status == 0 && res.message != null) {
          _this.props.showMessageBox(res.message);
        }
        if (res.status == 1) {
          _this.setState({ open: false, email: "" });
          _this.props.showMessageBox("SUCCESS");
        }
      },
      error: err => {
        _this.setState({ open: false, email: "" });
      }
    });
  };

  handleClose() {
    this.setState({ open: false, email: "" });
  }

  async getToken() {
    var request = {
      account: pca.getAllAccounts()[0],
      scopes: [`${window.CLIENT_ID}/.default`]
    };
    let apiToken = await pca.acquireTokenSilent(request)
      .then((tokenResponse) => {
        return tokenResponse;
      })
      .catch((error) => {
        return pca.acquireTokenRedirect(request);
      });
      return apiToken;
  }

  async logIn() {
    if (this.context.accounts.length == 0) {
      //this.context.instance.loginRedirect({ scopes: [`${window.CLIENT_ID}/.default`] });
    } else {
      //this.props.history.push("/home");

      let y = await this.getToken();
      let name = this.context.accounts[0].username;

      //var expiryTime = new Date(new Date().getTime() + 175 * 60 * 1000);
      var expiryTime = new Date(Date.parse(y.expiresOn) - 5 * 60 * 1000);
      var expiretime = Math.floor(expiryTime / 1000);
      Cookies.set(
        `owAuth${window.location.port}`,
        { token: y.accessToken, expires: expiretime },
        { expires: expiryTime, sameSite: "lax" }
      );

      ////
      if (!getUser()) {
        console.log("user first attempt to login", name);
        //Store Login
        storeUser(name);
        const _this = this;
        apiRequest(`/login/o365?userId=${name}`, "post")
          .then((res) => {
            if (res) {
              if (res.status == 0 && res.message != null) {
                _this.props.showMessageBox(res.message);
              }
              if (res.status == 1) {
                //_this.setState({ open: false, email: "" });
                _this.props.showMessageBox("SUCCESS");
              }
            }
          })
          .catch((err) => {
            //_this.setState({ open: false, email: "" });
          });
      }
      ////
      storeUser(name);
      console.log("acc", this.context.accounts);

      apiRequest(`/accesscontrol`, "get")
        .then((x) => {
          setAccess(x.data);
          if (x.data.length == 0) {
            this.props.history.push("/noaccess");
            //redirect the user to no access to view page
          } else if (getMaxEntityAccess() <= 300) {
            this.props.history.push("/devices/viewtable");
          } else {
            const lastUrl = localStorage.getItem("lastUrl");
            if (lastUrl) {
              localStorage.removeItem("lastUrl");
              if (getUser() == name) {
                if (lastUrl == "/")
                  this.props.history.push("/home");
                else
                  this.props.history.push(lastUrl);
              }
            }
            else
              this.props.history.push("/home");
          }
        })
        .catch((err) => {
          console.log("Error");
          this.props.history.push("/");
        });
    }
  }

  render() {
    const { classes } = this.props;
    if (!Cookies.get(`owAuth${window.location.port}`) && this.context.accounts.length != 0) {
      this.logIn();
    }
    if (!this.state.loginProcessOn) {
      return (
        <div
          style={{
            height: "100%",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignContent: "center"
          }}
        >
          <CircularProgress
            style={{
              color: backcolor
            }}
            className={classes.progress}
          />
        </div>
      );
    }

    return (
      <div className="container-fluid">
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={this.state.open}
          onClose={() => {
            this.handleClose();
          }}
        >
          <DialogTitle>Enter UserId/EmailID</DialogTitle>
          <DialogContent>
            <TextField
              id="standard-uncontrolled"
              label="UserId/EmailId"
              className={classes.textField}
              margin="dense"
              onChange={e => {
                this.setState({ email: e.target.value });
              }}
              value={this.state.email}
            />
            <p style={{ fontSize: "12px", color: "red", marginBottom: 0 }}>
              *New password will be sent to the registered EmailID
            </p>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.handleClose();
              }}
              color="primary"
              className="button1"
            >
              Cancel
            </Button>
            <Button
              onClick={this.handleForgetPassword}
              color="primary"
              className="button1"
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <div className="row">
          <div
            className="col-lg-12"
            style={{
              height: "100%"
            }}
          >
            <div style={{ backgroundColor: backcolor }} className="splitl left">
              <div className="text-center">
                <img className="centered" width="80%" src={logo} alt={"logo"} />
              </div>
              {/* <div className="logocenteredvertical">
                <h1 style={{ color: "white", width: "500px" }} align="center">
                  Asset Tracking System
                </h1>
              </div> */}
            </div>
            <div
              style={{ backgroundColor: backcolor }}
              className="splitr right"
            >
              <div className="vl centeredvertical" />
              <div className="form-signin text-center centered formcustom login-page">
                <h4 style={{marginBottom: "30px"}}>
                  <b>Sign into your account</b>
                </h4>
                <Button
                  className={classes.buttonMicro}
                  size="large"
                  onClick={() => this.context.instance.loginRedirect({ scopes: [`${window.CLIENT_ID}/.default`] })}
                >
                  SIGN IN WITH OFFICE 365
                </Button>
                <div className="or"><span>or</span></div>
                <Button
                  className={classes.buttonMicro}
                  size="large"
                  onClick={() => this.setState({ signInAsAdmin: !this.state.signInAsAdmin })}
                >
                  SIGN IN WITH LOCAL ACCOUNT
                </Button>
                <Collapse in={this.state.signInAsAdmin}>
                  <TextField
                    id="user-name"
                    label="Username"
                    value={this.state.name}
                    type="email"
                    onKeyPress={this.handleKeyPress}
                    style={{
                      width: "100%",
                      borderColor: backcolor,
                      marginTop: "20px"
                    }}
                    onChange={this.handleChangeName("name")}
                    margin="dense"
                    variant="outlined"
                    inputProps={{
                      style: {
                        padding: "15px 14px 15px"
                      }
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircle style={{ color: backcolor }} />
                        </InputAdornment>
                      )
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                  <TextField
                    id="password"
                    label="Password"
                    value={this.state.password}
                    type={this.state.showPassword ? "text" : "password"}
                    margin="dense"
                    onKeyPress={this.handleKeyPress}
                    style={{
                      width: "100%",
                      marginBottom: "0px",
                      marginTop: "16px",
                      borderColor: backcolor
                    }}
                    onChange={this.handleChangePassword("password")}
                    variant="outlined"
                    inputProps={{
                      style: {
                        padding: "15px 14px 15px"
                      }
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Lock style={{ color: backcolor }} />
                        </InputAdornment>
                      )
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                  <div
                    style={{
                      display: "block",
                      fontSize: "13px",
                      textDecoration: "underline",
                      color: backcolor,
                      width: "110px",
                      //margin: "auto",
                      cursor: "pointer",
                      marginBottom: "15px"
                    }}
                    onClick={() => {
                      this.setState({
                        open: true
                      });
                    }}
                  >
                    Forgot Password
                  </div>
                  {!this.state.signingIn ? (
                    <Button
                      className={classes.button}
                      size="large"
                      onClick={() => {
                        this.handleSubmit();
                      }}
                    >
                      Sign in
                    </Button>
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        marginTop: 17,
                        marginBottom: 17,
                        display: "flex",
                        justifyContent: "center"
                      }}
                    >
                      <CircularProgress
                        style={{
                          width: "20px",
                          height: "20px",
                          marginTop: "4px",
                          marginRight: "4px",
                          color: backcolor
                        }}
                        className={classes.progress}
                      />
                      Signing in
                    </div>
                  )}
                </Collapse>
                <b>
                  <a
                    style={{
                      display: "block",
                      fontSize: "13px",
                      textDecoration: "underline",
                      color: backcolor,
                      width: "110px",
                      margin: "auto",
                      cursor: "pointer",
                      paddingTop: "15px"
                    }}
                    href="mailto://appservices.support@hpe.com?cc=assettracker@uw.edu&subject=I%20need%20support%20for%20UWM%20Asset%20Tracker&body=To%20help%20with%20your%20query,%20please%20provide%20some%20details%20and%20a%20member%20of%20support%20will%20contact%20you%20soon."
                  >
                    HELP
                  </a>
                </b>
              </div>
            </div>

            <p
              style={{ color: "white" }}
              className="mt-5 mb-3 text-center fixed-bottom"
            >
              &copy; {new Date().getFullYear().toString()} University of
              Washington | Seattle, WA
              {/* &copy; 2019-{new Date().getFullYear().toString()} Hewlett Packard Enterprise Development LP | Version 1.0.1 */}
            </p>

            <div id="token" />
            <div id="msg" />
            <div id="notis" />
            <div id="err" />
            <script>
              MsgElem = document.getElementById("msg") TokenElem =
              document.getElementById("token") NotisElem =
              document.getElementById("notis") ErrElem =
              document.getElementById("err")
            </script>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    showMessageBox: message => dispatch(showMessage(message))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(Login);
