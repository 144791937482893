import React from "react";
import classNames from "classnames";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import { compose } from "recompose";
import { connect } from "react-redux";
import { getEntity, showMessage } from "../actions/index";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import AlertDialog from "./AlertDialog";
import { apiRequest, isPortalAdmin, storeEntityId, atLastPageScrollToTop, formatDate } from "../utils/Utils";

function desc(a, b, orderBy) {
  if (a[orderBy] == null) {
    return (b[orderBy] == null) ? 0 : 1;
  }
  if (b[orderBy] == null) return -1;
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  { id: "DeviceImage", disablePadding: true, label: "IMAGE" },
  { id: "MacId", disablePadding: false, label: "MAC ID" },
  { id: "Name", numeric: false, disablePadding: false, label: "NAME" },
  // { id: "DisplayName", numeric: false, disablePadding: false, label: "DISPLAY NAME" },
  { id: "FloorNumber", numeric: false, disablePadding: false, label: "FLOOR" },
  { id: "MeridianCreated", numeric: false, disablePadding: false, label: "CREATED" }
];

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {
      classes,
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount
    } = this.props;

    return (
      <TableHead>
        <TableRow>
          <TableCell /*padding="checkbox"*/>
            <Checkbox
              hidden={!this.props.access}
              style={{ padding: 0 }}
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              color="primary"
              onChange={onSelectAllClick}
            />
            <p
              hidden={!this.props.access}
              style={{ marginLeft: 4, display: "inline" }}
            >
              Select all
            </p>
          </TableCell>

          {rows.map(
            row => (
              <TableCell
                key={row.id}
                padding={row.disablePadding ? "none" : "default"}
                sortDirection={orderBy === row.id ? order : false}
              >
              {(row.id != 'DeviceImage') ? (
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? "bottom-end" : "bottom-start"}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              ) : (
                row.label
              )}
              </TableCell>
            ),
            this
          )}
        </TableRow>
      </TableHead>
    );
  }
}

const toolbarStyles = theme => ({
  rootcheckbox: {
    color: "#4b2e83",
    "&$checked": {
      color: "#4b2e83"
    }
  },
  root: {
    paddingRight: theme.spacing.unit
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: "#4b2e83"
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  spacer: {
    flex: "1 1 60%"
  },
  actions: {
    color: "#4b2e83",
    width: "100%"
  },
  title: {
    flex: "0 0 auto"
  }
});

let EnhancedTableToolbar = props => {
  const { numSelected, classes } = props;

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    >
      <div className={classes.title}>
        <h4 style={{ color: "#4b2e83" }}>Map Devices</h4>
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        <Typography color="inherit" variant="subtitle1">
          {numSelected} selected
        </Typography>
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 2
  },
  rootcheckbox: {
    color: "#4b2e83",
    "&$checked": {
      color: "#4b2e83"
    }
  },
  table: {
    minWidth: 50
  },
  tableWrapper: {
    overflowX: "auto"
  },
  button: {
    color: "#4b2e83",
    height: "40px",
    width: "100%",
    padding: "0px !important"
  },
  input: {
    height: 38
  },
  label: {
    marginTop: "-5px"
  },
  textField: { width: "100%", margin: "0px !important" }
});

class EnhancedTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: "desc",
      orderBy: "sno",
      selected: [],
      data: props.unmappedDevices || [],
      page: 0,
      EntityId: "",
      entityData: [],
      rowsPerPage: this.props.assignTo === "Organization" ? 10 : 5,
      openAlert: false,
      access: props.hasAccess
    };
  }

  componentWillMount() {
    this.props.storeEntity();
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState(state => ({
        selected: this.props.unmappedDevices.map(n => n.MacId)
      }));
      return;
    }
    this.setState({ selected: [] });
  };

  componentWillReceiveProps({ entities, hasAccess, page }) {
    this.setState({
      entityData: entities,
      access: hasAccess,
      selected: hasAccess ? this.state.selected : [],
      page: 0
    });
  }

  handleClick = (event, id) => {
    if (this.state.access) {
      const { selected } = this.state;
      const selectedIndex = selected.indexOf(id);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }

      this.setState({ selected: newSelected });
    }
  };

  handleChangePage = (event, page) => {
    this.setState({ page }, () => {
      atLastPageScrollToTop(this.props.unmappedDevices.length,this.state.rowsPerPage,page);
    });
  };

  handleChangeRowsPerPage = event => {
    if (event.target.value * this.state.page > this.state.data.length && this.state.page != 0)
      this.setState({ rowsPerPage: event.target.value, page: 0 });
    else
      this.setState({ rowsPerPage: event.target.value });
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };
  searchTable(e) {
    var input, found, table, tr, td, i, j;

    input = e.target.value.toUpperCase();
    table = document.getElementsByTagName("table");
    tr = table[0].getElementsByTagName("tr");
    for (i = 1; i < tr.length; i++) {
      td = tr[i].getElementsByTagName("td");
      for (j = 0; j < td.length; j++) {
        if (td[j].innerHTML.toUpperCase().indexOf(input) > -1) {
          found = true;
        }
      }
      if (found) {
        tr[i].style.display = "";
        found = false;
      } else {
        tr[i].style.display = "none";
      }
    }
  }

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  handleAssign = () => {
    this.setState({
      openAlert: false
    });
    if (this.props.assignTo === "Organization")
      this.props.getMacId(this.state.selected, this.state.EntityId);
    else
      this.props.getMacId(this.state.selected);
    this.setState({ selected: [] });
  };

  filterBasedOnMapping = (unmapped, mapped) => {
      let mds = mapped ? mapped.map(e => e.MacId) : [];
      return unmapped.filter(f => !mds.includes(f.MacId));
  }

  render() {
    const { numSelected } = this.props;
    const { classes } = this.props;
    const data = this.filterBasedOnMapping(this.props.unmappedDevices, this.props.mappedDevices);
    const { order, orderBy, selected, rowsPerPage, page } = this.state;
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    return (
      <div>
        <AlertDialog
          open={this.state.openAlert}
          onClickYes={this.handleAssign}
          action={"Assign"}
          close={() => {
            this.setState({ openAlert: false });
          }}
        />
        <Paper className={classes.root} style={{ padding: 10, height: "100%" }}>
          {this.props.assignTo === "Organization" ? (
            <Grid className="border-bottom" container spacing={8}>
              <Grid item xs={6}>
                <h4
                  style={{
                    paddingTop: 10,
                    color: "#4b2e83",
                    width: "100%"
                  }}
                >
                  {`${
                    this.props.assignTo == "Collection"
                      ? "Assign Devices to a Collection"
                      : "Assign Devices to a Department"
                  }`}
                </h4>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  style={{
                    width: "100%"
                  }}
                  sd
                  margin="dense"
                  placeholder="Device Search"
                  variant="outlined"
                  onChange={this.searchTable}
                  value={this.state.search}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    className: classes.input
                  }}
                  InputLabelProps={{
                    className: classes.label
                  }}
                />
              </Grid>
            </Grid>
          ) : (
            <div className="border-bottom">
              <h4
                style={{
                  color: "#4b2e83",
                  width: "100%"
                }}
              >
                {`${
                  this.props.assignTo == "Collection"
                    ? "Assign Devices to a Collection"
                    : "Assign Devices to a Department"
                }`}
              </h4>
            </div>
          )}

          <div className={classes.tableWrapper}>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="small"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                rowCount={data.length}
                access={this.state.access}
              />
              <TableBody>
                {stableSort(data, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(n => {
                    const isSelected = this.isSelected(n.MacId);
                    return (
                      <TableRow
                        hover
                        style={{ height: 42 }}
                        onClick={event => this.handleClick(event, n.MacId)}
                        role="checkbox"
                        aria-checked={isSelected}
                        tabIndex={-1}
                        key={n.MacId}
                        selected={isSelected}
                      >
                        <TableCell
                          style={{ width: "140px" }}
                          //padding="checkbox"
                        >
                          {this.state.access ? (
                            <Checkbox
                              style={{ padding: 0 }}
                              classes={{
                                root: classes.rootcheckbox,
                                checked: classes.checked
                              }}
                              color="primary"
                              checked={isSelected}
                            />
                          ) : null}
                        </TableCell>
                        <TableCell padding="none" style={{ width: 100 }}>
                          <img className="image"
                            style={{
                              borderRadius: "50%"
                            }}
                            src={n.ImageUrl}
                            width="30px"
                            height="30px"
                            alt=""
                          />
                        </TableCell>
                        <TableCell style={{ width: "160px" }} align="center">
                          {n.MacId}
                        </TableCell>
                        <TableCell>{n.Name}</TableCell>
                        {/* <TableCell>{n.DisplayName}</TableCell> */}
                        <TableCell>{n.FloorNumber}</TableCell>
                        <TableCell>{n.MeridianCreated ? formatDate(new Date(n.MeridianCreated + "Z")) : ""}</TableCell>
                      </TableRow>
                    );
                  })}
                {/* emptyRows > 0 && (
                  <TableRow style={{ height: 42 * emptyRows }}>
                    <TableCell colSpan={4} />
                  </TableRow>
                ) */}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "Previous Page"
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page"
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
          <Grid container spacing={16}>
            <Grid item xs={12} md={5} lg={5}>
              {this.state.access && <h6
                className="float-left"
                style={{
                  paddingLeft: "3px",
                  color: "#4b2e83"
                }}
              >
                {this.state.selected.length + " row(s) selected."}
              </h6>}
            </Grid>
            <Grid item xs={12} md={4} lg={4} style={{ paddingRight: 10 }}>
              {this.props.assignTo === "Organization" ? (
                <TextField
                  width="90%"
                  id="outlined-select"
                  select
                  style={{
                    marginTop: 20,
                    marginBottom: 0
                  }}
                  required
                  label="Organization"
                  placeholder="Organization"
                  margin="dense"
                  className={classes.textField}
                  value={this.state.EntityId}
                  onChange={this.handleChange("EntityId")}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  InputProps={{ className: classes.input }}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.label
                  }}
                  variant="outlined"
                >
                  {this.state.entityData.map(option => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              ) : null}
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <Button
                onClick={() => {
                  if (this.state.selected.length == 0) {
                    this.props.showMessageBox("Please Select devices");
                    return;
                  }
                  this.setState({
                    openAlert: true
                  });
                }}
                variant="outlined"
                disabled={!this.state.access}
                //className={classNames(classes.button, "float-right", "button1")}
                className={classNames("float-right", "button1")}
              >
                {this.props.assignTo === "Organization" ? "Take Ownership" : "Assign"}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { entities } = state;
  return {
    entities
  };
};

const mapDispatchToProps = dispatch => {
  return {
    storeEntity: () => getEntity(dispatch),
    showMessageBox: message => dispatch(showMessage(message))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(EnhancedTable);
