import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import ViewIcon from "@material-ui/icons/OpenInNew";
import { compose } from "recompose";
import { connect } from "react-redux";
import { showMessage } from "../actions/index";
import AlertDialog from "./AlertDialog";
import { apiRequest } from "../utils/Utils";

const rows = [
  { id: "CollectionName", disablePadding: true, label: "COLLECTION NAME" },
  {
    id: "CollectionType",
    numeric: false,
    disablePadding: false,
    label: "COLLECTION TYPE"
  },
  { id: "Action", numeric: false, disablePadding: false, label: "ACTION" }
];

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 2
  },
  rootcheckbox: {
    color: "#4b2e83",
    "&$checked": {
      color: "#4b2e83"
    }
  },
  table: {
    minWidth: 50
  },
  tableWrapper: {
    overflowX: "auto"
  },
  button: {
    color: "#4b2e83",
    height: "40px",
    width: "100%",
    padding: "0px !important"
  },
  input: {
    height: 38
  },
  label: {
    marginTop: "-5px"
  },
  textField: { width: "100%", margin: "0px !important" },
  tableHead: {
    fontWeight: 400,
    fontSize: 14,
    fontWeight: 500,
    fontSize: "0.75rem",
    color: "rgba(0, 0, 0, 0.54)"
  },
  tableRow: {
    paddingTop: "0px !important",
    paddingBottom: "0px !important",
    verticalAlign: "middle !important",
    fontWeight: 400,
    fontSize: "0.8125rem",
    color: "rgba(0, 0, 0, 0.87)"
  }
});

class EnhancedTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: "desc",
      orderBy: "sno",
      data: props.collections || [],
      page: 0,
      rowsPerPage: 4,
      openAlert: false,
      collectionToDelete: ""
    };
  }

  componentWillMount() {}

  componentWillReceiveProps({ collections }) {
    this.setState({
      data: collections || []
    });
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleDelete = () => {

    this.setState({
      openAlert: false,
      showLoader: true
    });
    apiRequest(`/assetmaps`, "delete", {
      ids: [this.state.collectionToDelete.MapId]
    })
      .then(x => {
        if (x.status > 0) {
          this.setState({
            showLoader: false
          });
          this.props.refreshData();
        }
        if (x.status > 0 && x.message == null)
          this.props.showMessageBox("SUCCESS");
        else
          this.props.showMessageBox(x.message);
      })
      .catch(err => this.props.showMessageBox("ERROR"));
  };

  render() {
    const { classes } = this.props;
    const { data, rowsPerPage, page } = this.state;
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    return (
      <Paper className={classes.root} style={{ padding: 10, height: "100%" }}>
        <AlertDialog
          open={this.state.openAlert}
          onClickYes={this.handleDelete}
          action={"Delete"}
          close={() => {
            this.setState({ openAlert: false });
          }}
        />
        <table
          // style={{ width: "97%" }}
          style={{ width: "100%", marginBottom: "0px" }}
          className="table table-striped coll-table table-bordered table-condensed"
        >
          <thead>
            <tr>
              {rows.map(row => (
                <th
                  className={classes.tableHead}
                  key={row.id}
                  align={row.id == "Action" ? "center" : "left"}
                >
                  {row.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {this.state.data.map(row => (
              <tr key={row.CollectionId}>
                <td className={classes.tableRow}>{row.CollectionName}</td>
                <td className={classes.tableRow}>{row.CollectionType}</td>
                <td>
                  <Tooltip title="View Collection">
                    <IconButton
                      aria-label="View Collection"
                      className={classes.icons}
                      onClick={() => {
                        this.props.showCollectionDetail(
                          row.CollectionId,
                          row.CollectionType
                        );
                      }}
                      style={{
                        color: "#4b2e83",
                        opacity: "1",
                        outline: "none",
                        padding: 0
                      }}
                    >
                      <ViewIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton
                      aria-label="Delete"
                      className={classes.icons}
                      onClick={() => {
                        this.setState({
                          collectionToDelete: row,
                          openAlert: true
                        });
                      }}
                      hidden={!this.props.disableButton}
                      style={{
                        color: "#4b2e83",
                        opacity: "1",
                        outline: "none",
                        padding: 0,
                        marginLeft: 20
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Paper>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    showMessageBox: message => dispatch(showMessage(message))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(EnhancedTable);
